import { format } from "date-fns"
import { TableCell, TableRow } from "../ui/table"

const Task = ({ task }) => {
    return (
        <TableRow>
            <TableCell>{task?.title}</TableCell>
            <TableCell>{format(new Date(task?.startDate),"dd-MM-yyyy")}</TableCell>
            <TableCell>
                <span
                    className={`${task.status === "in-progress" ? "bg-[#FFD700] text-white" : task.status === "needs-review" ? "bg-red-500 text-white" : task.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-3 py-2 font-semibold rounded-tl-xl rounded-br-xl`}
                >{task.status}</span>
            </TableCell>
        </TableRow>
    )
}

export default Task