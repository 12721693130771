import classes from './ImageModal.module.css';

import { FaXmark } from 'react-icons/fa6';

const ImageModal = ({ setIsImgView, img }) => {

    return (
        <div className={classes.container}>
            <div className={classes.invoice_box_wrapper}>
                <FaXmark onClick={() => setIsImgView(false)} className={classes.icon} />
                <div className={classes.invoice_box}>
                    <img src={`${import.meta.env.VITE_IMAGE_URL}/${img}`} style={{ width: "100%", height: "79vh", border: "none" }}></img>
                </div>
            </div>
        </div>
    )
}

export default ImageModal