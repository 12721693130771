import classes from './InvoiceModal.module.css';

import logo from '../../assets/Easesmith logo.png';

import { FaXmark } from 'react-icons/fa6';

import html2PDF from 'jspdf-html2canvas';
import { format } from 'date-fns';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

const InvoiceModal = ({ setIsInvoicePreview, services, subtotal, taxRate, total, invoiceNumber, invoiceDate, state,Terms }) => {
    console.log("state",state);
    const downloadInvoice = () => {
        html2PDF(document.querySelector("#invoice_box"), {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            output: './pdf/generate.pdf'
        });
    }


    return (
        <div className={classes.container}>
            <div className={classes.invoice_box_wrapper}>
                <FaXmark onClick={() => setIsInvoicePreview(false)} className={classes.icon} />
                <div className={classes.invoice_box} id='invoice_box'>
                    <div>
                        <div className="flex justify-between gap-3 bg-[#F2F5F9] p-8 rounded-xl">
                            <div>
                                <h2 className="text-2xl font-bold">Invoice</h2>
                                <div className="mt-9 flex flex-col gap-1">
                                    <h4 className="text-[#60737D]">Billed To:</h4>
                                    <h2 className="text-xl font-bold">{state?.companyName}</h2>
                                    <p className="text-[#374151] text-sm">{state.phone}</p>
                                    <p className="text-[#374151] text-sm">{`${state.address.addressLine1},${state.address.addressLine2}, ${state.address.city} ${state.address.state}, ${state.address.country}, ${state.address.zipCode}`}</p>
                                </div>
                            </div>
                            <div className="flec flex-col gap-4">
                                <div>
                                    <h4 className="text-[#60737D] mb-1 text-right">Invoice No.:</h4>
                                    <h2 className="text-xl font-bold">{invoiceNumber}</h2>
                                </div>
                                <div className="mt-9">
                                    <h4 className="text-[#60737D] mb-1 text-right">Issued on:</h4>
                                    <p>{invoiceDate && format(new Date(invoiceDate), 'dd-MM-yyyy')}</p>
                                </div>
                                <div className="mt-1">
                                    <h4 className="text-[#60737D] mb-1 text-right">Payment Due:</h4>
                                    <p></p>
                                </div>
                            </div>
                        </div>

                        <Table className="mt-4 w-full">
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Service Name</TableHead>
                                    <TableHead>Description</TableHead>
                                    <TableHead>Price (₹)</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {services?.map((service, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{service.serviceName}</TableCell>
                                        <TableCell>{service.desccription}</TableCell>
                                        <TableCell>₹ {service.price}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <div className="flex  justify-end mt-4">
                            <div className="flex flex-col items-end gap-2">
                                <div className="flex flex-col gap-2">
                                    <div className="flex items-center gap-3">
                                        <p>Subtotal:</p>
                                        <p>₹ {subtotal}</p>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <p>Tax Rate (%):</p>
                                        <p>{taxRate}</p>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <p>Total:</p>
                                        <p>₹ {total}</p>
                                    </div>
                                </div>

                                <div className="bg-[#F2F5F9] rounded-md p-3 flex items-center justify-between w-80 gap-3">
                                    <p>Total (INR):</p>
                                    <p className="text-2xl font-bold">{total}</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <h2 className="text-2xl font-bold">From</h2>
                            <img className="mt-2 w-60" src={logo} alt='logo' />
                            <div className="grid grid-cols-3 gap-3 mt-4">
                                <div>
                                    <p>Address: 1/39 vishesh khand
                                        Near summit Building Lucknow ,
                                        Uttar Pradesh - INDIA
                                    </p>
                                    <span className="text-[#334BC8] mt-1">info@easesmith.com</span>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold">Payment Instructions</h3>
                                    <p className="mt-1 text-[#60737D]">
                                        Voluptas nisi aut. Est vitae dolore molestias porro praesentium. Tempore recusandae voluptatem necessitatibus corporis inventore neque magnam ut.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold">Additional Notes</h3>
                                    <p className="text-[#60737D]">{Terms ? Terms : "Note not found"}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={classes.button_wrapper}>
                    <button onClick={downloadInvoice} className="px-4 py-2 text-white rounded-md bg-[#1A56DB]">Download Invoice</button>
                </div>
            </div>
        </div>
    )
}

export default InvoiceModal