import { format } from "date-fns"
import breakImg from "../../assets/break-img.png";

const Break = ({ singleBreak, i, handleAvailable }) => {
  return (
    <div
      className="border-2 p-3 border-[#E5E7EB] rounded flex items-center justify-between gap-2 max-w-[300px] w-full"
    >
      <div>
        <p>
          {format(new Date(singleBreak.startTime), "hh:mm aa")} - {singleBreak.endTime ? format(new Date(singleBreak.endTime), "hh:mm aa") : "00:00 AM"}
        </p>
        <h4 className="text-lg font-bold">Break {i + 1}</h4>
        {!singleBreak.endTime && (
          <button
            onClick={() => handleAvailable(singleBreak._id)}
            className="mt-3 bg-black text-white px-4 py-1 rounded"
          >
            Available
          </button>
        )}
      </div>
      <div>
        <img className="w-20 h-20" src={breakImg} alt="breakImg" />
      </div>
    </div>
  )
}

export default Break