import classes from './Module.module.css';
import empImg from '../../assets/emp-img.png';
import { format } from 'date-fns';

import { useEffect, useState } from 'react';
import ModuleInfoModal from '../module-infoModal/ModuleInfoModal';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';
import { BsThreeDots } from 'react-icons/bs';

const Module = ({ module, getAllModule, employee, getStories }) => {

    const dispatch = useDispatch();
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

    useEffect(() => {
        const handleModalOpen = () => {
            if (isInfoModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isInfoModalOpen, dispatch])

    return (
        <>
            <div onClick={() => setIsInfoModalOpen(true)} className={classes.module}>
                <div className='flex justify-between items-center gap-2'>
                    <span className={`${module.status === "in-progress" ? "bg-[#FFD700] text-white" : module.status === "needs-review" ? "bg-red-500 text-white" : module.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-2 py-[1px] rounded-full`}>{module.status}</span>
                    <BsThreeDots size={20} />
                </div>
                <div className={classes.task_card_top}>
                    <h4 className={`${classes.h4} line-clamp-2`}>{module.title}</h4>
                </div>
                <div className='flex items-center gap-1'>
                    <div className='w-full h-2 bg-gray-300 rounded-md relative overflow-hidden'>
                        <div className='w-full h-2 bg-[#7CD4E8] absolute rounded-md overflow-hidden'></div>
                    </div>
                    <p className='text-[#7CD4E8]'>100%</p>
                </div>
                <div className='flex gap-5 items-center mt-2'>
                    <div className='flex items-center'>
                        <img className='-mr-1' src={empImg} alt="empImg" />
                        <img className='-mr-1' src={empImg} alt="empImg" />
                        <img src={empImg} alt="empImg" />
                    </div>
                    <div className='px-2 py-1 rounded-md bg-[#8AAFB8] text-sm text-white'>{format(new Date(module.startDate), "MMM dd, yyyy")}</div>
                </div>
                {/* <p>start date: {format(new Date(module.startDate), "dd-MM-yyyy")}</p> */}
            </div>
            {isInfoModalOpen &&
                <ModuleInfoModal
                    setState={setIsInfoModalOpen}
                    module={module}
                    getAllModule={getAllModule}
                    employee={employee}
                    getStories={getStories}
                />
            }
        </>
    )
}

export default Module