import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "../ui/button"
import { Label } from "../ui/label"
import { Input } from "../ui/input"
import { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import { validate } from "@/utils/validate";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import usePatchApiReq from "@/hooks/usePatchApiReq";

const UpdateEmailPasswordModal = ({ isEmailPasswordUpdateModalOpen, setIsEmailPasswordUpdateModalOpen, id }) => {
    const { res, fetchData, isLoading } = usePatchApiReq();
    const [emailPassword, setEmailPassword] = useState()
    const [validateState, setValidateState] = useState([]);
    const [isShowEmailPassword, setIsShowEmailPassword] = useState(false);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setEmailPassword(value);
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const res = validate({
            "Email Password": emailPassword,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!emailPassword) {
            return;
        }

        fetchData(`/admin/update-employee-mailpassword/${id}`, { mailPassword: emailPassword });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("update email password res", res);
            setIsEmailPasswordUpdateModalOpen(false);
        }
    }, [res])

    return (
        <Dialog open={isEmailPasswordUpdateModalOpen} onOpenChange={() => setIsEmailPasswordUpdateModalOpen(!isEmailPasswordUpdateModalOpen)}>
            <DialogContent className="sm:max-w-[500px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle className="text-left">Update Email Password</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="grid items-center gap-4 relative">
                        <Label>Email Password</Label>
                        <Input onChange={handleOnChange} type={isShowEmailPassword ? "text" : "password"} placeholder="Enter Email Password" value={emailPassword} name="emailPassword" id="emailPassword" />
                        {isShowEmailPassword ?
                            <FaEyeSlash onClick={() => setIsShowEmailPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                            : <FaEye onClick={() => setIsShowEmailPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                        }
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Loader size={25} /> : "Update"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateEmailPasswordModal