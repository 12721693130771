import { useEffect, useState } from 'react'
import { FaArrowLeft, FaCirclePlus } from 'react-icons/fa6'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import 'bootstrap/dist/css/bootstrap.min.css';
import { openCloseBody } from '../../store/slices/bodySlice'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'
import AddDocumentModal from '../../components/add-documentModal/AddDocumentModal'
import Document from '../../components/document/Document'
import LimitComp from '../../components/limit-comp/LimitComp';
import useGetApiReq from '../../hooks/useGetApiReq';
import Search from '@/components/search/Search';
import Loader from '@/components/loader/Loader';
import Folder from '@/components/folder/Folder';
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import Folders from '@/components/folders/Folders';
import SubFolders from '@/components/folders/SubFolders';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import SubSubFolders from '@/components/folders/SubSubFolders';
import SubSubFolderDocs from '@/components/folders/SubSubFolderDocs';
import AddFolderModal from '@/components/add-folderModal/AddFolderModal';
import { readCookie } from '@/utils/readCookie';


const AllDocuments = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false)
    const [allDocuments, setAllDocuments] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    console.log("limit", limit);

    const getAllDocuments = async () => {
        fetchData(`/admin/get-all-docs?page=${page}&limit=${limit}`);
    }

    useEffect(() => {
        getAllDocuments();
    }, [page, limit])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            const count = Math.ceil(res?.data?.totalDocs / limit);
            setPageCount(count);
            console.log("count", count);
            console.log("all docs", res);
            setAllDocuments(res?.data?.docs);
        }
    }, [res])


    const { res: folderRes, fetchData: fetchFolderData, isLoading: isFolderLoading } = useGetApiReq();
    const params = useParams();
    const [allFolders, setAllFolders] = useState([]);

    const getFolders = () => {
        fetchFolderData(`/admin/get-all-folders?projectId=${params.id || ""}`);
    }

    useEffect(() => {
        getFolders();
    }, [])

    useEffect(() => {
        if (folderRes?.status === 200 || folderRes?.status === 201) {
            console.log("folders", folderRes);
            setAllFolders(folderRes?.data?.allFolders);
        }
    }, [folderRes])


    useEffect(() => {
        const handleModalOpen = () => {
            if (isAddDocumentModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isAddDocumentModalOpen])

    const { res: managerRes, fetchData: fetchManagerData, isLoading: isManagerLoading } = useGetApiReq();
    const userInfo = readCookie("userInfo")

    const getProjectManagerStatus = async () => {
        fetchManagerData(`/admin/get-project-access-admin/${params?.pId || params?.id}`);
    }

    useEffect(() => {
        if (params?.pId) {
            getProjectManagerStatus()
        }
    }, [])


    return (
        <div>
            <h1 className="text-3xl font-bold py-2">Documents</h1>
            <div className="flex w-full justify-between mt-8 items-center">
                <div className="flex gap-2">
                    <LimitComp
                        setLimit={setLimit}
                        limit={limit}
                    />

                    {(managerRes?.data?.data?.employeeId === userInfo?.userId || userInfo.userType === "admin") &&
                        <button onClick={() => setIsAddFolderModalOpen(true)} className="px-4 py-2 rounded-md flex items-center gap-3 border-2">
                            <FaCirclePlus size={18} />
                            Add Folder
                        </button>
                    }
                    <Search
                        placeholder="Search Document"
                    />
                </div>
            </div>

            <div className='mt-3 flex gap-2 items-center'>
                {pathname.split("/documents/").length > 1 && <FaArrowLeft className='cursor-pointer' onClick={() => navigate(-1)} />}
                {pathname}
            </div>


            {/* <Breadcrumb>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <BreadcrumbLink asChild>
                            <Link href="/documents">Documents</Link>
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    {pathname.split("/documents/").length > 1 &&
                        <>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbLink asChild>
                                    <Link href={pathname.split("/documents/")[1]}>{pathname.split("/documents/")[1].toUpperCase()}</Link>
                                </BreadcrumbLink>

                            </BreadcrumbItem></>
                    }
                    {pathname.split("/documents/").length > 2 &&
                        <>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbLink href={pathname.split("/documents/")[2]}>{pathname.split("/documents/")[2].toUpperCase()}</BreadcrumbLink>
                            </BreadcrumbItem></>
                    }
                </BreadcrumbList>
            </Breadcrumb> */}


            <Routes>
                <Route path="/" element={<Folders allFolders={allFolders} />} />
                <Route path="/:folderName" element={<SubFolders />} />
                <Route path="/:folderName/:subFolderName" element={<SubSubFolders />} />
                <Route path="/:folderName/:subFolderName/:subSubFolderName" element={<SubSubFolderDocs />} />
            </Routes>

            {/* {allDocuments.length === 0 && !isLoading &&
                <p>No documents found</p>
            }
            {allDocuments.length === 0 && isLoading &&
                <Loader />
            } */}
            {/* <div className='flex gap-10 mt-5'>
                {allDocuments?.map((singleDocument, index) => (
                    <Document
                        key={singleDocument._id}
                        singleDocument={singleDocument}
                        getAllDocuments={getAllDocuments}
                        index={index}
                    />
                ))}
            </div> */}


            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            }

            {isAddDocumentModalOpen &&
                <AddDocumentModal
                    setState={setIsAddDocumentModalOpen}
                    getAllDocuments={getAllDocuments}
                />
            }

            {isAddFolderModalOpen &&
                <AddFolderModal
                    isAddFolderModalOpen={isAddFolderModalOpen}
                    setIsAddFolderModalOpen={setIsAddFolderModalOpen}
                    getFolders={getFolders}
                />
            }
        </div >
    )
}

export default AllDocuments