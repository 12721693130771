import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import { MdOutlineEmail } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";

import avatar from "../../assets/avatar.jpg"
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import InvoiceItem from '../../components/invoiceItem/InvoiceItem';
import ProjectCard from '../../components/projectCard/ProjectCard';
import CreateProjectModal from '../../components/create-project-modal/CreateProjectModal';
import ReactPaginate from 'react-paginate';
import { BiSolidEdit, BiSolidTrashAlt } from 'react-icons/bi';
import toast from 'react-hot-toast';
import DeleteModal from '../../components/deleteModal/DeleteModal';
import AddClientModal from '../../components/add-clientModal/AddClientModal';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';
import LimitComp from '../../components/limit-comp/LimitComp';
import Loader from '@/components/loader/Loader';
import { FaLocationDot, FaUser } from 'react-icons/fa6';
import { LiaIndustrySolid } from "react-icons/lia";
import { FiPhone } from 'react-icons/fi';

const Profile = () => {
    const [clientInvoices, setClientInvoices] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [state, setState] = useState("")
    const [limit, setLimit] = useState(5);

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    // const { state } = useLocation();

    console.log("state", state);

    const getClientDetails = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-client-profile/${params?.clientId}`);
            console.log("client details", data);
            setState(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
    }


    const handleDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-client/${state?._id}`);
            toast.success("Client deleted successfully");
            setIsDeleteModal(false);
            navigate("/clients")
        } catch (error) {
            console.log(error);
        }
    }


    const getClientAllInvoices = async () => {
        try {
            setClientInvoices([]);
            const { data } = await axiosInstance.get(`/admin/client-invoice/${params?.clientId}?page=${page}&limit=${limit}`);
            console.log("invoice", data);
            setClientInvoices(data.data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
        } catch (error) {
            console.log(error);
        }
    }

    const getAllProjects = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/client-project/${params?.clientId}`);
            console.log("projects", data);
            setAllProjects(data.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getAllProjects();
        getClientDetails();
    }, [])

    useEffect(() => {
        getClientAllInvoices();
    }, [page])

    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen || isDeleteModal) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isModalOpen, isDeleteModal])


    return (
        <>
            <section>
                <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />

                <h1 className="text-3xl font-bold py-2 mb-2">Client Profile</h1>
                <div className="grid grid-cols-[55%_45%]">
                    <div className="grid grid-cols-[30%_60%] gap-5 bg-white border-2 border-[#E5E7EB] shadow-sm px-4 rounded-tr-[60px] rounded-bl-[50px] relative mb-5">
                        <div className='flex flex-col items-center py-3'>
                            <img className='w-28 h-28 rounded-full' src={state?.image ? `${import.meta.env.VITE_IMAGE_URL}/${state.image}` : avatar} alt="client" />
                            <p className='mt-2'>Client ID : PXL003</p>
                            <div className="flex gap-3 justify-center mt-3">
                                <BiSolidEdit onClick={handleModal} cursor={"pointer"} size={24} />
                                <BiSolidTrashAlt onClick={() => setIsDeleteModal(true)} cursor={"pointer"} size={22} color="red" />
                            </div>
                        </div>
                        <div className='flex gap-2'>
                            <div className='bg-[#348AC8] px-3 h-full flex flex-col items-center gap-4 text-white py-3'>
                                <FaUser size={22} />
                                <LiaIndustrySolid size={24} />
                                <MdOutlineEmail size={22} />
                                <FiPhone size={22} />
                                <FaLocationDot size={22} />
                            </div>
                            <div className='h-full flex flex-col items-start gap-4 py-3'>
                                <h3 className='text-base font-semibold'>{state?.founderName}</h3>
                                <h3 className='text-base font-bold'>{state?.companyName}</h3>
                                <p>{state?.email}</p>
                                <p>{state?.phone}</p>
                                <p>{`${state?.address?.addressLine1},${state?.address?.addressLine2}, ${state?.address?.city}, ${state?.address?.state}, ${state?.address?.country}, ${state?.address?.zipCode}`}</p>
                                <span className='text-sm text-white px-3 py-2 absolute right-5 top-1 bg-[#348AC8] rounded-bl-3xl rounded-tr-3xl'>Client</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center mt-5 mb-2">
                    <h3 className="text-2xl font-bold">Client Projects</h3>
                    {allProjects?.length > 0 && <button onClick={() => navigate(`/clients/${params.clientId}/projects`)} className='border px-4 py-1 rounded'>View All</button>}
                </div>

                <Table className="mt-3">
                    <TableHeader>
                        <TableRow>
                            <TableHead></TableHead>
                            <TableHead>Project & Client</TableHead>
                            <TableHead>Project Number</TableHead>
                            <TableHead>Project Manager</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Amount</TableHead>
                            <TableHead>Start Date</TableHead>
                            <TableHead>End Date</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allProjects?.map((project) => (
                            <ProjectCard
                                key={project._id}
                                project={project}
                            />
                        ))}
                    </TableBody>
                </Table>
                {allProjects?.length === 0 && !isLoading &&
                    <p>No project running</p>
                }
                {allProjects.length === 0 && isLoading &&
                    <Loader />
                }

                <div className='mt-4'>
                    <div className="flex items-center justify-between">
                        <h3 className="text-2xl font-bold py-2 mb-2">Client Invoices</h3>

                        <div className="flex gap-2 items-center">
                            <LimitComp
                                setLimit={setLimit}
                                limit={limit}
                            />
                            <button onClick={() => navigate(`/clients/${params?.clientId}/create-invoice`, { state: state })} className="border px-4 py-2 rounded">Create Invoice</button>
                        </div>
                    </div>
                </div>

                <Table className="mt-3">
                    <TableHeader>
                        <TableRow>
                            <TableHead>No</TableHead>
                            <TableHead>Project</TableHead>
                            <TableHead>Date Start</TableHead>
                            <TableHead>Date End</TableHead>
                            <TableHead>Amount</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {clientInvoices.map((clientInvoice, i) => (
                            <InvoiceItem
                                key={i}
                                state={state}
                                clientInvoice={clientInvoice}
                                getClientAllInvoices={getClientAllInvoices}
                            />
                        ))}
                    </TableBody>
                </Table>
                {clientInvoices.length === 0 && <p>Not any Invoice created.</p>}


                {pageCount > 0 &&
                    <div className="mt-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}
            </section >
            {isCreateProjectModalOpen &&
                <CreateProjectModal
                    getAllProject={getAllProjects}
                    setState={setIsCreateProjectModalOpen}
                    project={""}
                />
            }

            {
                isModalOpen &&
                <AddClientModal
                    client={state}
                    handleModal={handleModal}
                    isUpdate={true}
                    getAllClient={getClientDetails}
                />
            }

            {
                isDeleteModal &&
                <DeleteModal
                    setState={setIsDeleteModal}
                    handleDelete={handleDelete}
                />
            }
        </>
    )
}

export default Profile