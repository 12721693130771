import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: localStorage.getItem("maven-username") || ""
};


const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        changeUserName: (state, action) => {
            state.user = action?.payload;
            localStorage.setItem("maven-username", action?.payload);
        }
    },
});

export const { changeUserName } = userSlice.actions;
export default userSlice.reducer;