import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import usePostApiReq from '@/hooks/usePostApiReq';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

const AddExperience = ({ isAddModalOpen, setIsAddModalOpen,getAllExperience }) => {
    const { res, fetchData, isLoading } = usePostApiReq();

    const params = useParams();

    const [experienceInfo, setExperienceInfo] = useState({
        yearsOfExperience: 0,
        fromDate: "",
        toDate: "",
        position: "",
        location: "",
        companyName: "",
    })

    const [experiences, setExperiences] = useState([]);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setExperienceInfo({ ...experienceInfo, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (experiences.length === 0) {
            toast.error("Please add experience to update");
            return;
        }
        fetchData(`/admin/add-work-experience`, { workExperience:experiences, id: params?.empId });
    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddModalOpen(false)
            getAllExperience()
        }
    }, [res])

    console.log("experienceInfo", experienceInfo);

    const onClickAdd = () => {
        if (!experienceInfo.fromDate
            || !experienceInfo.location
            || !experienceInfo.position
            || !experienceInfo.toDate
            || !experienceInfo.yearsOfExperience
            || !experienceInfo.companyName
        ) {
            toast.error("All fields are required");
            return;
        }
        setExperiences([...experiences, experienceInfo]);
        setExperienceInfo(
            {
                yearsOfExperience: 0,
                fromDate: "",
                toDate: "",
                position: "",
                location: "",
                companyName: "",
            }
        )
    }

    return (
        <Dialog open={isAddModalOpen} onOpenChange={() => setIsAddModalOpen(!isAddModalOpen)}>
            <DialogContent className="sm:max-w-[725px] h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Add Experience</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="yearsOfExperience">
                            Years of Experience
                        </Label>
                        <Input type="number" placeholder="Enter Years of Experience" onChange={handleOnChange} value={experienceInfo.yearsOfExperience} name="yearsOfExperience" id="yearsOfExperience" className="col-span-3 mt-2" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="fromDate">
                            From Date
                        </Label>
                        <Input type="date" placeholder="Enter From Date" onChange={handleOnChange} value={experienceInfo.fromDate} name="fromDate" id="fromDate" className="col-span-3 mt-2" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="toDate">
                            To Date
                        </Label>
                        <Input type="date" placeholder="Enter To Date" onChange={handleOnChange} value={experienceInfo.toDate} name="toDate" id="toDate" className="col-span-3 mt-2" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="position">
                            Position
                        </Label>
                        <Input placeholder="Enter Position" onChange={handleOnChange} value={experienceInfo.position} name="position" id="position" className="col-span-3 mt-2" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="location">
                            Location
                        </Label>
                        <Input placeholder="Enter Location" onChange={handleOnChange} value={experienceInfo.location} name="location" id="location" className="col-span-3 mt-2" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="companyName">
                            Company Name
                        </Label>
                        <Input placeholder="Enter Company Name" onChange={handleOnChange} value={experienceInfo.companyName} name="companyName" id="companyName" className="col-span-3 mt-2" />
                    </div>
                    {experiences.length > 0 &&
                        <Table className="mt-3">
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Years  of Experience</TableHead>
                                    <TableHead>Company Name</TableHead>
                                    <TableHead>From</TableHead>
                                    <TableHead>To</TableHead>
                                    <TableHead>Position</TableHead>
                                    <TableHead>Location</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {experiences.map((exp) => (
                                    <TableRow key={exp._id}>
                                        <TableCell>{exp.yearsOfExperience}</TableCell>
                                        <TableCell>{exp.companyName}</TableCell>
                                        <TableCell>{exp.fromDate}</TableCell>
                                        <TableCell>{exp.toDate}</TableCell>
                                        <TableCell>{exp.position}</TableCell>
                                        <TableCell>{exp.location}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>}
                    <DialogFooter>
                        <Button onClick={onClickAdd} type="button">
                            Add
                        </Button>

                        <Button type="submit">
                            Update
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddExperience