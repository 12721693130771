import { format } from 'date-fns';
import classes from './StoryInfoModal.module.css';
import { RxCross2 } from 'react-icons/rx';

const AttendanceInfoModal = ({ setState, event }) => {

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.box}>
                    <div className={classes.heading_container}>
                        <h2></h2>
                        <div className={classes.button_icon_container}>
                            <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                        </div>
                    </div>
                    <span className={`${event?.flag ? "bg-green-400" : "bg-red-500"} font-semibold px-1 rounded`}>{event?.title}</span>
                    <p><b>Punch In :</b> {event?.punchIn ? format(new Date(event?.punchIn), "dd-MM-yyyy") : null} {event?.punchIn ? format(new Date(event?.punchIn), "hh:mm aaaaa'm'") : null}</p>
                    <p><b>Punch Out :</b> {event?.punchOut ? format(new Date(event?.punchOut), "dd-MM-yyyy") : null} {event?.punchOut ? format(new Date(event?.punchOut), "hh:mm aaaaa'm'") : null}</p>

                    <h3 className="text-xl mt-4 font-semibold">All Breaks</h3>
                    <div className="flex flex-wrap gap-3 mt-2">
                        {event?.breaks?.length === 0 &&
                            <p>No breaks found</p>
                        }
                        {event?.breaks?.map((singleBreak, i) => (
                            <div key={singleBreak._id} className="border-2 p-3 border-gray-600 rounded max-w-[250px] w-full">
                                <h4 className="text-lg">Break {i + 1}</h4>
                                <p>time from : {singleBreak.startTime ? format(new Date(singleBreak.startTime), "hh:mm") : null}</p>
                                {/* {singleBreak.endTime ? */}
                                <p>time to : {singleBreak.endTime ? format(new Date(singleBreak.endTime), "hh:mm") : null}</p>
                                {/* : <button onClick={() => handleAvailable(singleBreak._id)} className='mt-3 bg-black text-white px-4 py-1 rounded'>Available</button>
                                } */}
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </>
    )
}

export default AttendanceInfoModal