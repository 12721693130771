import { FaCircle, FaCirclePlus, FaRegStar, FaStar, FaTrash } from 'react-icons/fa6'
import { RiInboxFill } from "react-icons/ri";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Input } from '@/components/ui/input';
import { MdDrafts } from 'react-icons/md';
import { IoMdSend } from 'react-icons/io';
import { FaFireAlt, FaRegTrashAlt } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import SendEmailModal from '@/components/send-emailModal/SendEmailModal';
import { useNavigate } from 'react-router-dom';
import useGetApiReq from '@/hooks/useGetApiReq';
import Loader from '@/components/loader/Loader';
import EmailDetails from '../email-details/EmailDetails';
import { format } from 'date-fns';
import ReactPaginate from 'react-paginate';
import { TbReload } from "react-icons/tb";
import Email from '@/components/email/Email';
import useDeleteApiReq from '@/hooks/useDeleteApiReq';
import usePostApiReq from '@/hooks/usePostApiReq';
import DeleteModal from '@/components/deleteModal/DeleteModal';

const Emails = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: deleteRes, fetchData: fetchDeleteData, isLoading: isDeleteLoading } = usePostApiReq();

    const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
    const [folder, setFolder] = useState("INBOX");
    const [emails, setEmails] = useState([]);
    const [isEmailDetail, setIsEmailDetail] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [page, setPage] = useState(1);
    const [email, setEmail] = useState("");
    const [selected, setSelected] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleEmailClick = (email) => {
        setEmail(email);
        setIsEmailDetail(true);
    }

    const handleFolderClick = (value) => {
        setPage(1);
        setSelected([]);
        setFolder(value);
        setIsEmailDetail(false);
    }

    const handleClick = (e) => {
        if (e.target.checked) {
            const Emails = emails.map((email) => email?.seqno)
            setSelected(Emails);
        }
        else {
            setSelected([]);
        }
    }

    const getEmails = (loader=true) => {
        fetchData(`/user/get-mail?folderType=${folder}&pageNumber=${page}`,{},true,loader);
    }

    useEffect(() => {
        if (!isEmailDetail) {
            getEmails();
        }
    }, [folder, page, isEmailDetail])

    useEffect(() => {
        if (isLoading || isDeleteLoading) {
            setPageCount(0);
        }
    }, [isLoading, isDeleteLoading])


    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setEmails(res?.data?.response?.emails)
            setPageCount(res?.data?.response?.totalPages);
            console.log("emails", res);
        }
    }, [res])

    useEffect(() => {
        let id;
        if (folder === "INBOX") {
            id = setInterval(() => {
                getEmails(false)
            }, 120000);
        }

        return () => {
            clearInterval(id)
        }
    }, [folder])



    const handleDeleteModalOpen = (e) => {
        e.stopPropagation();
        setIsDeleteModalOpen(true);
    }

    const handleDelete = (seqno) => {
        fetchDeleteData(`/user/delete-mail`, { folderType: folder, seqno: seqno });
    }

    useEffect(() => {
        if (deleteRes?.status === 200 || deleteRes?.status === 201) {
            setSelected([]);
            setIsDeleteModalOpen(false);
            getEmails();
            console.log("deleteRes", deleteRes);
        }
    }, [deleteRes])


    return (

        <>
            <div className='emails h-full'>
                <div className="flex justify-between items-center">
                    <h1 className="text-3xl font-bold py-2">Emails</h1>
                    <div className='flex gap-2'>
                        <button onClick={() => getEmails()} className="px-4 py-2 rounded-md flex items-center gap-3 border-2">
                            <TbReload size={20} />
                        </button>
                        <button onClick={() => setIsSendEmailModalOpen(true)} className="px-4 py-2 rounded-md flex items-center gap-3 border-2">
                            <IoMdSend size={18} />
                            Send Email
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-[15%_85%] h-full mt-4">
                    <div className='border-r-2 pr-2 flex flex-col gap-2'>
                        <div onClick={() => handleFolderClick("INBOX")} className={`flex gap-3 items-center px-3 py-1 ${folder === "INBOX" && "bg-slate-200"} hover:bg-slate-200 rounded-tr-full rounded-br-full cursor-pointer`}>
                            <RiInboxFill className="text-slate-700" size={22} />
                            <span className='font-medium text-lg'>Inbox</span>
                        </div>
                        <div onClick={() => handleFolderClick("INBOX.Drafts")} className={`flex gap-3 items-center px-3 py-1 ${folder === "INBOX.Drafts" && "bg-slate-200"} hover:bg-slate-200 rounded-tr-full rounded-br-full cursor-pointer`}>
                            <MdDrafts className="text-slate-700" size={22} />
                            <span className='font-medium text-lg'>Drafts</span>
                        </div>
                        <div onClick={() => handleFolderClick("INBOX.Sent")} className={`flex gap-3 items-center px-3 py-1 ${folder === "INBOX.Sent" && "bg-slate-200"} hover:bg-slate-200 rounded-tr-full rounded-br-full cursor-pointer`}>
                            <IoMdSend className="text-slate-700" size={22} />
                            <span className='font-medium text-lg'>Sent</span>
                        </div>
                        <div onClick={() => handleFolderClick("INBOX.Junk")} className={`flex gap-3 items-center px-3 py-1 ${folder === "INBOX.Junk" && "bg-slate-200"} hover:bg-slate-200 rounded-tr-full rounded-br-full cursor-pointer`}>
                            <FaFireAlt className="text-slate-700" size={22} />
                            <span className='font-medium text-lg'>Junk</span>
                        </div>
                        <div onClick={() => handleFolderClick("INBOX.Trash")} className={`flex gap-3 items-center px-3 py-1 ${folder === "INBOX.Trash" && "bg-slate-200"} hover:bg-slate-200 rounded-tr-full rounded-br-full cursor-pointer`}>
                            <FaRegTrashAlt className="text-slate-700" size={22} />
                            <span className='font-medium text-lg'>Trash</span>
                        </div>
                    </div>
                    <div className="px-2 overflow-y-auto h-[64vh]">
                        {isEmailDetail ?
                            <EmailDetails
                                folderType={folder}
                                email={email}
                                setIsEmailDetail={setIsEmailDetail}
                            />
                            : <div>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>
                                                <Input onChange={(e) => handleClick(e)} className="w-4 h-4" type="checkbox" />
                                            </TableHead>
                                            <TableHead></TableHead>
                                            <TableHead>
                                                {selected.length > 0 && <FaTrash className='cursor-pointer' onClick={(e) => handleDeleteModalOpen(e)} />}
                                            </TableHead>
                                            <TableHead></TableHead>
                                            <TableHead></TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {emails?.map((email, i) => (
                                            <Email
                                                key={i}
                                                email={email}
                                                folder={folder}
                                                handleEmailClick={handleEmailClick}
                                                handleDelete={handleDelete}
                                                setSelected={setSelected}
                                                selected={selected}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>

                                {!isLoading && res && res.data.response.emails.length === 0 &&
                                    <p className='text-center mt-2'>Email not found</p>
                                }

                                {pageCount > 0 &&
                                    <div className="mt-5 pb-8">
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            pageCount={pageCount}
                                            onPageChange={(e) => setPage(e.selected + 1)}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                            renderOnZeroPageCount={null}
                                            forcePage={page - 1}
                                        />
                                    </div>}
                            </div>}


                    </div>
                </div>
            </div>
            {isSendEmailModalOpen &&
                <SendEmailModal
                    isSendEmailModalOpen={isSendEmailModalOpen}
                    setIsSendEmailModalOpen={setIsSendEmailModalOpen}
                    getEmails={getEmails}
                />
            }
            {isDeleteModalOpen &&
                <DeleteModal
                    handleDelete={() => handleDelete(selected)}
                    setState={setIsDeleteModalOpen}
                />
            }
        </>
    )
}

export default Emails