import { useState } from "react";
import { axiosInstance } from "../utils/axiosInstance";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { handleLoading } from "../store/slices/loadingSlice";
import { useNavigate } from "react-router-dom";

const useGetApiReq = () => {
  const [res, setRes] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async (url, config = {}, showMessage = true,loader=true) => {
    try {
      setIsLoading(loader);
      dispatch(handleLoading(loader));
      const response = await axiosInstance.get(url, config);
      setRes(response);
    } catch (error) {
      setRes(null)
      console.log("get request err",error);
      if (showMessage) {
        toast.error(error?.response?.data?.message || "An error occurred.");
      }
      if (error?.response?.data?.tokenExpired) {
        navigate("/");
      }
    } finally {
      setIsLoading(false);
      dispatch(handleLoading(false));
    }
  };

  return { res, isLoading, fetchData };
};

export default useGetApiReq;
