import useGetApiReq from '@/hooks/useGetApiReq';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import ManagerProjectView from '../managerProjectView/ManagerProjectView';
import EmployeeProjectView from '../employeeProjectView/EmployeeProjectView';
import { readCookie } from '@/utils/readCookie';

const EmployeeOrManagerProjectview = () => {
    const { res: managerRes, fetchData: fetchManagerData, isLoading: isManagerLoading } = useGetApiReq();
    const params = useParams();
    const userInfo = readCookie("userInfo")

    const getProjectManagerStatus = async () => {
        fetchManagerData(`/admin/get-project-access-admin/${params?.pId}`);
    }

    useEffect(() => {
        getProjectManagerStatus()
    }, [])

    console.log("managerRes", managerRes);

    return (
        <>
            {managerRes &&
                managerRes?.data?.data?.employeePermissions === "write" &&
                managerRes?.data?.data?.employeeId === userInfo?.userId

                ? <ManagerProjectView />
                : <EmployeeProjectView />
            }
        </>
    )
}

export default EmployeeOrManagerProjectview