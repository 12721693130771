import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { FaCirclePlus, FaEye } from 'react-icons/fa6'
import { MdDelete } from 'react-icons/md'
import { axiosInstance } from '../../utils/axiosInstance'
import toast from 'react-hot-toast'
import AddExpenseModal from '../../components/add-expenseModal/AddExpenseModal'
import DeleteModal from '../../components/deleteModal/DeleteModal'
import { FaEdit } from 'react-icons/fa'

import 'bootstrap/dist/css/bootstrap.min.css';
import ExpenseInfoModal from '../../components/expense-infoModal/ExpenseInfoModal'
import { openCloseBody } from '../../store/slices/bodySlice'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'
import LimitComp from '../../components/limit-comp/LimitComp'
import useGetApiReq from '../../hooks/useGetApiReq'
import Search from '@/components/search/Search'

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Loader from '@/components/loader/Loader'

const AllExpenses = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false)
    const [isUpdateExpenseModalOpen, setIsUpdateExpenseModalOpen] = useState(false)
    const [isExpenseInfoModalOpen, setIsExpenseInfoModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [allExpenses, setAllExpenses] = useState([]);
    const [deleteId, setDeleteId] = useState("");
    // const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [expense, setExpense] = useState({});
    const [limit, setLimit] = useState(5);

    const dispatch = useDispatch();

    const getAllExpenses = async () => {
        // setAllExpenses([]);
        fetchData(`/admin/get-all-expense?page=${page}&limit=${limit}`);
    }

    useEffect(() => {
        getAllExpenses();
    }, [page, limit])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            const count = Math.ceil(res?.data?.totalDocs / limit);
            setPageCount(count);
            //console.log("expenses", res);
            setAllExpenses(res?.data?.data);
        }
    }, [res])


    const handleDeleteModal = (id) => {
        setIsDeleteModalOpen(true);
        setDeleteId(id);
    }

    const handleUpdateModal = (data) => {
        setIsUpdateExpenseModalOpen(true);
        setExpense(data);
    }

    const handleInfoModal = (data) => {
        setIsExpenseInfoModalOpen(true);
        setExpense(data);
    }

    const handleDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-expense/${deleteId}`);
            toast.success("Expense deleted successfully");
            setIsDeleteModalOpen(false);
            getAllExpenses();
            //console.log(data);
        } catch (error) {
            //console.log(error);
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        const handleModalOpen = () => {
            if (isAddExpenseModalOpen || isDeleteModalOpen || isExpenseInfoModalOpen || isUpdateExpenseModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isAddExpenseModalOpen, isDeleteModalOpen, isExpenseInfoModalOpen, isUpdateExpenseModalOpen])

    return (
        <div>
            <h1 className="text-3xl font-bold py-2">Expenses</h1>

            <div className="flex items-center gap-2 mt-8">
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />

                <button onClick={() => setIsAddExpenseModalOpen(true)} className="px-4 py-2 border-2 rounded-md flex items-center gap-3">
                    <FaCirclePlus size={18} />
                    Add Expense
                </button>
                <Search
                    placeholder="Search Expense"
                />
            </div>


            <Table className="mt-4">
                <TableHeader>
                    <TableRow>
                        <TableHead>Expense Id</TableHead>
                        <TableHead>Item</TableHead>
                        <TableHead>From</TableHead>
                        <TableHead>Date</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allExpenses?.map((expense) => (
                        <TableRow key={expense._id}>
                            <TableCell onClick={() => handleInfoModal(expense)}>#{expense.expenseId}</TableCell>
                            <TableCell>{expense.item}</TableCell>
                            <TableCell>{expense.from}</TableCell>
                            <TableCell>{format(new Date(expense.date), "dd-MM-yyy")}</TableCell>
                            <TableCell>
                                <p className={`text-center font-semibold w-28 rounded-tl-xl rounded-br-xl px-[3px] py-2 text-white ${expense.status === "completed" ? "bg-green-400 text-black" : (expense.status === "in-progress") ? "bg-yellow-400" : "bg-sky-500"}`}>{expense.status}</p>
                            </TableCell>
                            <TableCell>
                                <div className="flex items-center gap-2">
                                    <FaEdit onClick={() => handleUpdateModal(expense)} size={20} cursor={"pointer"} />
                                    <MdDelete onClick={() => handleDeleteModal(expense?._id)} size={20} color="red" cursor={"pointer"} />
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {allExpenses.length === 0 && !isLoading &&
                <p>No expenses found</p>
            }
            {allExpenses.length === 0 && isLoading &&
                <Loader />
            }

            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}



            {
                isAddExpenseModalOpen &&
                <AddExpenseModal
                    setState={setIsAddExpenseModalOpen}
                    getAllExpenses={getAllExpenses}
                />
            }

            {
                isUpdateExpenseModalOpen &&
                <AddExpenseModal
                    setState={setIsUpdateExpenseModalOpen}
                    getAllExpenses={getAllExpenses}
                    expense={expense}
                />
            }

            {
                isExpenseInfoModalOpen &&
                <ExpenseInfoModal
                    setState={setIsExpenseInfoModalOpen}
                    expense={expense}
                />
            }

            {
                isDeleteModalOpen &&
                <DeleteModal
                    setState={setIsDeleteModalOpen}
                    handleDelete={handleDelete}
                />
            }
        </div >
    )
}

export default AllExpenses