import { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axiosInstance";
import Employee from "../../components/employee/Employee";
import MarkAttendanceModal from "../../components/mark-attendanceModal/MarkAttendanceModal";
import { openCloseBody } from "../../store/slices/bodySlice";
import { useDispatch } from "react-redux";
import avatar from "../../assets/employee.png"
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import LimitComp from "../../components/limit-comp/LimitComp";
import Loader from "@/components/loader/Loader";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

const Attendance = () => {
    const [isMarkAttendanceModalOpen, setIsMarkAttendanceModalOpen] = useState(false);
    const [allEmployees, setAllEmployees] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAllEmployee = async () => {
        try {
            setIsLoading(true);
            setAllEmployees([]);
            const { data } = await axiosInstance.get(`/admin/get-employee?page=${page}&limit=${limit}`);
            console.log("emp", data);
            setAllEmployees(data.data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        getAllEmployee();
    }, [page, limit])

    useEffect(() => {
        const handleModalOpen = () => {
            if (isMarkAttendanceModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isMarkAttendanceModalOpen])

    return (
        <>
            <div>
                <h1 className="text-3xl font-bold py-2">Attendance</h1>
                <div className="mt-5">
                    <button onClick={() => setIsMarkAttendanceModalOpen(true)} className="px-4 py-2 bg-[#348AC8] rounded-tl-xl rounded-br-xl text-white">
                        Mark Absent
                    </button>
                </div>

                <Table className="mt-3">
                    <TableHeader>
                        <TableRow>
                            <TableHead></TableHead>
                            <TableHead>Employee Name</TableHead>
                            <TableHead>Position</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allEmployees?.map((employee) => (
                            <TableRow key={employee._id}>
                                <TableCell>
                                    <div onClick={() => navigate(`/employees/${employee._id}`, { state: employee })} className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden border-2'>
                                        <img className="w-full h-full" src={employee.image ? `${import.meta.env.VITE_IMAGE_URL}/${employee.image}` : avatar} alt="employee" />
                                    </div>
                                </TableCell>
                                <TableCell className="cursor-pointer" onClick={() => navigate(`/employees/${employee._id}`, { state: employee })}>{employee.name}</TableCell>
                                <TableCell>{employee.position}</TableCell>
                                <TableCell>
                                    <p className={`w-24 px-2 py-2 rounded-tl-xl font-semibold rounded-br-xl text-center text-white ${employee?.status ? "bg-green-400" : "bg-red-500"}`}>{employee?.status ? "Active" : "InActive"}</p>
                                </TableCell>
                                <TableCell>
                                    <button onClick={() => navigate(`/allAttendance/${employee._id}`)} className="px-4 w-24 py-2 bg-black rounded-tl-xl font-semibold rounded-br-xl text-white">
                                        View
                                    </button>
                                </TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
                {allEmployees.length === 0 && !isLoading &&
                    <p>No employee found</p>
                }

                {allEmployees.length === 0 && isLoading &&
                    <Loader />
                }

                {pageCount > 0 &&
                    <div className="mt-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}
            </div>
            {isMarkAttendanceModalOpen &&
                <MarkAttendanceModal
                    setState={setIsMarkAttendanceModalOpen}
                />
            }
        </>
    )
}

export default Attendance