import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { useState } from 'react';
import StoryInfoModal from '../story-infoModal/StoryInfoModal';
import ModuleInfoModal from '../module-infoModal/ModuleInfoModal';

const TimlineView = ({ allResources, allEvents }) => {
    const [story, setStory] = useState("");
    const [module, setModule] = useState("");

    const [isStoryInfoModalOpen, setIsStoryInfoModalOpen] = useState(false);
    const [isModuleInfoModalOpen, setIsModuleInfoModalOpen] = useState(false);

    const handleEventClick = (arg) => {
        console.log('story clicked')
        console.log('arg.event', arg.event.extendedProps)
        setStory(arg.event.extendedProps.story);
        setIsStoryInfoModalOpen(true);
    };

    return (
        <>
            <div className='mt-5'>
                <FullCalendar
                    plugins={[resourceTimelinePlugin]}
                    initialView='resourceTimelineMonth'
                    height={"70vh"}
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: ''
                    }}
                    resources={allResources}

                    events={allEvents}
                    slotLabelFormat={{
                        day: 'numeric',
                        month: 'short',
                    }}
                    eventContent={(eventInfo) => {
                        const status = eventInfo.event.extendedProps.status;
                        return (
                            <div
                                className={`rounded-tr-full text-white rounded-br-full border-l-4 ${status === "completed" ? 'bg-[#7AFCBAE5] border-[#08954e]' : status === "upcoming" ? "bg-[#3498DB]" : status === "needs-review" ? "bg-[#F3828E99] border-[#AB1929]" : "bg-[#FEB64099] border-[#C89209]"}`}
                            >
                                <div className={`relative mr-8 rounded-tr-full rounded-br-full p-2 ${status === "completed" ? 'bg-[#18D676]' : status === "upcoming" ? "bg-[#3498DB]" : status === "needs-review" ? "bg-[#E85F6E]" : "bg-[#F5C678]"}`}>
                                    {eventInfo.event.title}
                                </div>
                            </div>
                        );
                    }}

                    resourceAreaHeaderContent={"Modules"}
                    resourceLabelContent={(resourceInfo) => {
                        //console.log("resourceInfo content",resourceInfo.resource.extendedProps);
                        const status = resourceInfo.resource.extendedProps.status;
                        return (
                            <div
                                style={{
                                    backgroundColor: status === "completed" ? '#008000' : status === "upcoming" ? "#3498DB" : status === "needs-review" ? "#ef4444" : "#FFD700",
                                    color: 'white',
                                    width: "auto",
                                    padding: "4px 10px",
                                    borderTopRightRadius: "30px",
                                    borderBottomRightRadius: "30px",
                                }}
                            >
                                {resourceInfo.resource.title}
                            </div>
                        );
                    }}
                    eventClick={handleEventClick}
                />
            </div>
            {isStoryInfoModalOpen &&
                <StoryInfoModal
                    setState={setIsStoryInfoModalOpen}
                    story={story}
                />
            }

            {isModuleInfoModalOpen &&
                <ModuleInfoModal
                    setState={setIsModuleInfoModalOpen}
                    module={""}
                />
            }
        </>
    )
}

export default TimlineView