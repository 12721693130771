import { useState } from 'react'
import classes from './ModuleView.module.css'
import Module from '../../components/module/Module';
import { axiosInstance } from '../../utils/axiosInstance';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaCirclePlus } from 'react-icons/fa6';
import CreateModuleModal from '../../components/create-module-modal/CreateModuleModal';
import { FaArrowLeft } from 'react-icons/fa';
import loader from '../../assets/rolling.gif';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import ModuleFilter from '../../components/module-filter/ModuleFilter';
import LimitComp from '../../components/limit-comp/LimitComp';
import useGetApiReq from '../../hooks/useGetApiReq';
import AllModuleStory from '../../components/all-module-story/AllModuleStory';
import Search from '@/components/search/Search';


const ModuleView = () => {
    const { res: storyRes, fetchData: fetchStoryData, isLoading: isStoryLoading } = useGetApiReq();
    const { res: moduleRes, fetchData: fetchModuleData, isLoading: isModuleLoading } = useGetApiReq();

    const [allModule, setAllModule] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [date, setDate] = useState("");
    const [status, setStatus] = useState("");
    const [dateType, setDateType] = useState("startDate");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [isStory, setIsStory] = useState(false);
    const [allStories, setAllStories] = useState([]);

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const getAllModule = async () => {
        setAllModule([]);
        fetchModuleData(`/admin/filter-modules?projectId=${params?.id ? params?.id : params?.pId}&date=${date}&status=${status}&dateType=${dateType}`);
    }

    useEffect(() => {
        if (moduleRes?.status === 200 || moduleRes?.status === 201) {
            setAllModule(moduleRes?.data?.modules);
            const count = Math.ceil(moduleRes?.data?.totalDocs / limit);
            setPageCount(count);
        }
    }, [moduleRes])

    const getAllStories = async () => {
        setAllStories([]);
        fetchStoryData(`/admin/get-stories/${params?.id ? params?.id : params?.pId}?page=${page}&limit=${limit}&date=${date}&status=${status}&dateType=${dateType}`);
    }

    useEffect(() => {
        if (storyRes?.status === 200 || storyRes?.status === 201) {
            console.log("story res",storyRes);
            setAllStories(storyRes?.data.stories);
            const count = Math.ceil(storyRes?.data?.totalDocs / limit);
            setPageCount(count);
        }
    }, [storyRes])



    useEffect(() => {
        getAllModule()
    }, [page, limit, isStory])

    useEffect(() => {
        isStory && getAllStories()
    }, [page, limit, isStory, date, status, dateType])

    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen();
    }, [isModalOpen])


    const filterModules = async () => {
        setAllModule([]);
        await fetchModuleData(`/admin/filter-modules?projectId=${params?.id?params?.id:params?.pId}&date=${date}&status=${status}&dateType=${dateType}`);
    }

    useEffect(() => {
        !isStory && filterModules();
    }, [date, status, dateType])


    const handleDateChange = (e) => {
        setDate(() => e.target.value);

    }

    const handleStatusChange = (e) => {
        setStatus(() => e.target.value);

    }

    const handleDateTypeChange = (e) => {
        setDateType(() => e.target.value);

    }

    const handleReset = () => {
        setDate("");
        setStatus("");
        setDateType("startDate")
    }


    return (
        <>
            <div>
                <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
                <h1 className="text-3xl font-bold py-2">All {isStory ? "Stories" : "Modules"}</h1>

                <div className="flex items-center gap-2 mt-8">
                    <LimitComp
                        setLimit={setLimit}
                        limit={limit}
                    />
                    {!isStory && <button onClick={() => setIsModalOpen(true)} className='border-2 flex items-center gap-2 px-4 py-2 rounded-tl-xl rounded-br-xl'>
                        <FaCirclePlus size={18} />
                        Create Module
                    </button>}

                    <button onClick={() => {
                        setIsStory(!isStory); handleReset()
                    }} className='border-2 flex items-center gap-2 px-4 py-2 rounded-tl-xl rounded-br-xl'>
                        Switch {!isStory ? "Story" : "Module"}
                    </button>

                    <Search
                        placeholder={`Search ${isStory ? "Story" : "Module"}`}
                    />

                    <ModuleFilter
                        handleDateChange={handleDateChange}
                        handleStatusChange={handleStatusChange}
                        handleDateTypeChange={handleDateTypeChange}
                        handleReset={handleReset}
                        status={status}
                        date={date}
                        dateType={dateType}
                    />
                </div>

                {isStory ?
                    <div className={classes.module_container}>
                        {allStories?.length === 0 && !isStoryLoading &&
                            <p>No story found</p>
                        }

                        {allStories?.map((story) => (
                            <AllModuleStory
                                key={story._id}
                                story={story}
                                getAllStories={getAllModule}
                            />
                        ))}
                    </div>

                    : <div className={classes.module_container}>
                        {allModule?.length === 0 && !isModuleLoading &&
                            <p>No module found</p>
                        }

                        {allModule?.map((module) => (
                            <Module
                                key={module._id}
                                module={module}
                                getAllModule={getAllModule}
                                getStories={() => { }}
                            />
                        ))}
                    </div>}

                {pageCount > 0 &&
                    <div className="mt-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}
            </div >

            {isModalOpen &&
                <CreateModuleModal
                    isUpdate={false}
                    setState={setIsModalOpen}
                    getAllModule={getAllModule}
                />
            }
        </>
    )
}

export default ModuleView