import { RxCross2 } from 'react-icons/rx'
import classes from './AddEmployeeModal.module.css'
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import { validate } from '../../utils/validate';
import { useDispatch } from 'react-redux';
import { openCloseBody } from '../../store/slices/bodySlice';
import usePostApiReq from '../../hooks/usePostApiReq';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';

const AddEmployeeModal = ({ handleModal, isUpdate, employee = "", getAllEmployee }) => {
  const { res, fetchData, isLoading } = usePostApiReq();
  const { res: updateRes, fetchData: updateFetchData, isLoading: updateIsLoading } = usePatchApiReq();

  const [employeeInfo, setEmployeeInfo] = useState({
    name: employee?.name || "",
    email: employee?.email || "",
    phone: employee?.phone || "",
    password: "",
    emailPassword: employee?.emailPassword || "",
    position: employee?.position || "",
    joiningDate: employee?.joiningDate && format(new Date(employee.joiningDate), "yyyy-MM-dd") || "",
    department: employee?.department || "",
    role: employee?.role || "",
    status: employee?.status || true,
    img: employee?.image || "",
    previewImage: "",
    access: "",
    doc: "",
    docPrev: "",
  });
  const [validateState, setValidateState] = useState([]);
  const [isPreview, setIsPreview] = useState(employee ? false : true);

  const [permissions, setPermissions] = useState({
    allProjects: employee?.permissions?.allProjects || "none",
    employees: employee?.permissions?.employees || "none",
    clients: employee?.permissions?.clients || "none",
    calender: employee?.permissions?.calender || "none",
    report: employee?.permissions?.report || "none",
    documents: employee?.permissions?.documents || "none",
    resumes: employee?.permissions?.resumes || "none",
    expenses: employee?.permissions?.expenses || "none",
    allAttendance: employee?.permissions?.allAttendance || "none",
    allTickets: employee?.permissions?.allTickets || "none",
  });
  const [isShowEmailPassword, setIsShowEmailPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handlePermissionChange = (key, value) => {
    setPermissions({ ...permissions, [key]: value });
  };

  const dispatch = useDispatch();

  const handleModalOpen = () => {
    handleModal();
    dispatch(openCloseBody(false));
  }

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    if (name === "email") {
      value = value.toLowerCase();
    }
    setEmployeeInfo({ ...employeeInfo, [name]: value });
  }

  const getImage = (event) => {
    event.preventDefault();
    // getting the image
    const uploadedImage = event.target.files[0];

    // if image exists then getting the url link of it
    if (uploadedImage) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(uploadedImage);
      fileReader.addEventListener("load", function () {
        setIsPreview(true);
        setEmployeeInfo({
          ...employeeInfo,
          previewImage: this.result,
          img: uploadedImage,
        });
      });
    }
  };


  const handleOnSubmit = async (e) => {
    e.preventDefault();
    let res;
    if (isUpdate) {
      res = validate({
        "Department": employeeInfo.department,
        "Email": employeeInfo.email,
        "Joining Date": employeeInfo.joiningDate,
        "Name": employeeInfo.name,
        "Phone": employeeInfo.phone,
        "Position": employeeInfo.position,
        "Status": employeeInfo.status,
        "Role": employeeInfo.role,
      });
    }
    else {
      res = validate({
        "Department": employeeInfo.department,
        "Email": employeeInfo.email,
        "Joining Date": employeeInfo.joiningDate,
        "Name": employeeInfo.name,
        "Password": employeeInfo.password,
        "Email Password": employeeInfo.emailPassword,
        "Phone": employeeInfo.phone,
        "Position": employeeInfo.position,
        "Status": employeeInfo.status,
        "Role": employeeInfo.role,
      });
    }

    const validateArr = [];
    for (const key in res) {
      if (!res[key].status) {
        validateArr.push(key);
        setValidateState(validateArr);
      }
      else {
        const temp = validateArr.filter((item) => item !== key);
        setValidateState(temp);
      }
    }

    if (!employeeInfo.name
      || !employeeInfo.email
      || !employeeInfo.phone
      || (!isUpdate ? !employeeInfo.password : false)
      || !employeeInfo.position
      || !employeeInfo.joiningDate
      || !employeeInfo.department
      || !employeeInfo.role
    ) {
      return null;
    }

    const formdata = new FormData();
    formdata.append("department", employeeInfo.department);
    formdata.append("email", employeeInfo.email);
    formdata.append("joiningDate", employeeInfo.joiningDate);
    formdata.append("name", employeeInfo.name);
    formdata.append("password", employeeInfo.password);
    formdata.append("emailPassword", employeeInfo.emailPassword);
    formdata.append("phone", employeeInfo.phone);
    formdata.append("position", employeeInfo.position);
    formdata.append("status", employeeInfo.status);
    formdata.append("role", employeeInfo.role);
    formdata.append("resume", employeeInfo.doc);
    // if (employeeInfo.role === "employee") {
    formdata.append("permissions", JSON.stringify(permissions));
    // }
    if (employeeInfo.img) {
      formdata.append("img", employeeInfo.img);
    }


    if (isUpdate) {
      updateFetchData(`/admin/update-employee/${employee._id}`, formdata);
    }
    else {
      fetchData("/user/create-user", formdata);
    }
  }

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      getAllEmployee();
      handleModalOpen();
    }
  }, [res])

  useEffect(() => {
    if (updateRes?.status === 200 || updateRes?.status === 201) {
      getAllEmployee();
      handleModalOpen();
    }
  }, [updateRes])

  const getDoc = (event) => {
    event.preventDefault();
    // getting the image
    const uploadedDoc = event.target.files[0];

    // if image exists then getting the url link of it
    if (uploadedDoc) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(uploadedDoc);
      fileReader.addEventListener("load", function () {
        setEmployeeInfo({
          ...employeeInfo,
          docPrev: this.result,
          doc: uploadedDoc,
        });
      });
    }
  };


  return (
    <div className={classes.wrapper}>
      <div className={classes.box}>
        <div className={classes.heading_container}>
          <h3 className='text-2xl'>{isUpdate ? "Update Employee" : "Add Employee"}</h3>
          <RxCross2 onClick={handleModalOpen} cursor={"pointer"} size={26} />
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className={classes.input_container}>
            <label htmlFor="img">Profile Image</label>
            <div className='flex items-center'>
              {isPreview && employeeInfo.previewImage &&
                <img
                  className="w-28 h-28 rounded-full m-auto"
                  src={employeeInfo.previewImage}
                  alt="preview image"
                />
              }
              {!isPreview && employeeInfo.img &&
                <img
                  className="w-28 h-28 rounded-full m-auto"
                  src={`${import.meta.env.VITE_IMAGE_URL}/${employeeInfo.img}`}
                  alt="preview image"
                />
              }
              <input onChange={getImage} type="file" id="img" name="img" />
            </div>
          </div>
          <div className={classes.input_container}>
            <label htmlFor="name">Name</label>
            <input onChange={handleOnChange} value={employeeInfo.name} className={classes.input} type="text" placeholder="Enter Name" name="name" id="name" />
          </div>
          <div className={classes.input_container}>
            <label htmlFor="email">Email</label>
            <input onChange={handleOnChange} value={employeeInfo.email} className={classes.input} type="email" placeholder="Enter Email Id" name="email" id="email" />
          </div>
          <div className={classes.input_container}>
            <label htmlFor="phone">Phone</label>
            <input onChange={handleOnChange} value={employeeInfo.phone} className={classes.input} type="tel" placeholder="Enter Phone No" name="phone" id="phone" />
          </div>
          {!isUpdate && <div className={classes.input_container}>
            <label htmlFor="password">Password</label>
            <input onChange={handleOnChange} className={classes.input} type={isShowPassword ? "text" : "password"} placeholder="Enter Password" name="password" id="password" />
            {isShowPassword ?
              <FaEyeSlash onClick={() => setIsShowPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
              : <FaEye onClick={() => setIsShowPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
            }
          </div>}
          {!isUpdate && <div className={classes.input_container}>
            <label htmlFor="emailPassword">Email Password</label>
            <input onChange={handleOnChange} className={classes.input} type={isShowEmailPassword ? "text" : "password"} placeholder="Enter Email Password" name="emailPassword" id="emailPassword" />
            {isShowEmailPassword ?
              <FaEyeSlash onClick={() => setIsShowEmailPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
              : <FaEye onClick={() => setIsShowEmailPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
            }
          </div>}
          <div className={classes.input_container}>
            <label htmlFor="position">Position</label>
            <input onChange={handleOnChange} value={employeeInfo.position} className={classes.input} type="text" placeholder="Enter Position" name="position" id="position" />
          </div>
          <div className={classes.input_container}>
            <label htmlFor="joiningDate">Joining Date</label>
            <input onChange={handleOnChange} value={employeeInfo.joiningDate} className={classes.input} type="date" placeholder="Enter Joining Date" name="joiningDate" id="joiningDate" />
          </div>
          <div className={classes.input_container}>
            <label htmlFor="department">Department</label>
            <select onChange={handleOnChange} value={employeeInfo.department} className={classes.input} name="department" id="department">
              <option value="">Select</option>
              <option value="finance">Finance</option>
              <option value="technology">Technology</option>
              <option value="marketing">Marketing</option>
              <option value="sales">Sales</option>
              <option value="customer relation">Customer Relation</option>
              <option value="manegment">Manegment</option>
            </select>
          </div>
          <div className={classes.input_container}>
            <label htmlFor="role">Role</label>
            <select onChange={handleOnChange} value={employeeInfo.role} className={classes.input} name="role" id="role">
              <option value="">Select</option>
              <option value="admin">Admin</option>
              <option value="employee">Employee</option>
            </select>
          </div>

          <div className={classes.input_container}>
            <label htmlFor="img">Resume</label>
            <div className='flex items-center'>
              <input onChange={getDoc} type="file" id="img" name="img" />
            </div>
          </div>

          {<div className={classes.input_container}>
            <label htmlFor="access">Access</label>
            <div>
              {Object.keys(permissions).map((key, index) => (
                <div key={key} className="flex justify-between">
                  <p className='text-uppercase'>{key}</p>
                  <div className="flex gap-2">
                    <div className="flex gap-1 cursor-pointer">
                      <input
                        onChange={(e) => handlePermissionChange(key, "read")}
                        type="radio"
                        name={`${key}-read`}
                        id={`${key}-read`}
                        value={permissions[key]}
                        checked={permissions[key] === "read"}
                      />
                      <span>read</span>
                    </div>
                    <div className="flex gap-1 cursor-pointer">
                      <input
                        onChange={(e) => handlePermissionChange(key, "write")}
                        type="radio"
                        name={`${key}-write`}
                        id={`${key}-write`}
                        value={permissions[key]}
                        checked={permissions[key] === "write"}
                      />
                      <span>write</span>
                    </div>
                    <div className="flex gap-1 cursor-pointer">
                      <input
                        onChange={(e) => handlePermissionChange(key, "none")}
                        type="radio"
                        name={`${key}-none`}
                        id={`${key}-none`}
                        value={permissions[key]}
                        checked={permissions[key] === "none"}
                      />
                      <span>none</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>}

          {!isUpdate && <div className={classes.input_container}>
            <label htmlFor="status">Employee Status</label>
            <select onChange={handleOnChange} value={employeeInfo.status} className={classes.input} name="status" id="status">
              <option value="true">Active</option>
              <option value="false">InActive</option>
            </select>
          </div>}
          {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
          <div className={classes.button_wrapper}>
            <button className={classes.button}>{isUpdate ? "Update" : "Create"}</button>
          </div>
        </form>

      </div>
    </div>
  )
}

export default AddEmployeeModal