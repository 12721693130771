import { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import loader from '../../assets/rolling.gif';
import { axiosInstance } from '../../utils/axiosInstance';
import classes from "./EmployeeListModal.module.css";
import EmployeeItem from '../employeeItem/EmployeeItem';
import toast from 'react-hot-toast';
import usePostApiReq from '../../hooks/usePostApiReq';
import useGetApiReq from '../../hooks/useGetApiReq';

const EmployeeListModal = ({ setIsEmployeeList, getAllMembers, projectId, allMembers }) => {
    const { res, fetchData, isLoading: loading } = usePostApiReq();
    const { res: empRes, fetchData: fetchEmpData, isLoading: isEmpLoading } = useGetApiReq();
    
    const [allEmployees, setAllEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const getAllEmployee = async () => {
        fetchEmpData("/admin/get-employee");
    }
    
    const handleOnclick = async () => {
        if (selectedEmployees.length !== 0) {
            fetchData("/admin/assign-emp-project", { projectId, employees: selectedEmployees });
        }
    }
    
    useEffect(() => {
        getAllEmployee()
    }, [])
    
    useEffect(() => {
        if (empRes?.status === 200 || empRes?.status === 201) {
            let arr = empRes?.data?.data;
            for (const member of allMembers) {
                arr = arr.filter((employee) => employee._id !== member.employeeId)
            }
            setAllEmployees(arr);
        }
    }, [empRes])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsEmployeeList(false);
            getAllMembers();
        }
    }, [res])

    return (
        <div className={classes.employees_Modal_wrapper}>
            <div className={classes.employees_Modal}>
                <div className={classes.heading_container}>
                    <h3>All Employees</h3>
                    <RxCross2 onClick={() => setIsEmployeeList(false)} cursor={"pointer"} size={26} />
                </div>
                {allEmployees.length === 0 && isEmpLoading &&
                    <div className={classes.img}>
                        <img src={loader} alt="loader" />
                    </div>
                }
                {allEmployees.length === 0 && !isEmpLoading &&
                    <div className={classes.img}>
                        <p>all Employees assigned!</p>
                    </div>
                }
                <div className={classes.employee_container}>
                    {allEmployees?.map((employee) => (
                        <EmployeeItem
                            key={employee._id}
                            employee={employee}
                            setSelectedEmployees={setSelectedEmployees}
                        />
                    ))}
                </div>
                <div className={classes.button_wrapper}>
                    <button onClick={handleOnclick} className={classes.button}>Add</button>
                </div>
            </div>
        </div>
    )
}

export default EmployeeListModal