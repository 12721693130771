import { useEffect, useState } from 'react';
import classes from './SelectProjectManagerModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { validate } from '../../utils/validate';
import usePostApiReq from '../../hooks/usePostApiReq';
import { readCookie } from '../../utils/readCookie';
import useGetApiReq from '@/hooks/useGetApiReq';

const SelectProjectManagerModal = ({ setState, projectId,getProjectManagerStatus }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: empRes, fetchData: fetchEmpData, isLoading: isEmpLoading } = useGetApiReq();

    const [projectManagerInfo, setProjectManagerInfo] = useState({
        projectManager: "",
        projectManagerAccess: "",
    });
    const [validateState, setValidateState] = useState([]);


    const [allEmployees, setAllEmployees] = useState([]);

    const getAllEmployee = async () => {
        fetchEmpData("/admin/get-employee");
    }

    useEffect(() => {
        getAllEmployee()
    }, [])

    useEffect(() => {
        if (empRes?.status === 200 || empRes?.status === 201) {
            setAllEmployees(empRes?.data?.data);
        }
    }, [empRes])

    const cookies = readCookie("userInfo");
    const userId = cookies?.userId;


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setProjectManagerInfo({ ...projectManagerInfo, [name]: value });
    }


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "Project Manager": projectManagerInfo.projectManager,
            "Project Manager Access": projectManagerInfo.projectManagerAccess,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!projectManagerInfo.projectManager
            || !projectManagerInfo.projectManagerAccess
        ) {
            return;
        }

        fetchData(`/admin/update-project-access-admin`, {
            projectId: projectId,
            employeeId: projectManagerInfo.projectManager,
            permissions: projectManagerInfo.projectManagerAccess
        });

    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("project manager res",res);
            setState(false);
            getProjectManagerStatus();
        }
    }, [res])

    return (
        <div className={classes.add_document_wrapper}>
            <div className={classes.add_document_box}>
                <div className={classes.heading_container}>
                    <h3>Select Project Manager</h3>
                    <div className={classes.d_flex}>
                        <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                    </div>
                </div>
                <form onSubmit={handleOnSubmit} className={classes.form}>
                    <div className={classes.container}>
                        <label htmlFor="projectManager">Project Manager</label>
                        <select onChange={handleOnChange} value={projectManagerInfo.projectManager} className={classes.input} name="projectManager" id="projectManager">
                            <option value="">Select</option>
                            {allEmployees?.map((employee) => (
                                <option key={employee._id} value={employee._id}>{employee.name}</option>
                            ))}
                        </select>
                    </div>

                    {projectManagerInfo.projectManager &&
                        <div className={classes.container}>
                            <label htmlFor="projectManagerAccess">Project Manager Access</label>
                            <select onChange={handleOnChange} value={projectManagerInfo.projectManagerAccess} className={classes.input} name="projectManagerAccess" id="projectManagerAccess">
                                <option value="">Select</option>
                                <option value="read">Read</option>
                                <option value="write">Write</option>
                                <option value="none">None</option>
                            </select>
                        </div>
                    }
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>Continue</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SelectProjectManagerModal