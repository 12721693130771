import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import usePostApiReq from '../../hooks/usePostApiReq';

const ForgotPassword = () => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const [info, setinfo] = useState({
        name: "",
        email: ""
    });

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        setinfo({ ...info, [name]: value });
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!info.name || !info.email) {
            toast.error("All fields are required");
            return;
        }

        fetchData("/user/user-forget-password", { ...info });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setinfo({
                name: "",
                email: ""
            });
        }
    }, [res])

    return (
        <div className="flex justify-center items-center h-[calc(100vh-100px)]">
            <div className="max-w-lg w-full border-2 border-gray-600 p-4 rounded">
                <h1 className="text-2xl text-center">Forgot Password</h1>
                <form onSubmit={handleOnSubmit} className="w-full">
                    <div>
                        <label htmlFor="name">Name</label>
                        <input onChange={handleOnChange} value={info.name} className="border-2 border-gray-500 rounded w-full py-1 px-2 mt-1" type="text" name="name" id="name" />
                    </div>
                    <div className="mt-3">
                        <label htmlFor="email">Email</label>
                        <input onChange={handleOnChange} value={info.email} className="border-2 border-gray-500 rounded w-full py-1 px-2 mt-1" type="email" name="email" id="email" />
                    </div>

                    <div className="flex justify-center items-center mt-3">
                        <button className="bg-black text-white py-1 px-2 rounded">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword