import { useEffect, useState } from "react";
import ProjectCard from "../../components/projectCard/ProjectCard";
import { useNavigate } from "react-router-dom";

import CreateProjectModal from "../../components/create-project-modal/CreateProjectModal";
import { useDispatch } from "react-redux";
import { openCloseBody } from "../../store/slices/bodySlice";
import ReactPaginate from "react-paginate";
import usePostApiReq from "../../hooks/usePostApiReq";
import { BiSolidFileExport } from "react-icons/bi";
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Search from "@/components/search/Search";
import useGetApiReq from "@/hooks/useGetApiReq";


const AllProjects = () => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: projectRes, fetchData: fetchProjectData, isLoading: isProjectLoading } = useGetApiReq();

    const [allProjects, setAllProjects] = useState([]);
    const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
    const [status, setStatus] = useState("ongoing");
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleModalOpen = () => {
        setIsCreateProjectModalOpen(() => true);
        dispatch(openCloseBody(true));
    }


    const handleProjectChange = async (value) => {
        setStatus(value);
        setAllProjects([]);
        if (status === "all") {
            fetchProjectData(`/admin/get-all-projects?page=${page}&limit=${limit}`);
        }
        else {
            fetchData(`/admin/get-project-by-status?page=${page}&limit=${limit}`, { status: value });
        }
    }

    useEffect(() => {
        handleProjectChange(status);
    }, [page, limit, status])

    // useEffect(() => {
    //     setAllProjects([]);
    // }, [status])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("all projects by status", res?.data);
            setAllProjects(res?.data?.data);
            setPageCount(projectRes?.data?.totalPage);
        }
    }, [res])

    useEffect(() => {
        if (projectRes?.status === 200 || projectRes?.status === 201) {
            console.log("all projects", projectRes?.data);
            setAllProjects(projectRes?.data?.data);
            setPageCount(projectRes?.data?.totalPage);
        }
    }, [projectRes])

    return (
        <>
            <div>
                <div className="flex justify-between items-center px-2">
                    <h1 className="text-3xl text-[#374151] font-bold py-2">All Projects</h1>
                </div>
                <div className="flex items-center gap-2 mt-8">
                    <button onClick={handleModalOpen} className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl">Add Project</button>
                    <select className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl" value={status} onChange={(e) => handleProjectChange(e.target.value)} name="" id="">
                        <option value="all">All</option>
                        <option value="upcoming">Upcoming</option>
                        <option value="ongoing">Ongoing</option>
                        <option value="completed">Completed</option>
                    </select>
                    <select className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl" name="" id="">
                        <option value="">Filter</option>
                        <option value="upcoming">Upcoming</option>
                        <option value="ongoing">Ongoing</option>
                        <option value="completed">Completed</option>
                    </select>
                    <button className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl flex items-center gap-1">
                        <BiSolidFileExport />
                        Export
                    </button>
                    <Search
                        placeholder="Search Project"
                    />
                </div>

                <Table className="mt-5">
                    <TableHeader>
                        <TableRow>
                            <TableHead></TableHead>
                            <TableHead>Project & Client</TableHead>
                            <TableHead>Project Number</TableHead>
                            <TableHead>Project Manager</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Amount</TableHead>
                            <TableHead>Start Date</TableHead>
                            <TableHead>End Date</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allProjects?.length === 0 && !isLoading &&
                            <p className="mt-2">No {status} projects found</p>
                        }

                        {allProjects?.map((project, i) => (
                            <ProjectCard
                                key={project._id}
                                project={project}
                                index={i}
                            />
                        ))}
                    </TableBody>
                </Table>
            </div>

            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}

            {isCreateProjectModalOpen &&
                <CreateProjectModal
                    getAllProject={() => handleProjectChange(status)}
                    setState={setIsCreateProjectModalOpen}
                    project={""}
                />}
        </>
    )
}

export default AllProjects