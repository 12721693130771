import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BiSolidEdit, BiSolidTrashAlt } from "react-icons/bi";

import AddClientModal from "../add-clientModal/AddClientModal";

import classes from './Client.module.css';
import DeleteModal from "../deleteModal/DeleteModal";
import SuccessModal from "../successModal/SuccessModal";
import { axiosInstance } from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import { openCloseBody } from "../../store/slices/bodySlice";
import { useDispatch } from "react-redux";
import avatar from "../../assets/avatar.jpg"
import { TableCell, TableRow } from "../ui/table";

const Client = ({ client, getAllClient }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isSuccessModal, setIsSuccessModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const handleDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-client/${client._id}`);
            getAllClient();
            toast.success("Client deleted successfully");
            setIsDeleteModal(false);
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen || isDeleteModal) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isModalOpen, isDeleteModal])

    return (
        <>
            <TableRow>
                <TableCell>
                    <div onClick={() => navigate(`/clients/${client._id}`, { state: client })} className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden border-2'>
                        <img className={classes.img} src={client.image ? `${import.meta.env.VITE_IMAGE_URL}/${client.image}` : avatar} alt="client" />
                    </div>
                </TableCell>
                <TableCell onClick={() => navigate(`/clients/${client._id}`, { state: client })} className="cursor-pointer">{client.founderName}</TableCell>
                <TableCell>{client.companyName}</TableCell>
                <TableCell>
                    <div className="flex gap-4">
                        <BiSolidEdit onClick={handleModal} cursor={"pointer"} size={24} />
                        <BiSolidTrashAlt onClick={() => setIsDeleteModal(true)} cursor={"pointer"} size={22} color="red" />
                    </div>
                </TableCell>
            </TableRow>
            {/* <div className="flex items-center gap-5 w-full justify-around border-b p-[10px]">
                <div onClick={() => navigate(`/clients/${client._id}`, { state: client })} className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden border-2'>
                    <img className={classes.img} src={client.image ? `${import.meta.env.VITE_IMAGE_URL}/${client.image}` : avatar} alt="client" />
                </div>
                <p onClick={() => navigate(`/clients/${client._id}`, { state: client })} className="w-36 cursor-pointer">{client.founderName}</p>
                <p className="w-36">{client.companyName}</p>
                <span className={`${classes.button_group} w-24`}>
                    <button onClick={handleModal} className={classes.button}>
                        <BiSolidEdit cursor={"pointer"} size={20} color="green" />
                    </button>
                    <button onClick={() => setIsDeleteModal(true)} className={classes.button}>
                        <BiSolidTrashAlt cursor={"pointer"} size={20} color="red" />
                    </button>
                </span>
            </div> */}

            {
                isModalOpen &&
                <AddClientModal
                    client={client}
                    handleModal={handleModal}
                    isUpdate={true}
                    getAllClient={getAllClient}
                />
            }

            {isSuccessModal &&
                <SuccessModal
                    setState={setIsSuccessModal}
                    message={"Deleted successfully"}
                />
            }
            {isDeleteModal &&
                <DeleteModal
                    setState={setIsDeleteModal}
                    handleDelete={handleDelete}
                />
            }
        </>
    )
}

export default Client