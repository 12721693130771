import { useEffect, useState } from 'react';
import classes from './CreateModuleModal.module.css';
import { axiosInstance } from '../../utils/axiosInstance';

import { RxCross2 } from 'react-icons/rx';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { validate } from '../../utils/validate';
import usePostApiReq from '../../hooks/usePostApiReq';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useGetApiReq from '../../hooks/useGetApiReq';

const CreateModuleModal = ({ isUpdate, module = "", setState, getAllModule }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateRes, fetchData: fetchUpdateData, isLoading: isUpdateLoading } = usePatchApiReq();
    const { res: serviceRes, fetchData: fetchServiceData, isLoading: isServiceLoading } = useGetApiReq();

    const params = useParams();
    const [moduleInfo, setModuleInfo] = useState({
        projectId: params.id ? params?.id : params?.pId,
        title: module?.title || "",
        service: module?.service || "",
        startDate: module?.startDate && format(new Date(module.startDate), "yyyy-MM-dd") || format(new Date(), "yyyy-MM-dd"),
        deadline: module?.deadline && format(new Date(module.deadline), "yyyy-MM-dd") || "",
    });
    const [description, setDescription] = useState(module?.description || "")
    const [validateState, setValidateState] = useState([]);
    const [allServices, setAllServices] = useState([]);


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setModuleInfo({ ...moduleInfo, [name]: value });
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        const res = validate({
            "Dead Line": moduleInfo.deadline,
            "Description": description,
            "Title": moduleInfo.title,
            "Start Date": moduleInfo.startDate,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!moduleInfo.projectId ||
            !moduleInfo.title ||
            !description ||
            !moduleInfo.startDate ||
            !moduleInfo.deadline ||
            !moduleInfo.service
        ) {
            return;
        }
        if (isUpdate) {
            fetchUpdateData(`/admin/update-module/${module._id}`, { ...moduleInfo, description });
        }
        else {
            fetchData("/admin/create-module", { ...moduleInfo, description });
        }

    }

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            getAllModule()
            setState(false);
        }
    }, [updateRes])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllModule()
            setState(false);
        }
    }, [res])

    const getAllServices = async () => {
        fetchServiceData(`/admin/get-services/${params.id ? params?.id : params?.pId}`);
    }

    useEffect(() => {
        getAllServices();
    }, [])

    useEffect(() => {
        if (serviceRes?.status === 200 || serviceRes?.status === 201) {
            setAllServices(serviceRes?.data?.services);
        }
    }, [serviceRes])



    return (
        <div className={classes.wrapper}>
            <div className={classes.box}>
                <div className={classes.heading_container}>
                    <h3 className='text-2xl'>{isUpdate ? "Update" : "Create"} Module</h3>
                    <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                </div>
                <form onSubmit={handleOnSubmit}>
                    <div className={classes.input_container}>
                        <label htmlFor="title">Title</label>
                        <input onChange={handleOnChange} value={moduleInfo.title} className={classes.input} type="text" placeholder="Enter Title" name="title" id="title" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="description">Description</label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription} />
                        {/* <input onChange={handleOnChange} value={moduleInfo.description} className={classes.input} type="text" placeholder="Enter Description" name="description" id="description" /> */}
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="startDate">StartDate</label>
                        <input onChange={handleOnChange} value={moduleInfo.startDate} className={classes.input} type="date" placeholder="Enter StartDate" name="startDate" id="startDate" />
                    </div>
                    {/* <div className={classes.input_container}>
                        <label htmlFor="endDate">EndDate</label>
                        <input onChange={handleOnChange} value={moduleInfo.endDate} className={classes.input} type="date" placeholder="Enter endDate" name="endDate" id="endDate" />
                    </div> */}
                    <div className={classes.input_container}>
                        <label htmlFor="deadline">Deadline</label>
                        <input onChange={handleOnChange} value={moduleInfo.deadline} className={classes.input} type="date" placeholder="Enter deadline" name="deadline" id="deadline" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="service">Service</label>
                        <select onChange={handleOnChange} value={moduleInfo.service} className={classes.input} name="service" id="service">
                            <option value="">Select</option>
                            {allServices.map((service) => (
                                <option key={service._id} value={service._id}>{service.name}</option>
                            ))}

                        </select>
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>{isUpdate ? "Update" : "Create"}</button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default CreateModuleModal