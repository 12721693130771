import classes from './DocumentModal.module.css';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import parse from "html-react-parser";
import { FaXmark } from 'react-icons/fa6';

const DocumentModal = ({ isDocumentViewModalOpen, setIsDocumentViewModalOpen, doc }) => {
    console.log("doc", doc);

    return (
        <Dialog open={isDocumentViewModalOpen} onOpenChange={() => setIsDocumentViewModalOpen(!isDocumentViewModalOpen)}>
            <DialogContent className="sm:max-w-[925px]">
                {/* <DialogHeader>
                    <DialogTitle>Add Document</DialogTitle>
                </DialogHeader> */}
                <div>
                    {doc?.type === "add-document" && <div>
                        <div className='flex justify-between items-center mb-5'>
                            <h2 className="text-2xl font-semibold">{doc.name}</h2>
                            {/* <FaXmark onClick={() => setIsDocumentViewModalOpen(false)} className={classes.icon} /> */}
                        </div>
                        <p className='mb-2'><b>Type :</b> {doc?.type}</p>
                        <p className='list-disc'>{parse(doc.content)}</p>
                    </div>}
                    {doc?.type === "upload-document" && <div>
                        <FaXmark onClick={() => setIsDocumentViewModalOpen(false)} className='absolute right-4 top-4 cursor-pointer' />
                        <iframe className='w-full h-[78vh]' src={`${import.meta.env.VITE_PDF_URL}/${doc.filePath}`} frameborder="0"></iframe>
                    </div>}
                    {doc?.type === "add-video" && <div>
                        <FaXmark onClick={() => setIsDocumentViewModalOpen(false)} className='absolute right-4 top-4 cursor-pointer' />
                        <iframe className='w-full h-[78vh]' src={doc.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DocumentModal