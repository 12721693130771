import { configureStore } from '@reduxjs/toolkit'
import sidebarSliceReducer from './slices/sidebarSlice'
import userSliceReducer from './slices/userSlice'
import bodySliceReducer from './slices/bodySlice'
import loadingSliceReducer from './slices/loadingSlice'
import folderSliceReducer from './slices/folderSlice'

export const store = configureStore({
    reducer: {
        sidebarReducer: sidebarSliceReducer,
        userReducer: userSliceReducer,
        bodyReducer: bodySliceReducer,
        loadingReducer: loadingSliceReducer,
        folderReducer: folderSliceReducer,
    },
})
