import React, { useState } from 'react'
import DocumentModal from '../documentModal/DocumentModal'

const Doc = ({ doc }) => {
    const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);

    return (
        <>
            <div onClick={() => setIsDocumentViewModalOpen(true)} className='text-center cursor-pointer flex flex-col gap-1 justify-end items-center'>
                <span className='text-8xl'>📄</span>
                {doc?.name}
            </div>
            {isDocumentViewModalOpen &&
                <DocumentModal
                    setIsDocumentViewModalOpen={setIsDocumentViewModalOpen}
                    isDocumentViewModalOpen={isDocumentViewModalOpen}
                    doc={doc}
                />
            }
        </>
    )
}

export default Doc