import { useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectCard from '../../components/projectCard/ProjectCard';
import { FaArrowLeft } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import LimitComp from '../../components/limit-comp/LimitComp';
import Loader from '@/components/loader/Loader';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

const ClientProjects = () => {
    const [allProjects, setAllProjects] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(5);

    const params = useParams();
    const navigate = useNavigate();

    const getAllProjects = async () => {
        try {
            setAllProjects([]);
            setIsLoading(true);
            const { data } = await axiosInstance.get(`/admin/client-project/${params?.clientId}?page=${page}&limit=${limit}`);
            console.log("projects", data);
            setAllProjects(data.data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getAllProjects();
    }, [page, limit])

    return (
        <div>
            <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
            <div className="flex gap-2 justify-between py-2">
                <h3 className="text-3xl font-bold">Client Projects</h3>
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />
            </div>
            <Table className="mt-3">
                <TableHeader>
                    <TableRow>
                        <TableHead></TableHead>
                        <TableHead>Project & Client</TableHead>
                        <TableHead>Project Number</TableHead>
                        <TableHead>Project Manager</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Amount</TableHead>
                        <TableHead>Start Date</TableHead>
                        <TableHead>End Date</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allProjects?.map((project) => (
                        <ProjectCard
                            key={project._id}
                            project={project}
                        />
                    ))}
                </TableBody>
            </Table>
            {allProjects.length === 0 && isLoading &&
                <Loader />
            }
            {allProjects.length === 0 && !isLoading &&
                <p className="mt-2">No projects found</p>
            }

            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}
        </div >
    )
}

export default ClientProjects