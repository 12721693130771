export const validate = (obj) => {
    const validatedObj = {}

    for (const key in obj) {
        if (obj[key] === "") {
            validatedObj[key] = { status: false, message: `*${key.toUpperCase()} is required.` };
        }
        else {
            validatedObj[key] = { status: true, message: "" };
        }
    }
    return validatedObj;
}