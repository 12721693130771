import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "../ui/button"
import { Label } from "../ui/label"
import { Input } from "../ui/input"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import usePostApiReq from "@/hooks/usePostApiReq";
import { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import { validate } from "@/utils/validate";

const ReplyModal = ({ isReplyModalOpen, setIsReplyModalOpen, to }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const [emailInfo, setEmailInfo] = useState({
        body: "",
        recipientMail: to || "",
        subject: "",
    })
    const [validateState, setValidateState] = useState([]);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setEmailInfo({ ...emailInfo, [name]: value });
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const res = validate({
            "Recipient Mail": emailInfo.recipientMail,
            "Email Body": emailInfo.body,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!emailInfo.recipientMail || !emailInfo.subject || !emailInfo.body) {
            return;
        }

        fetchData("/user/reply-mail", emailInfo);
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("reply email res", res);
        }
    }, [res])

    return (
        <Dialog open={isReplyModalOpen} onOpenChange={() => setIsReplyModalOpen(!isReplyModalOpen)}>
            <DialogContent className="sm:max-w-[1000px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle className="text-left">Reply Email</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="grid grid-cols-[10%_85%] items-center gap-4">
                        <Label>To</Label>
                        <Input onChange={handleOnChange} value={emailInfo.recipientMail} name="recipientMail" id="recipientMail" />
                    </div>
                    <div className="grid grid-cols-[10%_85%] h-96 items-start gap-4">
                        <Label>Content</Label>
                        <ReactQuill onChange={(value) => setEmailInfo({ ...emailInfo, body: value })} value={emailInfo.body} className="h-80" theme="snow" />
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Loader size={25} /> : "Reply"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default ReplyModal