import { useEffect, useState } from 'react';
import classes from './MarkAttendanceModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';

import { useParams } from 'react-router-dom';
import { validate } from '../../utils/validate';
import usePostApiReq from '../../hooks/usePostApiReq';
import useGetApiReq from '../../hooks/useGetApiReq';

const MarkAttendanceModal = ({ setState }) => {
    const { res, fetchData } = usePostApiReq();
    const { res: empRes, fetchData: fetchEmpData, isLoading: isEmpLoading } = useGetApiReq();

    const [validateState, setValidateState] = useState([]);
    const [markAttendanceInfo, setMarkAttendanceInfo] = useState({
        empId: "",
        date: ""
    })
    const [allEmployees, setAllEmployees] = useState([]);

    const getAllEmployee = async () => {
            fetchEmpData("/admin/get-employee");
    }
    
    useEffect(() => {
        getAllEmployee();
    }, [])
    
    useEffect(() => {
        if (empRes?.status === 200 || empRes?.status === 201) {
            let arr = empRes?.data.data;
            setAllEmployees(arr);
        }
    }, [empRes])


    const params = useParams();

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setMarkAttendanceInfo({ ...markAttendanceInfo, [name]: value });
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "Username": markAttendanceInfo.empId,
            "Date": markAttendanceInfo.date
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }
        if (!markAttendanceInfo.empId || !markAttendanceInfo.date) {
            return;
        }

        fetchData(`/admin/mark-absent-emp`, { ...markAttendanceInfo });

    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setState(false);
        }
    }, [res])


    return (
        <div className={classes.add_document_wrapper}>
            <div className={classes.add_document_box}>
                <div className={classes.heading_container}>
                    <h3>Mark Absent</h3>
                    <div className={classes.d_flex}>
                        <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                    </div>
                </div>
                <form onSubmit={handleOnSubmit} className={classes.form}>
                    <div className={classes.container}>
                        <label htmlFor="empId">Employee</label>
                        {/* <input onChange={handleOnChange} value={markAttendanceInfo.username} type="text" name="username" id="username" /> */}
                        <select onChange={handleOnChange} value={markAttendanceInfo.empId} name="empId" id="empId">
                            <option value="">Select</option>
                            {allEmployees?.map((employee)=>(
                                <option key={employee._id} value={employee._id}>{employee?.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="date">Date</label>
                        <input onChange={handleOnChange} value={markAttendanceInfo.date} type="date" name="date" id="date" />
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>Mark</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MarkAttendanceModal