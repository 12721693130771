import classes from './ProjectView.module.css';

import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import Story from '../../components/story/Story';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaCirclePlus, FaEye } from 'react-icons/fa6';
import Module from '../../components/module/Module';
import { format } from 'date-fns';
import AddTicketModal from '../../components/add-ticketModal/AddTicketModal';
import { FaArrowLeft } from "react-icons/fa";
import loader from '../../assets/rolling.gif';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';
import DocumentsModal from '../../components/documentsModal/DocumentsModal';
import { IoDocumentTextOutline } from 'react-icons/io5';
import ReactPaginate from 'react-paginate';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import TimlineView from '../../components/timelineview/TimlineView';
import LimitComp from '../../components/limit-comp/LimitComp';
import { readCookie } from '../../utils/readCookie';
import Loader from '@/components/loader/Loader';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Ticket from '@/components/ticket/Ticket';
import useGetApiReq from '@/hooks/useGetApiReq';
import avatar from "../../assets/employee.png"
import ProjectDocumentsModal from '@/components/documentsModal/ProjectDocumentsModal';

const EmployeeProjectView = () => {
    const { res, fetchData, isLoading: isUpdateLoading } = usePatchApiReq();
    const { res: managerRes, fetchData: fetchManagerData, isLoading: isManagerLoading } = useGetApiReq();

    const [allStories, setAllStories] = useState([]);
    const [allModule, setAllModule] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isStoryLoading, setIsStoryLoading] = useState(true);
    const [isTicketLoading, setIsTicketLoading] = useState(true);

    const [inProgressStories, setInProgressStories] = useState([]);
    const [needsReviewStories, setNeedsReviewStories] = useState([]);
    const [completedStories, setCompletedStories] = useState([]);
    const [upcomingStories, setUpcomingStories] = useState([]);

    const [allTickets, setAllTickets] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [allResources, setAllResources] = useState([]);
    const [isAddTicketModalOpen, setIsAddTicketModalOpen] = useState(false);
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const navigate = useNavigate();
    const params = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const cookies = readCookie("userInfo");
    const id = cookies?.userId;

    useEffect(() => {
        const handleModalOpen = () => {
            if (isAddTicketModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isAddTicketModalOpen])

    const getAllStories = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-emp-stories/${id}?pId=${params.pId}`);
            console.log("all stories emp side", data);
            setAllStories(data.data);
            let myEvents = data.data.map((item) => ({ title: item.title, start: item.startDate, end: item.endDate, resourceId: item.moduleId, status: item.status, storyId: item._id, story: item }));
            setAllEvents(myEvents);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsStoryLoading(false);
        }
    }

    const getAllModule = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/projects-modules/${params.pId}`);
            let myResources = data.data.map((item) => ({ title: item.title, id: item._id, status: item.status }))
            console.log("Resources", myResources);
            setAllResources(myResources);
            const filtered = data.data.filter((item) => item.status === "in-progress")
            setAllModule(filtered);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getAllStories();
        getAllModule();
    }, [])

    useEffect(() => {
        const inProgress = allStories.filter((story) => story.status === "in-progress");
        const needsReview = allStories.filter((story) => story.status === "needs-review");
        const completed = allStories.filter((story) => story.status === "completed");
        const upcoming = allStories.filter((story) => story.status === "upcoming");
        setInProgressStories(inProgress);
        setNeedsReviewStories(needsReview);
        setCompletedStories(completed);
        setUpcomingStories(upcoming);
    }, [allStories])


    const handleOnDragEnd = async (result) => {
        const { source, destination } = result;

        if (!destination) return
        if (source.droppableId === destination.droppableId && source.index === destination.index) return
        let inProgress = inProgressStories;
        let needsReview = needsReviewStories;
        let completed = completedStories;
        let upcoming = upcomingStories;
        let filtered;
        let status;

        if (source.droppableId === "in-progress") {
            filtered = inProgress[source.index];
            inProgress.splice(source.index, 1);
        }
        else if (source.droppableId === "needs-review") {
            filtered = needsReview[source.index];
            needsReview.splice(source.index, 1);
        }
        else if (source.droppableId === "completed") {
            filtered = completed[source.index];
            completed.splice(source.index, 1);
        }
        else {
            filtered = upcoming[source.index];
            upcoming.splice(source.index, 1);
        }

        if (destination.droppableId === "in-progress") {
            inProgress.splice(destination.index, 0, filtered);
            status = "in-progress";
        }
        else if (destination.droppableId === "needs-review") {
            needsReview.splice(destination.index, 0, filtered);
            status = "needs-review";
        }
        else if (destination.droppableId === "completed") {
            completed.splice(destination.index, 0, filtered);
            status = "completed";
        }
        else {
            upcoming.splice(destination.index, 0, filtered);
            status = "upcoming";
        }

        setInProgressStories(inProgress);
        setNeedsReviewStories(needsReview);
        setCompletedStories(completed);
        setUpcomingStories(upcoming);

        fetchData(`/admin/update-emp-stories/${filtered._id}`, { status });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllModule();
            getAllStories();
        }
    }, [res])


    const getAllTickets = async () => {
        try {
            setAllTickets([]);
            setIsTicketLoading(true);
            const { data } = await axiosInstance.get(`/admin/get-project-tickets/${params?.pId}?page=${page}&limit=${limit}`);
            console.log("emp", data);
            setAllTickets(data?.data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message);
        }
        finally {
            setIsTicketLoading(false);
        }
    }

    useEffect(() => {
        getAllTickets();
    }, [page, limit])


    const getProjectManagerStatus = async () => {
        fetchManagerData(`/admin/get-project-access-admin/${params?.pId}`);
    }

    useEffect(() => {
        getProjectManagerStatus();
    }, [])



    return (
        <>
            <section>
                <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
                <div className='flex justify-between items-center'>
                    <h1 className='text-3xl font-bold py-2'>{state?.projectId?.projectName}</h1>
                    <button onClick={() => setIsDocumentModalOpen(true)} className='flex gap-1 items-center border-2 py-1 px-2 border-[#1A56DB] text-[#1A56DB] rounded-md'>
                        <IoDocumentTextOutline cursor={"pointer"} size={23} />
                        <span className='text-sm'>Documents</span>
                    </button>
                </div>

                {managerRes?.data?.data &&
                    <div className='ml-auto pr-20'>
                        <h2 className='text-2xl text-[#374151] font-bold'>Project Manager Info</h2>
                        <div className="flex gap-8 w-full font-bold mt-2">
                            <div className="w-14"></div>
                            <div className="w-36 whitespace-nowrap">Project Manager Name</div>
                        </div>
                        <div className="flex items-center gap-8 w-full border-b pb-2 pt-0">
                            <div className='w-14 h-14 rounded-full p-[2px] overflow-hidden border-2'>
                                <img className="w-full h-full object-cover" src={managerRes?.data?.data?.employeeImage ? `${import.meta.env.VITE_IMAGE_URL}/${managerRes?.data?.data?.employeeImage}` : avatar} alt="manager" />
                            </div>
                            <p className="w-36">{managerRes?.data?.data?.employeeName}</p>
                        </div>
                    </div>
                }
                <div className={`flex justify-between items-center mt-4`}>
                    <h1 className='text-2xl font-bold py-2'>In-Progress Modules</h1>
                    <button onClick={() => navigate(`/employee-projects/${params.pId}/allModules`)} className='border-2 border-[#1A56DB] text-[#1A56DB] px-4 py-1 rounded'>View All</button>
                </div>

                <div
                    className="flex flex-wrap items-center gap-8 mt-4 mb-5"
                >
                    {allModule.map((module, i) => (
                        <Module
                            key={i}
                            index={i}
                            module={module}
                            getAllModule={getAllModule}
                            employee={true}
                            getStories={() => { }}
                        />
                    ))}

                    {allModule.length === 0 && isLoading &&
                        <Loader />
                    }

                    {allModule.length === 0 && !isLoading &&
                        <p>No module found</p>
                    }
                </div>

                <h1 className='text-2xl font-bold py-2 mt-4'>Assigned Stories</h1>
                {allStories.length === 0 && isStoryLoading &&
                    <Loader />
                }

                {allStories.length === 0 && !isStoryLoading &&
                    <p>No stories found</p>
                }

                {allStories.length > 0 && <DragDropContext onDragEnd={handleOnDragEnd}>
                    <div className={classes.container}>
                        <div>
                            <h3 className={classes.h3}>Upcoming</h3>
                            <Droppable droppableId='upcoming'>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.task_card_container}
                                    >
                                        {upcomingStories.map((story, i) => (
                                            <Story
                                                key={i}
                                                index={i}
                                                story={story}
                                                getAllStories={getAllStories}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div>
                            <h3 className={classes.h3}>In Progress</h3>
                            <Droppable droppableId='in-progress'>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.task_card_container}
                                    >
                                        {inProgressStories.map((story, i) => (
                                            <Story
                                                key={i}
                                                index={i}
                                                story={story}
                                                getAllStories={getAllStories}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div>
                            <h3 className={classes.h3}>Needs Review</h3>
                            <Droppable droppableId='needs-review'>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.task_card_container}
                                    >
                                        {needsReviewStories.map((story, i) => (
                                            <Story
                                                key={i}
                                                index={i}
                                                story={story}
                                                getAllStories={getAllStories}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div>
                            <h3 className={classes.h3}>Completed</h3>
                            <Droppable droppableId='completed'>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.task_card_container}
                                    >
                                        {completedStories.map((story, i) => (
                                            <Story
                                                key={i}
                                                index={i}
                                                story={story}
                                                getAllStories={getAllStories}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </DragDropContext>}

                <div className='mt-5'>
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl font-bold py-2">Project Tickets</h2>
                        <div className="flex gap-2">
                            <LimitComp
                                setLimit={setLimit}
                                limit={limit}
                            />

                            <button onClick={() => setIsAddTicketModalOpen(true)} className="px-4 py-1 border-2 border-gray-400 text-black rounded-md flex items-center gap-3">
                                <FaCirclePlus size={18} />
                                Add Ticket
                            </button>
                        </div>
                    </div>
                    <Table className="mt-1">
                        <TableHeader>
                            <TableRow>
                                <TableHead>Ticket Id</TableHead>
                                <TableHead>Subject</TableHead>
                                <TableHead>Assigned</TableHead>
                                <TableHead>Created Date</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {allTickets?.map((ticket) => (
                                <Ticket
                                    key={ticket?._id}
                                    ticket={ticket}
                                    getAllTickets={getAllTickets}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    {allTickets?.length === 0 && !isTicketLoading &&
                        <p>No tickets found</p>
                    }

                    {allTickets.length === 0 && isTicketLoading &&
                        <Loader />
                    }
                    {pageCount > 0 &&
                        <div className="mt-5">
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                pageCount={pageCount}
                                onPageChange={(e) => setPage(e.selected + 1)}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>}
                </div>
                <TimlineView
                    allEvents={allEvents}
                    allResources={allResources}
                />
            </section>
            {isAddTicketModalOpen &&
                <AddTicketModal
                    setState={setIsAddTicketModalOpen}
                    getAllTickets={getAllTickets}
                    projectId={params.pId}
                />
            }

            {isDocumentModalOpen &&
                <ProjectDocumentsModal
                    setIsDocumentsModalOpen={setIsDocumentModalOpen}
                    isDocumentsModalOpen={isDocumentModalOpen}
                />
            }
        </>
    )
}

export default EmployeeProjectView