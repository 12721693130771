import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error to an error reporting service
        console.error('Error caught by Error Boundary:', error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // Display a fallback UI when an error occurs
            return (
                <div className='flex justify-center items-center h-screen'>
                    <h1 className='text-xl font-bold'>Something went wrong.</h1>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
