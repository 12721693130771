
import { useState, useEffect } from 'react';
import loader from '../../assets/rolling.gif';
import { axiosInstance } from '../../utils/axiosInstance';
import ReactPaginate from 'react-paginate';
import EmpProjectCard from '../../components/emp-projectCard/EmpProjectCard';
import LimitComp from '../../components/limit-comp/LimitComp';
import { readCookie } from '../../utils/readCookie';
import Loader from '@/components/loader/Loader';

import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

const EmployeeProjects = () => {
    const [allProjects, setAllProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const cookies = readCookie("userInfo");
    const userId = cookies?.userId;


    const getAllProjects = async () => {
        setIsLoading(true);
        try {
            setAllProjects([]);
            const { data } = await axiosInstance.get(`/admin/emp-projects/${userId}?page=${page}&limit=${limit}`);
            setIsLoading(false)
            console.log("projects", data);
            setAllProjects(data.data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        getAllProjects();
    }, [page, limit])


    return (
        <div>
            <div className="flex justify-between gap-2 py-2">
                <h2 className="text-3xl font-bold">Projects</h2>
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />
            </div>
            <Table className="mt-5">
                <TableHeader>
                    <TableRow>
                        <TableHead></TableHead>
                        <TableHead>Project Name</TableHead>
                        <TableHead>Start Date</TableHead>
                        <TableHead>Status</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allProjects?.map((project) => (
                        <EmpProjectCard
                            key={project?.projectId?._id}
                            project={project}
                        />
                    ))}
                </TableBody>
            </Table>
            {allProjects.length === 0 && isLoading &&
                <Loader />
            }

            {allProjects.length === 0 && !isLoading &&
                <p>No projects found</p>
            }
            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}
        </div>
    )
}

export default EmployeeProjects