import classes from './SuccessModal.module.css'

const SuccessModal = ({ message, setState }) => {
    return (
        <div className={classes.modal_wrapper}>
            <div className={classes.modal}>
                <p>{message}</p>
                <div className={classes.button_wrapper}>
                    <button onClick={() => setState(false)} className={classes.button}>ok</button>
                </div>
            </div>
        </div>
    )
}

export default SuccessModal