import { format } from 'date-fns';
import classes from './StoryInfoModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import parse from "html-react-parser";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

const StoryInfoModal = ({ setState, story }) => {
    console.log("story", story);
    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.box}>
                    <div className={classes.heading_container}>
                        <h2 className='text-3xl font-semibold'>Story Info </h2>
                        <div className={classes.button_icon_container}>
                            <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />

                        </div>
                    </div>
                    <div className='flex gap-2 items-center text-lg font-semibold'>
                        <h4>Name:</h4>
                        <p className='text-gray-400'>{story?.title}</p>
                    </div>
                    <Table className="mt-3">
                        <TableHeader>
                            <TableRow>
                                <TableHead>Start Date</TableHead>
                                {story.endDate && <TableHead>End Date</TableHead>}
                                <TableHead>Deadline</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Description</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell>{story.startDate && format(new Date(story.startDate), "dd-MM-yyyy")}</TableCell>
                                {story.endDate && <TableCell>{story.endDate && format(new Date(story.endDate), "dd-MM-yyyy")}</TableCell>}
                                <TableCell>{story.deadline && format(new Date(story.deadline), "dd-MM-yyyy")}</TableCell>
                                <TableCell>
                                    <span className={`${story.status === "in-progress" ? "bg-[#FFD700] text-white" : story.status === "needs-review" ? "bg-red-500 text-white" : story.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-2 py-[3px] rounded-full`}>{story.status}</span>
                                </TableCell>
                                <TableCell className="w-[170px]">{story?.description && parse(story.description)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <h2 className='text-xl font-bold mt-4'>Logs</h2>
                    {story?.logs?.length === 0 &&
                        <p>No logs found</p>
                    }

                    {story?.logs?.length > 0 &&
                        <>
                            <Table className="mt-3">
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Username</TableHead>
                                        <TableHead>Date</TableHead>
                                        <TableHead>Time</TableHead>
                                        <TableHead>From</TableHead>
                                        <TableHead>To</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {story?.logs?.map((storyLog, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{storyLog?.userId?.name}</TableCell>
                                            <TableCell>{storyLog?.date && format(new Date(storyLog?.date), "dd-MM-yyyy")}</TableCell>
                                            <TableCell>{storyLog?.date && format(new Date(storyLog?.date), "hh:mm a")}</TableCell>
                                            <TableCell><span className={`${storyLog?.from === "in-progress" ? "bg-[#FFD700] text-white" : storyLog?.from === "needs-review" ? "bg-red-500 text-white" : storyLog?.from === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-2 py-[3px] rounded-full`} >{storyLog?.from}</span></TableCell>
                                            <TableCell><span className={`${storyLog?.to === "in-progress" ? "bg-[#FFD700] text-white" : storyLog?.to === "needs-review" ? "bg-red-500 text-white" : storyLog?.to === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-2 py-[3px] rounded-full`} >{storyLog?.to}</span></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </>}
                </div>
            </div>
        </>
    )
}

export default StoryInfoModal