import { useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { FaCirclePlus } from 'react-icons/fa6';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import LimitComp from '../../components/limit-comp/LimitComp';
import Loader from '@/components/loader/Loader';
import AddTicketModal from '@/components/add-ticketModal/AddTicketModal';
import Search from '@/components/search/Search';
import Ticket from '@/components/ticket/Ticket';

const ProjectTickets = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [allTickets, setAllTickets] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(2);
    const [isAddTicketModalOpen, setIsAddTicketModalOpen] = useState(false);

    const getAllTickets = async () => {
        try {
            setAllTickets([]);
            setIsLoading(true);
            const { data } = await axiosInstance.get(`/admin/get-project-tickets/${params?.id?params?.id:params?.pId}?page=${page}&limit=${limit}`);
            console.log("admin", data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
            setAllTickets(data.data);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAllTickets();
    }, [page, limit])


    return (
        <div className='mb-5'>
            <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
            <h2 className="text-3xl text-[#374151] font-bold">Project Tickets</h2>

            <div className="flex items-center gap-2 mt-8">
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />
                <button onClick={() => setIsAddTicketModalOpen(true)} className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl flex items-center gap-3">
                    <FaCirclePlus size={18} />
                    Add Ticket
                </button>
                <Search
                    placeholder="Search Ticket"
                />
            </div>

            <Table className="mt-1">
                <TableHeader>
                    <TableRow>
                        <TableHead>Ticket Id</TableHead>
                        <TableHead>Subject</TableHead>
                        <TableHead>Assigned</TableHead>
                        <TableHead>Created Date</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allTickets?.map((ticket) => (
                        <Ticket
                            key={ticket?._id}
                            ticket={ticket}
                            getAllTickets={getAllTickets}
                        />
                    ))}
                </TableBody>
            </Table>
            {allTickets?.length === 0 && !isLoading &&
                <p>No tickets found</p>
            }

            {allTickets.length === 0 && isLoading &&
                <Loader />
            }

            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            }

            {isAddTicketModalOpen &&
                <AddTicketModal
                    setState={setIsAddTicketModalOpen}
                    getAllTickets={getAllTickets}
                    projectId={params?.id?params?.id:params?.pId}
                />
            }
        </div>
    )
}

export default ProjectTickets