import useGetApiReq from '@/hooks/useGetApiReq';
// import { saveDoc } from '@/utils/download';
import React, { useEffect, useState } from 'react'
import SubSubFolder from '../subSubfolder/SubSubFolder';
import { FaFolder } from 'react-icons/fa6';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddSubFolderModal from '../add-subFolderModal/AddSubFolderModal';
import AddDocumentModal from '../add-documentModal/AddDocumentModal';
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "@/components/ui/context-menu"
import { readCookie } from '@/utils/readCookie';

const SubFolder = ({ subfolder, setFolderIdAndType, folderIdAndType, isDownload }) => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: subFolderRes, fetchData: fetchSubFolderData, isLoading: isSubFolderLoading } = useGetApiReq();

    const navigate = useNavigate();
    const params = useParams();
    const { pathname } = useLocation();

    const [subFolderDocs, setSubFolderDocs] = useState([]);
    const [isSubFolderOpen, setIsSubFolderOpen] = useState(false);
    const [subFolderId, setSubFolderId] = useState("");
    const [allSubSubFolders, setAllSubSubFolders] = useState([1, 2]);
    const [isAddSubFolderModalOpen, setIsAddSubFolderModalOpen] = useState(false);
    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);

    const handleSubFolderOpen = (type, id, name) => {
        setFolderIdAndType({ folderType: type, fId: id, folderName: name });
        setIsSubFolderOpen(!isSubFolderOpen);
        setSubFolderId(id);
    }

    const { res: docRes, fetchData: fetchDocData, isLoading: isDocLoading } = useGetApiReq();

    const getDoc = (doc) => {
        if (isDownload) {
            fetchDocData(`/admin/get-doc?url=${doc}`);
        }
    }

    useEffect(() => {
        if (docRes?.status === 200 || docRes?.status === 201) {
            console.log("Document", docRes);
            // saveDoc(docRes?.data?.preSignedUrl);
        }
    }, [docRes])

    const getSubFolders = () => {
        // fetchSubFolderData(`/admin/get-subfolders-and-docs/${folderIdAndType.fId}?flag=sub-folder`);
    }

    useEffect(() => {
        if (folderIdAndType.fId === subfolder?._id) {
            getSubFolders();
        }
    }, [folderIdAndType])


    useEffect(() => {
        if (subFolderRes?.status === 200 || subFolderRes?.status === 201) {
            console.log("sub 'sub' FolderRes", subFolderRes);
            setAllSubSubFolders(subFolderRes?.data?.subfolders);
            setSubFolderDocs(subFolderRes?.data?.updateDocs);
        }
    }, [subFolderRes])

    const { res: managerRes, fetchData: fetchManagerData, isLoading: isManagerLoading } = useGetApiReq();
    const userInfo = readCookie("userInfo")

    const getProjectManagerStatus = async () => {
        fetchManagerData(`/admin/get-project-access-admin/${params?.pId}`);
    }

    useEffect(() => {
        if (params?.pId) {
            getProjectManagerStatus()
        }
    }, [])

    const navigateUrl = `/${pathname.includes("/documents") ? "documents" : userInfo.userType === "employee" ? `employee-projects/${params?.pId}` : `allProjects/${params?.id}`}/folder/subfolder`;

    return (
        <div>
            <div className="cursor-pointer flex justify-center font-semibold">
                {(managerRes?.data?.data?.employeeId === userInfo?.userId || userInfo.userType === "admin") ?

                    <ContextMenu>
                        <ContextMenuTrigger>
                        <div className='flex flex-col gap-1 justify-center text-center'
                            onDoubleClick={() => navigate(navigateUrl, { state: { folderId: subfolder?._id } })}
                        >
                            <FaFolder className='text-yellow-400 text-8xl' />
                            {subfolder?.name}
                        </div>
                        </ContextMenuTrigger>
                        <ContextMenuContent>
                            <ContextMenuItem className="cursor-pointer" onClick={() => setIsAddSubFolderModalOpen(true)}>Add Folder</ContextMenuItem>
                            <ContextMenuItem className="cursor-pointer" onClick={() => setIsAddDocumentModalOpen(true)}>Add Document</ContextMenuItem>
                        </ContextMenuContent>
                    </ContextMenu>
                    :
                    <div className='flex flex-col gap-1 justify-center text-center'
                            onDoubleClick={() => navigate(navigateUrl, { state: { folderId: subfolder?._id } })}
                        >
                            <FaFolder className='text-yellow-400 text-8xl' />
                            {subfolder?.name}
                        </div>
                }
            </div>

            {isAddSubFolderModalOpen &&
                <AddSubFolderModal
                    isAddSubFolderModalOpen={isAddSubFolderModalOpen}
                    setIsAddSubFolderModalOpen={setIsAddSubFolderModalOpen}
                    getSubFolders={getSubFolders}
                    id={subfolder._id}
                />
            }

            {isAddDocumentModalOpen &&
                <AddDocumentModal
                    setState={setIsAddDocumentModalOpen}
                    isAddDocumentModalOpen={isAddDocumentModalOpen}
                    subFolderId={subfolder._id}
                    projectId={params?.id || params?.pId}
                    getAllDocuments={() => { }}
                />
            }
        </div>
    )
}

export default SubFolder