import toast from 'react-hot-toast';
import { axiosInstance } from '../../utils/axiosInstance';
import classes from './MemberCard.module.css';
import avatar from "../../assets/employee.png"

const MemberCard = ({ member,getAllMembers }) => {

    const handleDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-assigned-emp/${member._id}`);
            toast.success("Removed employee from project");
            getAllMembers();
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <div className={classes.member_card}>
            <div className={classes.member_card_left}>
                <img className={classes.img} src={member?.data[0]?.image ? `${import.meta.env.VITE_IMAGE_URL}/${member?.data[0]?.image}` : avatar} alt="" />
                <div className={classes.info}>
                    <h4>{member?.data[0]?.name}</h4>
                    <p>{member?.data[0]?.position}</p>
                </div>
            </div>
            <div className={classes.member_card_right}>
                <button onClick={handleDelete} className={classes.button}>Remove</button>
            </div>
        </div>
    )
}

export default MemberCard