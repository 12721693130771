import { lazy, useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/axiosInstance';

import classes from './Employees.module.css';
import loader from '../../assets/rolling.gif';

import { FaCirclePlus } from 'react-icons/fa6';

import { useNavigate } from 'react-router-dom';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';

import Employee from '../../components/employee/Employee';
import AddEmployeeModal from '../../components/add-employeeModal/AddEmployeeModal';
import ReactPaginate from 'react-paginate';
import LimitComp from '../../components/limit-comp/LimitComp';
import { BiSolidFileExport } from 'react-icons/bi';
import Search from '@/components/search/Search';

import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Loader from '@/components/loader/Loader';

const Employees = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allEmployees, setAllEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("admin");
    const [limit, setLimit] = useState(5);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isModalOpen])


    const handleChange = async (value) => {
        setStatus(value);
        if (value === "emp") {
            try {
                setAllEmployees([]);
                setIsLoading(true);
                const { data } = await axiosInstance.get(`/admin/get-employee?page=${page}&limit=${limit}`);
                console.log("emp", data);
                setAllEmployees(data.data);
                const count = Math.ceil(data?.totalDocs / limit);
                setPageCount(count);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        }
        else {
            try {
                setAllEmployees([]);
                setIsLoading(true);
                const { data } = await axiosInstance.get(`/admin/get-admins?page=${page}&limit=${limit}`);
                console.log("admins", data);
                setAllEmployees(data.data);
                const count = Math.ceil(data?.totalDocs / limit);
                setPageCount(count);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        }
        // fetchData(`/admin/get-project-by-status?page=${page}`, { status: value });
    }

    useEffect(() => {
        handleChange(status)
    }, [page, limit])

    return (
        <section>
            <div className="flex justify-between items-center px-2">
                <h1 className="text-3xl text-[#374151] font-bold py-2">Employees</h1>
            </div>
            <div className="flex items-center gap-2 mt-8">
                <select className="px-4 py-2 border-2  rounded-tl-xl rounded-br-xl" value={status} onChange={(e) => handleChange(e.target.value)} name="" id="">
                    <option value="emp">Employee</option>
                    <option value="admin">Admin</option>
                </select>
                <button onClick={handleModal} className="px-4 py-2 border-2 flex items-center gap-1 rounded-tl-xl rounded-br-xl">
                    <FaCirclePlus size={18} />
                    Add Employee
                </button>
                <select className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl" name="" id="">
                    <option value="">Filter</option>
                    <option value="upcoming">Upcoming</option>
                    <option value="ongoing">Ongoing</option>
                    <option value="completed">Completed</option>
                </select>
                <button className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl flex items-center gap-1">
                    <BiSolidFileExport />
                    Export
                </button>
                <Search
                    placeholder="Search Employee"
                />
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />
            </div>

            {/* <div className="flex gap-2 mt-3">
                <button onClick={() => handleChange("emp")} className={`px-4 py-1 rounded ${status === "emp" ? "bg-[#3498DB] text-white" : "border-2"}`}>Employee</button>
                <button onClick={() => handleChange("admin")} className={`px-4 py-1 rounded ${status === "admin" ? "bg-[#FFD700] text-black" : "border-2"}`}>Admin</button>
            </div> */}

            <Table className="mt-5">
                <TableHeader>
                    <TableRow>
                        <TableHead></TableHead>
                        <TableHead>Employee Name</TableHead>
                        <TableHead>Position</TableHead>
                        <TableHead>Role</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allEmployees?.map((employee, i) => (
                        <Employee
                            index={i}
                            key={employee._id}
                            employee={employee}
                            getAllEmployee={() => handleChange(status)}
                        />
                    ))}
                </TableBody>
            </Table>
            {allEmployees.length === 0 && !isLoading &&
                <p>No employee found</p>
            }

            {allEmployees.length === 0 && isLoading &&
                <Loader />
            }


            {
                isModalOpen &&
                <AddEmployeeModal
                    handleModal={handleModal}
                    isUpdate={false}
                    getAllEmployee={() => handleChange(status)}
                />
            }
            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}
        </section>
    )
}

export default Employees