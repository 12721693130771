import classes from './ProjectView.module.css';
import { FaCirclePlus, FaEye } from 'react-icons/fa6';
import { IoDocumentTextOutline } from "react-icons/io5";

import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { MdDelete } from 'react-icons/md';
import { FaArrowLeft, FaCalendarAlt, FaEdit } from 'react-icons/fa';

import Module from '../../components/module/Module';
import Story from '../../components/story/Story';
import EmployeeListModal from '../../components/employeeListModal/EmployeeListModal';
import MemberCard from '../../components/memberCard/MemberCard';
import DocumentsModal from '../../components/documentsModal/DocumentsModal';
import AddTicketModal from '../../components/add-ticketModal/AddTicketModal';
import DeleteModal from '../../components/deleteModal/DeleteModal';
import ExpenseInfoModal from '../../components/expense-infoModal/ExpenseInfoModal';
import AddExpenseModal from '../../components/add-expenseModal/AddExpenseModal';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
);

import loader from '../../assets/rolling.gif';
import CreateProjectModal from '../../components/create-project-modal/CreateProjectModal';
import { useDispatch } from 'react-redux';
import { openCloseBody } from '../../store/slices/bodySlice';
import CreateModuleModal from '../../components/create-module-modal/CreateModuleModal';
import avatar from "../../assets/avatar.jpg"
import usePostApiReq from '../../hooks/usePostApiReq';
import TimlineView from '../../components/timelineview/TimlineView';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Progress } from "@/components/ui/progress"

import Loader from '@/components/loader/Loader';
import empImg from '../../assets/emp-img.png';
import { IoMdChatbubbles } from 'react-icons/io';
import { BiMessageAltDetail } from 'react-icons/bi';
import Ticket from '@/components/ticket/Ticket';
import SelectProjectManagerModal from '@/components/select-projectManagerModal/SelectProjectManagerModal';
import useGetApiReq from '@/hooks/useGetApiReq';
import useDeleteApiReq from '@/hooks/useDeleteApiReq';
import ProjectDocumentsModal from '@/components/documentsModal/ProjectDocumentsModal';

const ProjectView = () => {
    const { res, fetchData, isLoading: isProjectLoading } = usePostApiReq();
    const { res: storyRes, fetchData: fetchStoryData, isLoading: isStoryLoading2 } = usePostApiReq();
    const { res: managerRes, fetchData: fetchManagerData, isLoading: isManagerLoading } = useGetApiReq();
    const { res: managerDelRes, fetchData: fetchManagerDelData, isLoading: isManagerDelLoading } = useDeleteApiReq();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
    const [isUpdateProjectModalOpen, setIsUpdateProjectModalOpen] = useState(false);

    const [allModule, setAllModule] = useState([]);
    const [allStories, setAllStories] = useState([]);
    const [allMembers, setAllMembers] = useState([]);


    const [inProgressStories, setInProgressStories] = useState([]);
    const [needsReviewStories, setNeedsReviewStories] = useState([]);
    const [completedStories, setCompletedStories] = useState([]);
    const [upcomingStories, setUpcomingStories] = useState([]);
    const [isEmployeeList, setIsEmployeeList] = useState(false);

    const [isAddTicketModalOpen, setIsAddTicketModalOpen] = useState(false);
    const [allTickets, setAllTickets] = useState([]);
    const [isTicketLoading, setIsTicketLoading] = useState(true);
    const [state, setstate] = useState("");
    const [isMemberLoading, setIsMemberLoading] = useState(true);
    const [allEvents, setAllEvents] = useState([]);
    const [allResources, setAllResources] = useState([]);
    const [isModuleLoading, setIsModuleLoading] = useState(true);
    const [isStoryLoading, setIsStoryLoading] = useState(true);
    const [isProjectManagerModalOpen, setIsProjectManagerModalOpen] = useState(false);
    const [isRemoveProjectManagerModalOpen, setIsRemoveProjectManagerModalOpen] = useState(false);


    const navigate = useNavigate();
    const params = useParams();

    const dispatch = useDispatch();

    // get-project-access-admin

    const getProjectManagerStatus = async () => {
        fetchManagerData(`/admin/get-project-access-admin/${params?.id}`);
    }

    useEffect(() => {
        getProjectManagerStatus();
    }, [])

    console.log("managerRes", managerRes);
    useEffect(() => {
        if (managerRes?.status === 200 || managerRes?.status === 201) {
        }
    }, [managerRes])


    const deleteProjectManager = async () => {
        fetchManagerDelData(`/admin/remove-project-access-admin?projectId=${params?.id}`);
    }

    useEffect(() => {
        if (managerDelRes?.status === 200 || managerDelRes?.status === 201) {
            setIsRemoveProjectManagerModalOpen(false);
            getProjectManagerStatus();
        }
    }, [managerDelRes])


    const getAllModule = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-module/${params.id}`);
            console.log("all modules", data);
            let myResources = data.data.map((item) => ({ title: item.title, id: item._id, status: item.status }))
            setAllResources(myResources);
            let filtered = [];
            filtered = data.data.filter((module) => module.status === "in-progress");
            setAllModule(filtered);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsModuleLoading(false);
        }
    }

    const getAllStories = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-project-stories/${params.id}`);
            getNotComletedStories()
            console.log('allstorires', data)
            setAllStories(data.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsStoryLoading(false);
        }
    }

    const getNotComletedStories = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-timeline-stories/${params.id}`);
            let myEvents = data.data.map((item) => ({ title: item.title, start: item.startDate, end: item.deadline, resourceId: item.moduleId, status: item.status, storyId: item._id, story: item }));
            setAllEvents(myEvents);
        } catch (error) {
            console.log(error);
        }
    }

    const getAllMembers = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-project-emp/${params.id}`);
            setAllMembers(data.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsMemberLoading(false);
        }
    }

    const getProjectDetails = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/project-profile/${params.id}`);
            setstate(data.data[0]);
        } catch (error) {
            console.log(error);
        }
    }

    const updateProjectStatus = async () => {
        fetchData(`/admin/change-project-status/${params.id}`, { status: "completed" });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getProjectDetails();
        }
    }, [res])


    useEffect(() => {
        getAllModule();
        getAllStories();
        getAllMembers();
        getProjectDetails();
        getNotComletedStories();
    }, [])

    useEffect(() => {
        const inProgress = allStories.filter((story) => story.status === "in-progress");
        const needsReview = allStories.filter((story) => story.status === "needs-review");
        const completed = allStories.filter((story) => story.status === "completed");
        const upcoming = allStories.filter((story) => story.status === "upcoming");
        setInProgressStories(inProgress);
        setNeedsReviewStories(needsReview);
        setCompletedStories(completed);
        setUpcomingStories(upcoming);
    }, [allStories])


    const handleOnDragEnd = async (result) => {
        const { source, destination } = result;

        if (!destination) return
        if (source.droppableId === destination.droppableId && source.index === destination.index) return
        let inProgress = inProgressStories;
        let needsReview = needsReviewStories;
        let completed = completedStories;
        let upcoming = upcomingStories;
        let filtered;
        let status;

        if (source.droppableId === "in-progress") {
            filtered = inProgress[source.index];
            inProgress.splice(source.index, 1);
        }
        else if (source.droppableId === "needs-review") {
            filtered = needsReview[source.index];
            needsReview.splice(source.index, 1);
        }
        else if (source.droppableId === "completed") {
            filtered = completed[source.index];
            completed.splice(source.index, 1);
        }
        else {
            filtered = upcoming[source.index];
            upcoming.splice(source.index, 1);
        }

        if (destination.droppableId === "in-progress") {
            inProgress.splice(destination.index, 0, filtered);
            status = "in-progress";
        }
        else if (destination.droppableId === "needs-review") {
            needsReview.splice(destination.index, 0, filtered);
            status = "needs-review";
        }
        else if (destination.droppableId === "completed") {
            completed.splice(destination.index, 0, filtered);
            status = "completed";
        }
        else {
            upcoming.splice(destination.index, 0, filtered);
            status = "upcoming";
        }

        setInProgressStories(inProgress);
        setNeedsReviewStories(needsReview);
        setCompletedStories(completed);
        setUpcomingStories(upcoming);

        fetchStoryData(`/admin/change-story-status/${filtered._id}`, { status })
    }

    useEffect(() => {
        if (storyRes?.status === 200 || storyRes?.status === 201) {
            getAllStories();
            getAllModule();
        }
    }, [storyRes])



    const revenueData = {
        labels: [
            // 'Mon',
            // 'Tue',
            // 'Wed',
        ],
        fontColor: "white",
        datasets: [
            {
                label: "Revenue / %",
                data: [65, 35],
                backgroundColor: ["#02A611", "#ccc"],
                borderColor: ["white"],
                borderWidth: 2,
            },
        ],
    };

    const getAllTickets = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-project-ticket/${params?.id}`);
            let filtered = [];
            if (data?.data?.length > 5) {
                for (let i = 0; i <= 4; i++) {
                    filtered.push(data.data[i]);
                }
                setAllTickets(filtered);
            }
            else {
                setAllTickets(data?.data);
            }
            setIsTicketLoading(false);
        } catch (error) {
            console.log(error);
            setIsTicketLoading(false);
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        getAllTickets();
    }, [])



    const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false)
    const [isUpdateExpenseModalOpen, setIsUpdateExpenseModalOpen] = useState(false)
    const [isExpenseInfoModalOpen, setIsExpenseInfoModalOpen] = useState(false)
    const [isExpenseDeleteModalOpen, setIsExpenseDeleteModalOpen] = useState(false);
    const [allExpenses, setAllExpenses] = useState([]);
    const [expenseDeleteId, setExpenseDeleteId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [expense, setExpense] = useState({});
    const [totalCost, setTotalCost] = useState(0);

    const getAllExpenses = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-project-expense/${params.id}`);
            setIsLoading(false);
            setTotalCost(data.totalCost);
            //console.log("project expense", data);
            setAllExpenses(data?.data);
        } catch (error) {
            //console.log(error);
            setIsLoading(false);
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        getAllExpenses();
    }, [])


    const handleExpenseDeleteModal = (id) => {
        setIsExpenseDeleteModalOpen(true);
        setExpenseDeleteId(id);
    }

    const handleUpdateModal = (data) => {
        setIsUpdateExpenseModalOpen(true);
        setExpense(data);
    }

    const handleInfoModal = (data) => {
        setIsExpenseInfoModalOpen(true);
        setExpense(data);
    }

    const handleExpenseDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-expense/${expenseDeleteId}`);
            toast.success("Expense deleted successfully");
            setIsExpenseDeleteModalOpen(false);
            getAllExpenses();
            //console.log(data);
        } catch (error) {
            //console.log(error);
            toast.error(error.response.data.message);
        }
    }


    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen || isAddExpenseModalOpen || isAddTicketModalOpen || isDocumentModalOpen ||
                isEmployeeList || isExpenseDeleteModalOpen || isExpenseInfoModalOpen || isUpdateExpenseModalOpen || isUpdateProjectModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isModalOpen, isAddExpenseModalOpen, isAddTicketModalOpen, isDocumentModalOpen, isEmployeeList, isExpenseDeleteModalOpen, isExpenseInfoModalOpen, isUpdateExpenseModalOpen, isUpdateProjectModalOpen])


    const events = [
        { title: 'Meeting', start: new Date(), end: new Date("2024-04-24") },
    ]


    return (
        <section>
            <div className='flex gap-2 items-center'>
                <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
                <h1 className='text-3xl text-[#374151] font-bold px-2 py-2 mb-4'>{state?.projectName}</h1>
            </div>
            <div className='flex justify-between items-center'>
                <div className={classes.icon_wrapper}>
                    <button onClick={() => setIsAddTicketModalOpen(true)} className="px-4 py-2 flex items-center gap-2 border-2 rounded-tl-xl rounded-br-xl">
                        Add Ticket
                        <FaCirclePlus size={18} />
                    </button>
                    {state.status !== "completed" && <button onClick={updateProjectStatus} className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl">
                        Mark as completed
                    </button>}
                    <button onClick={() => setIsDocumentModalOpen(true)} className="px-4 py-2 flex items-center gap-2 border-2 rounded-tl-xl rounded-br-xl">
                        <span>View Documents</span>
                        <IoDocumentTextOutline cursor={"pointer"} size={20} />
                    </button>
                    <button onClick={() => setIsUpdateProjectModalOpen(true)} className="px-4 py-2 flex items-center gap-2 border-2 rounded-tl-xl rounded-br-xl">
                        <span>Edit Project</span>
                        <FaEdit cursor={"pointer"} size={20} />
                    </button>
                    {managerRes?.data?.data?.employeePermissions === "write" ?
                        <button onClick={() => setIsRemoveProjectManagerModalOpen(true)} className="px-4 py-2 flex items-center gap-2 border-2 rounded-tl-xl rounded-br-xl">
                            Remove Project Manager
                        </button>
                        : <button onClick={() => setIsProjectManagerModalOpen(true)} className="px-4 py-2 flex items-center gap-2 border-2 rounded-tl-xl rounded-br-xl">
                            Select Project Manager
                        </button>
                    }
                </div>
            </div>

            <div className={classes.wrapper}>
                <div className={classes.project_detail}>
                    {/* {state?.projectId ? state?.projectId?.endDate : state?.endDate &&
                        <div className={classes.project_detail_div}>
                            <p>End Date:</p>
                            {state?.projectId ? state?.projectId?.endDate : state?.endDate && <p>{format(new Date(state?.projectId ? state?.projectId?.endDate : state?.endDate), "dd-MM-yyyy")}</p>}
                        </div>} */}
                    <div className={classes.project_detail_div}>
                        <p className='text-[#9CA3AF]'>Project Expense:</p>
                        <p className='text-[#9CA3AF]'>₹{state.projectExpense}</p>
                    </div>
                    <div className={classes.project_detail_div}>
                        <p className='text-[#9CA3AF]'>Total Expense:</p>
                        <p className='text-[#9CA3AF]'>₹{totalCost}</p>
                    </div>
                </div>
            </div>

            <Table className="mt-5">
                <TableHeader>
                    <TableRow>
                        <TableHead></TableHead>
                        <TableHead>Start Date</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Deadline</TableHead>
                        <TableHead>Time Duration</TableHead>
                        <TableHead>Project Cost</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <div className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden object-cover border-2'>
                                <img src={state.logo ? `${import.meta.env.VITE_IMAGE_URL}/${state.logo}` : avatar} className='w-full h-full object-cover' alt="avatar" />
                            </div>
                        </TableCell>
                        <TableCell>{state?.startDate && format(new Date(state?.projectId ? state?.projectId?.startDate : state?.startDate), "dd-MM-yyyy")}</TableCell>
                        <TableCell>
                            <p className={`${state?.projectId ? state?.projectId?.status : state?.status === "ongoing" ? "bg-[#FFD700] text-white" : state?.projectId ? state?.projectId?.status : state?.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-2 py-2 font-semibold text-center rounded-tl-xl rounded-br-xl`}>{state?.projectId ? state?.projectId?.status : state?.status}</p>
                        </TableCell>
                        <TableCell>{state?.deadline && format(new Date(state?.projectId ? state?.projectId?.deadline : state?.deadline), "dd-MM-yyyy")}</TableCell>
                        <TableCell>{state?.projectId ? state?.projectId?.timeDuration : state?.timeDuration} days</TableCell>
                        <TableCell>₹{state?.projectId ? state?.projectId?.projectCost : state?.projectCost}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <div className='flex gap-10 my-5'>
                <div>
                    {state?.clientId &&
                        <>
                            <h2 className='text-2xl text-[#374151] font-bold'>Client Info</h2>
                            <div className="flex gap-8 w-full font-bold mt-2">
                                <div className="w-14"></div>
                                <div className="w-36">Client Name</div>
                                <div className="w-36">Company Name</div>
                            </div>
                            <div className="flex items-center gap-8 w-full border-b pb-2 pt-0">
                                <div onClick={() => navigate(`/clients/${state.clientId._id}`, { state: state.clientId })} className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden border-2'>
                                    <img className={classes.img} src={state?.clientId?.image ? `${import.meta.env.VITE_IMAGE_URL}/${state?.clientId?.image}` : avatar} alt="client" />
                                </div>
                                <p onClick={() => navigate(`/clients/${state.clientId._id}`, { state: state.clientId })} className="w-36 cursor-pointer">{state?.projectId ? state?.projectId?.founderName : state?.clientId?.founderName}</p>
                                <p className="w-36">{state?.projectId ? state?.projectId?.companyName : state?.clientId?.companyName}</p>
                            </div>
                        </>}
                </div>
                {managerRes?.data?.data &&
                    <div className='ml-auto pr-20'>
                        <h2 className='text-2xl text-[#374151] font-bold'>Project Manager Info</h2>
                        <div className="flex gap-8 w-full font-bold mt-2">
                            <div className="w-14"></div>
                            <div className="w-36 whitespace-nowrap">Project Manager Name</div>
                        </div>
                        <div className="flex items-center gap-8 w-full border-b pb-2 pt-0">
                            <div className='w-14 h-14 rounded-full p-[2px] overflow-hidden border-2'>
                                <img className="w-full h-full object-cover" src={managerRes?.data?.data?.employeeImage ? `${import.meta.env.VITE_IMAGE_URL}/${managerRes?.data?.data?.employeeImage}` : avatar} alt="manager" />
                            </div>
                            <p className="w-36">{managerRes?.data?.data?.employeeName}</p>
                        </div>
                    </div>
                }
            </div>

            <div className="rounded-md p-3 grid grid-cols-[73%_25%] gap-3 mb-5">
                <div className="bg-white shadow-md rounded-3xl p-3 working-hours">
                    <div className="flex justify-between items-center mb-3">
                        <p className='font-semibold'>Total Working Hours Tracked</p>
                        <select className="border-2 rounded-md px-[2px]" name="" id="">
                            <option value="week">Week</option>
                        </select>
                    </div>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="dayGridWeek"
                        events={events}
                        height={"60vh"}
                        eventContent={(eventInfo) => {
                            return (
                                <div
                                    className="rounded-full bg-gradient-to-r from-[#348AC8] to-[#E4ECF2] flex items-center gap-2 text-white w-full h-7"
                                >
                                    <img className="w-7 h-7 rounded-full bg-slate-400" src={avatar} alt="avatar" />
                                    {eventInfo.event.title}
                                    <p className="px-2">25Hr</p>
                                </div>
                            );
                        }}
                        headerToolbar={{
                            left: 'today',
                            center: 'title',
                            right: 'dayGridMonth,dayGridWeek,dayGridDay',
                        }}
                    />
                </div>
                <div className="bg-white shadow-md rounded-3xl p-3">
                    <h2 className="text-xl text-center">Revenue Status</h2>
                    <div className="relative">
                        <Doughnut data={revenueData} />
                        {/* <Pie data={revenueData} /> */}
                        <div className="absolute top-[44%] left-[39%]">
                            <p className="text-center">Total</p>
                            <p className="text-center font-bold">70,000 ₹</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mb-5'>
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl text-[#374151] font-bold py-2">Project Tickets</h2>
                    <button onClick={() => navigate(`/allProjects/${params.id}/projectTickets`)} className='bg-[#1A56DB] text-white px-4 py-1 rounded'>View All</button>
                </div>

                <Table className="mt-1">
                    <TableHeader>
                        <TableRow>
                            <TableHead>Ticket Id</TableHead>
                            <TableHead>Subject</TableHead>
                            <TableHead>Assigned</TableHead>
                            <TableHead>Created Date</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allTickets?.length === 0 && !isTicketLoading &&
                            <p>No tickets found</p>
                        }
                        {allTickets?.map((ticket) => (
                            <Ticket
                                key={ticket?._id}
                                ticket={ticket}
                                getAllTickets={getAllTickets}
                            />
                        ))}
                    </TableBody>
                </Table>

            </div>

            <div className="flex w-full justify-between items-center mt-5">
                <h1 className="text-2xl text-[#374151] font-bold py-2">Project Expenses</h1>
                <button onClick={() => setIsAddExpenseModalOpen(true)} className="px-4 py-1 bg-[#1A56DB] rounded-md flex items-center gap-3 text-white">
                    <FaCirclePlus size={18} />
                    Add Expense
                </button>
            </div>

            <Table className="mt-1">
                <TableHeader>
                    <TableRow>
                        <TableHead>Expense Id</TableHead>
                        <TableHead>Item</TableHead>
                        <TableHead>From</TableHead>
                        <TableHead>Date</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allExpenses?.length === 0 && !isLoading &&
                        <p>No expenses found</p>
                    }

                    {allExpenses?.length === 0 && isLoading &&
                        <Loader />
                    }

                    {allExpenses?.map((expense) => (
                        <TableRow key={expense._id}>
                            <TableCell onClick={() => handleInfoModal(expense)}>#{expense.expenseId}</TableCell>
                            <TableCell>{expense.item}</TableCell>
                            <TableCell>{expense.from}</TableCell>
                            <TableCell>{format(new Date(expense.date), "dd-MM-yyy")}</TableCell>
                            <TableCell>
                                <p className={`text-center font-semibold w-28 rounded-tl-xl rounded-br-xl px-[3px] py-2 text-white ${expense.status === "completed" ? "bg-green-400 text-black" : (expense.status === "in-progress") ? "bg-yellow-400" : "bg-sky-500"}`}>{expense.status}</p>
                            </TableCell>
                            <TableCell>
                                <div className="flex items-center gap-2">
                                    <FaEdit onClick={() => handleUpdateModal(expense)} size={20} cursor={"pointer"} />
                                    <MdDelete onClick={() => handleExpenseDeleteModal(expense?._id)} size={20} color="red" cursor={"pointer"} />
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {isAddExpenseModalOpen &&
                <AddExpenseModal
                    setState={setIsAddExpenseModalOpen}
                    getAllExpenses={getAllExpenses}
                    projectId={params.id}
                />
            }

            {isUpdateExpenseModalOpen &&
                <AddExpenseModal
                    setState={setIsUpdateExpenseModalOpen}
                    getAllExpenses={getAllExpenses}
                    expense={expense}
                    projectId={params.id}
                />
            }

            {isExpenseInfoModalOpen &&
                <ExpenseInfoModal
                    setState={setIsExpenseInfoModalOpen}
                    expense={expense}
                />
            }

            {
                isExpenseDeleteModalOpen &&
                <DeleteModal
                    setState={setIsExpenseDeleteModalOpen}
                    handleDelete={handleExpenseDelete}
                />
            }



            <div className={classes.box_container}>
                <div className={classes.box}>
                    <h4 className="mb-3 text-lg">UI Prototyping</h4>
                    <div className="mt-2 flex gap-4 items-center text-white text-sm">
                        <div className="bg-[#1A56DB] px-2 py-[2px] flex gap-1 items-center rounded-md">
                            <FaCalendarAlt />
                            20 April 2024
                        </div>
                        <div className="bg-[#043220] px-2 py-[2px]">Normal</div>
                    </div>
                    <p className='mt-5 text-[#6A6D75]'>Develop interactive prototypes based on HiFi</p>
                    <div>
                        <Progress className="h-3 mt-1" value={23} />
                        <span>OnProgress</span>
                    </div>

                    <div className="mt-3 flex justify-between items-center">
                        <div className='flex items-center'>
                            <img className='-mr-1' src={empImg} alt="empImg" />
                            <img className='-mr-1' src={empImg} alt="empImg" />
                            <img src={empImg} alt="empImg" />
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex items-center">
                                <IoMdChatbubbles size={20} />
                                <span className='text-sm'>10</span>
                            </div>
                            <div className="flex items-center">
                                <BiMessageAltDetail size={20} />
                                <span className='text-sm'>01</span>
                            </div>
                        </div>
                    </div>

                    {/* <div className={classes.card_container}>
                        <div className={classes.card}>
                            <div className={classes.date_h4_container}>
                                <h4>UI/UX Design</h4>
                                <p className={classes.date}>02/07</p>
                            </div>
                            <div className={classes.progress}></div>
                        </div>
                    </div> */}
                </div>
                <div className={classes.box}>
                    <h4 className="mb-3 text-lg">Recent Activity</h4>
                    <div className={classes.card_container}>
                        <div className={classes.experience_card}>
                            <div className={classes.experience_card_left}>
                                <p>PW</p>
                            </div>
                            <div className={classes.experience_card_right}>
                                <h4>Pixel Wibes</h4>
                                <p className={classes.p}>
                                    20Min ago
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.box}>
                    <div className={classes.d_flex}>
                        <h4 className="mb-3 text-lg">Allocated Project Members</h4>
                        <button onClick={() => setIsEmployeeList(true)} className={classes.button}>add</button>
                    </div>

                    {allMembers.length === 0 && isMemberLoading &&
                        <Loader />
                    }

                    {allMembers.length === 0 && !isMemberLoading &&
                        <p className="mt-2">No assigned employee found</p>
                    }
                    <div className={classes.card_container}>
                        {allMembers?.map((member) => (
                            <MemberCard
                                key={member._id}
                                member={member}
                                getAllMembers={getAllMembers}
                            />
                        ))}
                    </div>
                </div>
                {isEmployeeList &&
                    <EmployeeListModal
                        setIsEmployeeList={setIsEmployeeList}
                        getAllMembers={getAllMembers}
                        projectId={state?.projectId ? state.projectId._id : state._id}
                        allMembers={allMembers}
                    />
                }
            </div>

            <div className={`${classes.h3_view_all_btn_container}`}>
                <h3 className='text-2xl text-[#374151] font-bold py-2'>In-Progress Modules</h3>
                <div className="flex items-center gap-3">
                    <button onClick={() => navigate(`/allProjects/${params.id}/allModules`)} className={classes.button}>All Modules</button>
                    <button onClick={() => setIsModalOpen(true)} className={classes.button}>
                        <FaCirclePlus size={18} />
                        Create Module
                    </button>
                </div>
            </div>

            {isModalOpen &&
                <CreateModuleModal
                    isUpdate={false}
                    setState={setIsModalOpen}
                    getAllModule={getAllModule}
                />
            }

            <div className={classes.module_container}>
                {allModule.length === 0 && isModuleLoading &&
                    <Loader />
                }
                {allModule?.length === 0 && !isModuleLoading &&
                    <p>No latest module found</p>
                }
                {allModule.map((module) => (
                    <Module
                        key={module._id}
                        module={module}
                        getAllModule={getAllModule}
                        getStories={getAllStories}
                    />
                ))}
            </div>

            <h3 className='text-2xl font-bold text-[#374151] py-2 mt-4'>Stories</h3>
            {allStories.length === 0 && isStoryLoading &&
                <Loader />
            }

            {allStories?.length === 0 && !isStoryLoading &&
                <p>No stories found</p>
            }

            {allStories.length > 0 &&
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <div className={classes.container}>
                        <div>
                            <h3 className={classes.h3}>Upcoming</h3>
                            <Droppable droppableId='upcoming'>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.task_card_container}
                                    >
                                        {upcomingStories.map((story, i) => (
                                            <Story
                                                key={i}
                                                index={i}
                                                story={story}
                                                getAllStories={getAllStories}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div>
                            <h3 className={classes.h3}>In Progress</h3>
                            <Droppable droppableId='in-progress'>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.task_card_container}
                                    >
                                        {inProgressStories.map((story, i) => (
                                            <Story
                                                key={i}
                                                index={i}
                                                story={story}
                                                getAllStories={getAllStories}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div>
                            <h3 className={classes.h3}>Needs Review</h3>
                            <Droppable droppableId='needs-review'>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.task_card_container}
                                    >
                                        {needsReviewStories.map((story, i) => (
                                            <Story
                                                key={i}
                                                index={i}
                                                story={story}
                                                getAllStories={getAllStories}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div>
                            <h3 className={classes.h3}>Completed</h3>
                            <Droppable droppableId='completed'>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.task_card_container}
                                    >
                                        {completedStories.map((story, i) => (
                                            <Story
                                                key={i}
                                                index={i}
                                                story={story}
                                                getAllStories={getAllStories}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </DragDropContext>
            }

            {isAddTicketModalOpen &&
                <AddTicketModal
                    setState={setIsAddTicketModalOpen}
                    getAllTickets={getAllTickets}
                    projectId={params.id}
                />
            }

            {isProjectManagerModalOpen &&
                <SelectProjectManagerModal
                    setState={setIsProjectManagerModalOpen}
                    projectId={params?.id}
                    getProjectManagerStatus={getProjectManagerStatus}
                />
            }

            {isUpdateProjectModalOpen &&
                <CreateProjectModal
                    getAllProject={getProjectDetails}
                    setState={setIsUpdateProjectModalOpen}
                    isUpdate={true}
                    project={state}
                />
            }

            {isRemoveProjectManagerModalOpen &&
                <DeleteModal
                    setState={setIsRemoveProjectManagerModalOpen}
                    handleDelete={deleteProjectManager}
                    text="Are you sure you want to remove it ?"
                />
            }

            <TimlineView
                allEvents={allEvents}
                allResources={allResources}
            />

            {isDocumentModalOpen &&
                <ProjectDocumentsModal
                    setIsDocumentsModalOpen={setIsDocumentModalOpen}
                    isDocumentsModalOpen={isDocumentModalOpen}
                />
            }

        </section>
    )
}

export default ProjectView