import { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axiosInstance";

import { RxCross2 } from "react-icons/rx";

import classes from './AddClientModal.module.css';
import toast from "react-hot-toast";
import { validate } from "../../utils/validate";
import usePostApiReq from "../../hooks/usePostApiReq";
import usePatchApiReq from "../../hooks/usePatchApiReq";

const AddClientModal = ({ handleModal, isUpdate, client, getAllClient }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateRes, fetchData: updateFetchData, isLoading: updateIsLoading } = usePatchApiReq();

    const [clientInfo, setClientInfo] = useState({
        companyName: client?.companyName || "",
        email: client?.email || "",
        phone: client?.phone || "",
        founderName: client?.founderName || "",
        note: client?.note || "",
        img: client?.image || "",
        previewImage: ""
    });


    const [address, setAddress] = useState({
        addressLine1: client?.address?.addressLine1 || "",
        addressLine2: client?.address?.addressLine2 || "",
        city: client?.address?.city || "",
        state: client?.address?.state || "",
        country: client?.address?.country || "",
        zipCode: client?.address?.zipCode || ""
    });

    console.log("address state", address);

    const [validateState, setValidateState] = useState([]);
    const [isPreview, setIsPreview] = useState(client ? false : true);

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        if (
            name === 'companyName'
            || name === 'email'
            || name === 'phone'
            || name === 'founderName'
            || name === 'note'
        ) {
            setClientInfo({ ...clientInfo, [name]: value });
        }
        else {
            setAddress({ ...address, [name]: value });
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "AddressLine1": address?.addressLine1,
            "AddressLine2": address?.addressLine2,
            "City": address?.city,
            "State": address?.state,
            "Country": address?.country,
            "Zipcode": address?.zipCode,
            "Company Name": clientInfo?.companyName,
            "Email": clientInfo?.email,
            "Phone": clientInfo?.phone,
            "Founder Name": clientInfo?.founderName,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!clientInfo.companyName
            || !clientInfo.email
            || !clientInfo.founderName
            || !clientInfo.phone
            || !address.city
            || !address.country
            || !address.addressLine1
            || !address.state
            || !address.zipCode
        ) {
            return null;
        }

        const formdata = new FormData();
        formdata.append("companyName", clientInfo.companyName);
        formdata.append("email", clientInfo.email);
        formdata.append("founderName", clientInfo.founderName);
        formdata.append("img", clientInfo.img);
        formdata.append("note", clientInfo.note);
        formdata.append("phone", clientInfo.phone);
        formdata.append("address", JSON.stringify(address));
        console.log(formdata.get("address"));

        if (isUpdate) {
            updateFetchData(`/admin/edit-client/${client._id}`, formdata);
        }
        else {
            fetchData("/admin/create-client", formdata);
        }
    }


    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllClient();
            handleModal();
        }
    }, [res])

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            getAllClient();
            handleModal();
        }
    }, [updateRes])


    const getImage = (event) => {
        event.preventDefault();
        // getting the image
        const uploadedImage = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setIsPreview(true);
                setClientInfo({
                    ...clientInfo,
                    previewImage: this.result,
                    img: uploadedImage,
                });
            });
        }
    };


    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.box}>
                    <div className={classes.heading_container}>
                        <h3 className="text-2xl">{isUpdate ? "Update Client" : "Add Client"}</h3>
                        <RxCross2 onClick={handleModal} cursor={"pointer"} size={26} />
                    </div>
                    <form onSubmit={handleOnSubmit}>
                        <div className={classes.input_container}>
                            <label htmlFor="img">Profile Image</label>
                            <div className="flex items-center">
                                {isPreview && clientInfo.previewImage &&
                                    <img
                                        className="w-28 h-28 rounded-full m-auto"
                                        src={clientInfo.previewImage}
                                        alt="preview image"
                                    />
                                }
                                {!isPreview && clientInfo.img &&
                                    <img
                                        className="w-28 h-28 rounded-full m-auto"
                                        src={`${import.meta.env.VITE_IMAGE_URL}/${clientInfo.img}`}
                                        alt="preview image"
                                    />
                                }
                                <input onChange={getImage} type="file" id="img" name="img" />
                            </div>
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="companyName">Company Name</label>
                            <input onChange={handleOnChange} value={clientInfo.companyName} className={classes.input} type="text" placeholder="Enter Company Name" name="companyName" id="companyName" />
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="email">Email</label>
                            <input onChange={handleOnChange} value={clientInfo.email} className={classes.input} type="email" placeholder="Enter Email Id" name="email" id="email" />
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="phone">Phone</label>
                            <input onChange={handleOnChange} value={clientInfo.phone} className={classes.input} type="tel" placeholder="Enter Phone No" name="phone" id="phone" />
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="founderName">Founder Name</label>
                            <input onChange={handleOnChange} value={clientInfo.founderName} className={classes.input} type="text" placeholder="Enter Founder Name" name="founderName" id="founderName" />
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="note">Note (optional)</label>
                            <textarea onChange={handleOnChange} value={clientInfo.note} className={classes.input} placeholder="Enter Note" name="note" id="note" cols="30" rows="10"></textarea>
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="addressLine1">Address Line1</label>
                            <input onChange={handleOnChange} value={address.addressLine1} className={classes.input} type="text" placeholder="Enter address Line1" name="addressLine1" id="addressLine1" />
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="addressLine2">Address Line2 (optional)</label>
                            <input onChange={handleOnChange} value={address.addressLine2} className={classes.input} type="text" placeholder="Enter Address Line2" name="addressLine2" id="addressLine2" />
                        </div>
                        {/* {myHtml} */}
                        <div className={classes.input_container}>
                            <label htmlFor="country">Country</label>
                            <input onChange={handleOnChange} value={address.country} className={classes.input} type="text" placeholder="Enter Country" name="country" id="country" />
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="state">State</label>
                            <input onChange={handleOnChange} value={address.state} className={classes.input} type="text" placeholder="Enter State" name="state" id="state" />
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="city">City</label>
                            <input onChange={handleOnChange} value={address.city} className={classes.input} type="text" placeholder="Enter City" name="city" id="city" />
                        </div>
                        <div className={classes.input_container}>
                            <label htmlFor="zipCode">ZipCode</label>
                            <input onChange={handleOnChange} value={address.zipCode} className={classes.input} type="number" placeholder="Enter ZipCode" name="zipCode" id="zipCode" />
                        </div>
                        {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                        <div className={classes.button_wrapper}>
                            <button className={classes.button}>{isUpdate ? "Update" : "Create"}</button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
}

export default AddClientModal