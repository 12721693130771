import { useEffect, useState } from 'react';
import classes from './AddDocumentModal.module.css';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import usePostApiReq from '../../hooks/usePostApiReq';
import toast from 'react-hot-toast';

const AddDocumentModal = ({ setState, isAddDocumentModalOpen, getAllDocuments, singleDocument, folderId = "", subFolderId = "", projectId = "" }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateRes, fetchData: updateFetchData, isLoading: loading } = usePatchApiReq();

    const [docType, setDocType] = useState("");
    const [docInfo, setDocInfo] = useState({
        doc: "",
        docPrev: "",
    })
    const [documentName, setDocumentName] = useState(singleDocument?.name || "");
    const [content, setContent] = useState(singleDocument?.content || "");
    const [videoUrl, setVideoUrl] = useState("");
    const [validateState, setValidateState] = useState([]);
    const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);

    // const { folderId } = useSelector((state) => state.folderReducer)

    const handleOnChange = (e) => {
        let { value } = e.target;
        setDocType(value);
    }

    const getDoc = (event) => {
        event.preventDefault();
        // getting the image
        const uploadedDoc = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedDoc) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedDoc);
            fileReader.addEventListener("load", function () {
                setDocInfo({
                    docPrev: this.result,
                    doc: uploadedDoc,
                });
            });
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!docType) {
            toast.error("Select doc type")
            return;
        }
        // const res = validate({
        //     "Document Name": documentName,
        //     "Content": content,
        //     "Type": docType,
        // });

        // const validateArr = [];
        // for (const key in res) {
        //     if (!res[key].status) {
        //         validateArr.push(key);
        //         setValidateState(validateArr);
        //     }
        //     else {
        //         const temp = validateArr.filter((item) => item !== key);
        //         setValidateState(temp);
        //     }
        // }

        // if (!documentName || !content || !docType) {
        //     return;
        // }

        if (docType === "upload-document") {
            if (!documentName || !docInfo.doc || !docType) {
                toast.error("All fields are required")
                return;
            }
            const formdata = new FormData();
            formdata.append("name", documentName);
            formdata.append("type", docType);
            formdata.append("folderId", folderId || "");
            formdata.append("subFolderId", subFolderId || "");
            formdata.append("projectId", projectId || "");
            formdata.append("doc", docInfo.doc);
            fetchData("/admin/create-document", formdata);
        }
        else if (docType === "add-document") {
            if (!documentName || !content || !docType) {
                toast.error("All fields are required")
                return;
            }
            fetchData("/admin/create-document", { name: documentName, content, type: docType, folderId, subFolderId, projectId });
        }
        else {
            if (!documentName || !videoUrl || !docType) {
                toast.error("All fields are required")
                return;
            }
            fetchData("/admin/create-document", { name: documentName, url: videoUrl, type: docType, folderId, subFolderId, projectId });
        }

        // if (singleDocument) {
        //     updateFetchData(`/admin/update-doc/${singleDocument._id}`, { name: documentName, content, type: docType });
        // }
        // else {
        // }

    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllDocuments();
            setState(false);
        }
    }, [res])

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            getAllDocuments();
            setState(false);
        }
    }, [updateRes])


    return (
        <Dialog open={isAddDocumentModalOpen} onOpenChange={() => setState(!isAddDocumentModalOpen)}>
            <DialogContent className="sm:max-w-[925px]">
                <DialogHeader>
                    <DialogTitle>Add Document</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className={classes.form}>
                    <div className={classes.container}>
                        <label htmlFor="docType">Document Type</label>
                        <select onChange={handleOnChange} value={docType} className={classes.input} name="docType" id="docType">
                            <option value="">Select</option>
                            <option value="add-document">Add Document</option>
                            <option value="upload-document">Upload Document</option>
                            <option value="add-video">Add Video</option>
                        </select>
                    </div>
                    {docType === "add-document" && <>
                        <div className={classes.container}>
                            <label htmlFor="documentName">Document Name</label>
                            <input
                                value={documentName}
                                onChange={(e) => setDocumentName(e.target.value)}
                                type="text"
                                id="documentName"
                                name="documentName"
                                placeholder="Document Name"
                            />
                        </div>
                        <div className={`${classes.container} mb-5`}>
                            <label htmlFor="content">Content</label>
                            <ReactQuill theme="snow" value={content} onChange={setContent} />
                        </div>
                    </>}

                    {docType === "upload-document" &&
                        <>
                            <div className={classes.container}>
                                <label htmlFor="documentName">Document Name</label>
                                <input
                                    value={documentName}
                                    onChange={(e) => setDocumentName(e.target.value)}
                                    type="text"
                                    id="documentName"
                                    name="documentName"
                                    placeholder="Document Name"
                                />
                            </div>
                            <div className={classes.input_container}>
                                <label htmlFor="img">Document</label>
                                <div className='flex items-center'>
                                    <input onChange={getDoc} type="file" id="img" name="img" />
                                </div>
                            </div>
                        </>
                    }

                    {docType === "add-video" && <>
                        <div className={classes.container}>
                            <label htmlFor="documentName">Name</label>
                            <input
                                value={documentName}
                                onChange={(e) => setDocumentName(e.target.value)}
                                type="text"
                                id="documentName"
                                name="documentName"
                                placeholder="Document Name"
                            />
                        </div>
                        <div className={classes.container}>
                            <label htmlFor="videoUrl">Video Url</label>
                            <input
                                value={videoUrl}
                                onChange={(e) => setVideoUrl(e.target.value)}
                                type="text"
                                id="videoUrl"
                                name="videoUrl"
                                placeholder="Video Url"
                            />
                        </div>
                    </>}

                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>{docType === "upload-document" ? "Upload" : "Add"}</button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDocumentModal