import { useEffect, useState } from 'react';
import classes from './UpdatePersonalInfoModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { validate } from '../../utils/validate';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import usePostApiReq from '../../hooks/usePostApiReq';

const UpdatePersonalInfoModal = ({ setState, info, getEmpDetails }) => {
    const { res, fetchData } = usePostApiReq();
    const [personalInfo, setPersonalInfo] = useState(info || "");
    const [validateState, setValidateState] = useState([]);

    const params = useParams();

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "Personal Info": personalInfo
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }
        if (!personalInfo) {
            return;
        }


        fetchData(`/admin/add-emp-personal-info/${params?.empId}`, { personalInfo });

    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setState(false);
            getEmpDetails();
        }
    }, [res])


    return (
        <div className={classes.wrapper}>
            <div className={classes.box}>
                <div className={classes.heading_container}>
                    <h3>Update Personal Info</h3>
                    <div className={classes.d_flex}>
                        <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                    </div>
                </div>
                <form onSubmit={handleOnSubmit} className={classes.form}>
                    <div className={classes.container}>
                        <label htmlFor="personalInfo">Personal Info</label>
                        <ReactQuill theme="snow" value={personalInfo} onChange={setPersonalInfo} />
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>Update</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UpdatePersonalInfoModal