import { useState } from "react"
import ResumeModal from "../resumeModal/ResumeModal";
import AddCandidateModal from "../add-candidateModal/AddCandidateModal";
import { FaEdit } from "react-icons/fa"
import { MdDelete } from "react-icons/md"
import DeleteModal from "../deleteModal/DeleteModal";
import { axiosInstance } from "../../utils/axiosInstance";
import { TableCell, TableRow } from "../ui/table";
import { format } from "date-fns";
import { FaEye } from "react-icons/fa6";

const Candidate = ({ candidate, getAllCandidate }) => {
    const [isResumeViewModalOpen, setIsResumeViewModalOpen] = useState(false);
    const [isCandidateUpdateModalOpen, setIsCandidateUpdateModalOpen] = useState(false);
    const [isCandidateDeleteModalOpen, setIsCandidateDeleteModalOpen] = useState(false);

    const handleDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-resume/${candidate._id}`, { withCredentials: true });
            console.log("delete candidate", data);
            getAllCandidate();
            setIsCandidateDeleteModalOpen(false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <TableRow>
                <TableCell>{candidate.name}</TableCell>
                <TableCell>PDF</TableCell>
                <TableCell>{candidate.remarks}</TableCell>
                <TableCell>{format(new Date(candidate?.createdAt), "dd-MM-yyyy")}</TableCell>
                <TableCell>
                    <p className="px-1 py-2 w-24 font-semibold text-center rounded-tl-xl rounded-br-xl bg-[#1C64F2] text-white">Active</p>
                </TableCell>
                <TableCell>
                    <div className="flex gap-2">
                        <FaEye cursor={"pointer"} onClick={() => setIsResumeViewModalOpen(true)} size={20} />
                        <FaEdit cursor={"pointer"} onClick={() => setIsCandidateUpdateModalOpen(true)} size={20} />
                        <MdDelete cursor={"pointer"} className="text-[#E02424]" onClick={() => setIsCandidateDeleteModalOpen(true)} size={20} />
                    </div>
                </TableCell>
            </TableRow>
            
            {isResumeViewModalOpen &&
                <ResumeModal
                    setIsResumeViewModalOpen={setIsResumeViewModalOpen}
                    resume={candidate.resume}
                />
            }

            {isCandidateUpdateModalOpen &&
                <AddCandidateModal
                    getAllCandidate={getAllCandidate}
                    setState={setIsCandidateUpdateModalOpen}
                    candidate={candidate}
                />
            }

            {isCandidateDeleteModalOpen &&
                <DeleteModal
                    handleDelete={handleDelete}
                    setState={setIsCandidateDeleteModalOpen}
                />
            }
        </>
    )
}

export default Candidate