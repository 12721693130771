import { format } from 'date-fns';
import classes from './ModuleInfoModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import DeleteModal from '../deleteModal/DeleteModal';
import { useState } from 'react';
import { useEffect } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import Story from '../story/Story';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import CreateModuleModal from '../create-module-modal/CreateModuleModal';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FaCirclePlus } from 'react-icons/fa6';
import CreateStoryModal from '../create-story-modal/CreateStoryModal';
import usePostApiReq from '../../hooks/usePostApiReq';
import loader from '../../assets/rolling.gif';
import parse from "html-react-parser";
import useGetApiReq from '../../hooks/useGetApiReq';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import AllModuleStory from '../all-module-story/AllModuleStory';
import { FaEdit } from 'react-icons/fa';

const ModuleInfoModal = ({ setState, module, getAllModule, employee, getStories }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: storyRes, fetchData: fetchStoryData, isLoading: isStoryLoading } = useGetApiReq();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStoryCreateModalOpen, setIsStoryCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [allStories, setAllStories] = useState([]);


    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`/admin/delete-module/${module._id}`);
            toast.success("Module deleted successfully");
            getAllModule();
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const getAllStories = async () => {
        fetchStoryData(`/admin/get-module-stories/${module?._id}`);
    }

    useEffect(() => {
        getAllStories()
    }, [])

    useEffect(() => {
        if (storyRes?.status === 200 || storyRes?.status === 201) {
            setAllStories(storyRes?.data.data);
            getStories()
        }
    }, [storyRes])


    const updateStatus = async (status) => {
        fetchData(`/admin/change-module-status/${module?._id}`, { status }, { withCredentials: true });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllModule();
        }
    }, [res])

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.box}>
                    <div className={classes.heading_container}>
                        <h2 className='text-3xl font-semibold'>Module Info </h2>
                        <div className={classes.button_icon_container}>
                            <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                        </div>
                    </div>
                    <div className='flex justify-between items-center'>
                        <div className='flex gap-4 items-center mt-3'>
                            <h4 className='text-2xl font-semibold text-[#9CA3AF]'>{module.title}</h4>
                            <span className={`${module.status === "in-progress" ? "bg-[#FFD700] text-white" : module.status === "needs-review" ? "bg-red-500 text-white" : module.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-2 py-[1px]`}>{module.status}</span>
                        </div>
                        {!employee &&
                            <div className={classes.buttons_wrapper}>
                                <button onClick={() => setIsModalOpen(true)}><FaEdit /></button>
                                <button onClick={() => setIsDeleteModalOpen(true)}><MdDelete /></button>
                            </div>}
                    </div>
                    <Table className="mt-3">
                        <TableHeader>
                            <TableRow>
                                <TableHead>Start Date</TableHead>
                                {module.endDate && <TableHead>End Date</TableHead>}
                                <TableHead>Deadline</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Description</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell>{module.startDate && format(new Date(module.startDate), "dd-MM-yyyy")}</TableCell>
                                {module.endDate && <TableCell>{module.endDate && format(new Date(module.endDate), "dd-MM-yyyy")}</TableCell>}
                                <TableCell>{module.deadline && format(new Date(module.deadline), "dd-MM-yyyy")}</TableCell>
                                <TableCell>
                                    {employee ? <span className={`${module.status === "in-progress" ? "bg-[#FFD700] text-white" : module.status === "needs-review" ? "bg-red-500 text-white" : module.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-2 py-[1px] rounded`}>{module.status}</span>
                                        : <select onChange={(e) => updateStatus(e.target.value)} className='border-2 ml-2 rounded-md' name="" id="">
                                            <option value="">Select</option>
                                            <option value="in-progress">In Progress</option>
                                            <option value="needs-review">Needs Review</option>
                                            <option value="completed">Completed</option>
                                            <option value="upcoming">Upcoming</option>
                                        </select>}
                                </TableCell>
                                <TableCell className="w-[170px]">{module?.description && parse(module.description)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>


                    <div className={classes.container}>
                        <h3 className='text-2xl font-semibold'>Stories</h3>
                        {!employee &&
                            <button onClick={() => setIsStoryCreateModalOpen(true)} className="px-4 py-1 border-2 rounded-md flex items-center gap-3">
                                <FaCirclePlus size={18} />
                                Create Story
                            </button>}
                    </div>
                    <div className={classes.story_container}>
                        {allStories.length === 0 && !isStoryLoading &&
                            <p>No stories found</p>
                        }

                        {/* {allStories.length === 0 && isStoryLoading &&
                            <div className="flex w-full justify-center items-center mt-2">
                                <img className="w-8 h-8" src={loader} alt="loader" />
                            </div>
                        } */}

                        {allStories.map((story, i) => (
                            <AllModuleStory
                                key={i}
                                story={story}
                                getAllStories={getAllStories}
                                setState={setState}
                            />
                        ))}
                    </div>
                </div>
                {isDeleteModalOpen &&
                    <DeleteModal
                        setState={setIsDeleteModalOpen}
                        handleDelete={handleDelete}
                    />
                }

                {isModalOpen &&
                    <CreateModuleModal
                        isUpdate={true}
                        module={module}
                        setState={setIsModalOpen}
                        getAllModule={getAllModule}
                    />
                }

                {isStoryCreateModalOpen &&
                    <CreateStoryModal
                        id={module._id}
                        setState={setIsStoryCreateModalOpen}
                        getAllStories={getAllStories}
                    />
                }
            </div>
        </>
    )
}

export default ModuleInfoModal