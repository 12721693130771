import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Button } from "../ui/button"
import { Label } from "../ui/label"
import { Input } from "../ui/input"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import usePostApiReq from "@/hooks/usePostApiReq";
import { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import { validate } from "@/utils/validate";

const SendEmailModal = ({ isSendEmailModalOpen, setIsSendEmailModalOpen, getEmails }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const [emailInfo, setEmailInfo] = useState({
        body: "",
        recipientMail: "",
        subject: "",
        cc: "",
        bcc: "",
        attachments: [],
        filePrev: "",
    })
    const [validateState, setValidateState] = useState([]);
    const [isCc, setIsCc] = useState(false);
    const [isBcc, setIsBcc] = useState(false);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setEmailInfo({ ...emailInfo, [name]: value });
    }

    console.log("emailInfo", emailInfo);

    const getImage = (event) => {
        event.preventDefault();
        // getting the image
        let uploadedImage = Array.from(event.target.files);

        setEmailInfo(() => ({
            ...emailInfo,
            attachments: uploadedImage,
        }));
        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setEmailInfo({
                    ...emailInfo,
                    attachments: uploadedImage,
                    filePrev: this.result,
                });
            });
        }
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const res = validate({
            "Subject": emailInfo.subject,
            "Recipient Mail": emailInfo.recipientMail,
            "Email Body": emailInfo.body,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!emailInfo.recipientMail || !emailInfo.subject || !emailInfo.body) {
            return;
        }

        const formData = new FormData();
        formData.append("body", emailInfo.body);
        formData.append("recipientMail", emailInfo.recipientMail);
        formData.append("subject", emailInfo.subject);
        formData.append("cc", emailInfo.cc);
        formData.append("bcc", emailInfo.bcc);
        emailInfo.attachments.map((attachment) => {
            formData.append("attachments", attachment);
        })

        for (const pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }
        fetchData("/user/send-mail", formData);
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("send email res", res);
            setIsSendEmailModalOpen(false);
            getEmails();
        }
    }, [res])

    return (
        <Dialog open={isSendEmailModalOpen} onOpenChange={() => setIsSendEmailModalOpen(!isSendEmailModalOpen)}>
            <DialogContent className="sm:max-w-[1000px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle className="text-left">Send Email</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="flex justify-end gap-2">
                        <button type="button" onClick={() => setIsCc(!isCc)}>Cc</button>
                        <button type="button" onClick={() => setIsBcc(!isBcc)}>Bcc</button>
                    </div>
                    <div className="grid grid-cols-[10%_87%] items-center gap-4">
                        <Label>To</Label>
                        <Input onChange={handleOnChange} value={emailInfo.recipientMail} name="recipientMail" id="recipientMail" />
                    </div>
                    {isCc && <div className="grid grid-cols-[10%_87%] items-center gap-4">
                        <Label>CC</Label>
                        <Input onChange={handleOnChange} value={emailInfo.cc} name="cc" id="cc" />
                    </div>}
                    {isBcc && <div className="grid grid-cols-[10%_87%] items-center gap-4">
                        <Label>BCC</Label>
                        <Input onChange={handleOnChange} value={emailInfo.bcc} name="bcc" id="bcc" />
                    </div>}
                    <div className="grid grid-cols-[10%_87%] items-center gap-4">
                        <Label>Subject</Label>
                        <Input onChange={handleOnChange} value={emailInfo.subject} name="subject" id="subject" />
                    </div>
                    <div className="grid grid-cols-[10%_87%] items-start gap-4">
                        <Label>Attachment</Label>
                        <div className='w-full'>
                            {/* {emailInfo.filePrev &&
                                <iframe className='w-full h-[350px] overflow-hidden object-contain' src={emailInfo?.filePrev} alt="" />
                            } */}
                            <Input className="mt-2" multiple={true} onChange={(e) => getImage(e)} type="file" />
                        </div>
                    </div>
                    <div className="grid grid-cols-[10%_87%] h-96 items-start gap-4">
                        <Label>Content</Label>
                        <ReactQuill onChange={(value) => setEmailInfo({ ...emailInfo, body: value })} value={emailInfo.body} className="h-80" theme="snow" />
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Loader size={25} /> : "Send"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default SendEmailModal