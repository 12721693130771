import React, { useState } from 'react'
import { TableCell, TableRow } from '../ui/table'
import { Input } from '../ui/input'
import { FaRegStar, FaTrash } from 'react-icons/fa6'
import { format } from 'date-fns'
import DeleteModal from '../deleteModal/DeleteModal'

const Email = ({ email, handleEmailClick, folder, handleDelete, setSelected, selected }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [seqno, setSeqno] = useState("");

    console.log("email", email);
    const handleOnchange = (e) => {
        e.stopPropagation();
        if (e.target.checked) {
            setSelected((prev) => [...prev, email?.seqno]);
        }
        else {
            const clients = [...selected].filter((item) => item !== email?.seqno)
            setSelected(clients);
        }
    }

    const handleDeleteModalOpen = (e, value) => {
        e.stopPropagation();
        setIsDeleteModalOpen(true);
        setSeqno(value);
    }

    return (
        <>
            <TableRow className={`font-medium cursor-pointer group ${email?.seen ? "text-gray-500 bg-gray-50" : "text-black bg-white"}`}>
                <TableCell>
                    <Input checked={selected.find((item) => item === email?.seqno)} onChange={handleOnchange} className="w-4 h-4" type="checkbox" />
                </TableCell>
                <TableCell>
                    <FaRegStar className='text-slate-400' size={20} />
                </TableCell>
                <TableCell onClick={() => handleEmailClick(email)}>
                    {folder === "INBOX.Sent" ? <p className='w-40 line-clamp-1'>{email?.headers?.to}</p>
                        : <p className='w-40 line-clamp-1'>{email?.headers?.from}</p>}
                </TableCell>
                <TableCell onClick={() => handleEmailClick(email)}>
                    <p className="line-clamp-1 mx-3">{email?.headers?.subject && email?.headers?.subject}</p>
                </TableCell>
                <TableCell className="w-24">
                    <div onClick={() => handleEmailClick(email)} className='block group-hover:hidden'>
                        {email?.headers?.date && (
                            (format(new Date(), "dd MMM") === format(new Date(email?.headers?.date), "dd MMM")) ?
                                format(new Date(email?.headers?.date), "hh:mm a")
                                :
                                format(new Date(email?.headers?.date), "dd MMM")
                        )}
                    </div>
                    <FaTrash onClick={(e) => handleDeleteModalOpen(e, [email.seqno])} className='group-hover:block hidden cursor-pointer' />

                </TableCell>
            </TableRow>
            {isDeleteModalOpen &&
                <DeleteModal
                    handleDelete={()=>handleDelete(seqno)}
                    setState={setIsDeleteModalOpen}
                />
            }
        </>
    )
}

export default Email