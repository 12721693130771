import React, { useEffect, useRef, useState } from 'react'
import AttendanceInfoModal from '../../components/attendance-infoModal/AttendanceInfoModal';
import { format } from 'date-fns';
import { axiosInstance } from '../../utils/axiosInstance';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft, FaXmark } from 'react-icons/fa6';
import { getISTDate } from '../../utils/getISTDate';
import { TiTickOutline } from 'react-icons/ti';

const EmployeeAttendanceDetail = () => {
    const calendarRef = useRef(null);
    const params = useParams();
    const navigate = useNavigate();

    const [allEvents, setAllEvents] = useState([])
    const [event, setEvent] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [noOfAbsents, setNoOfAbsents] = useState(0);
    const [noOfPresents, setNoOfPresents] = useState(0);

    const handleDateClick = (arg) => {
        console.log("arg", arg);
        const data = allEvents.find((event) => {
            console.log("event", event);
            const date = format(new Date(event.date), "dd-MM-yyyy");
            const eventDate = format(new Date(arg.date), "dd-MM-yyyy");

            return date === eventDate
        })
        console.log("event data", data);
        if (data) {
            setEvent(data);
            setModalIsOpen(true);
        }
    };

    const handleDatesSet = () => {
        console.log("handle dates set clicked");
        const currentDate = new Date();
        setSelectedDate(() => currentDate);
    };



    const handleMonthChange = async (newDate) => {
        const month = format(new Date(newDate), "MM");
        const year = format(new Date(newDate), "yyyy");
        try {
            const { data } = await axiosInstance.get(`/admin/get-user-atd/${params?.attId}?month=${month}&year=${year}`);
            console.log("attendance admin site", data);
            setNoOfAbsents(data.no_of_absents);
            setNoOfPresents(data.no_of_presents);

            const Data = data?.attendance?.att?.map((singleAttendance) => {
                if (singleAttendance.punchIn && singleAttendance.punchOut) {
                    return { title: "Present", date: singleAttendance?.punchIn, flag: singleAttendance.flag, punchIn: singleAttendance.punchIn, punchOut: singleAttendance.punchOut, breaks: singleAttendance.breaks }
                }
                else {
                    return { title: "Absent", date: singleAttendance?.punchIn, flag: singleAttendance.flag, punchIn: singleAttendance.punchIn, punchOut: singleAttendance.punchOut, breaks: singleAttendance.breaks }
                }
            })
            console.log("allevents", Data);
            setAllEvents(Data);
        } catch (error) {
            console.log("error", error);
        }
    }

    const handlePrev = () => {
        const calendar = calendarRef.current.getApi();

        const currentDate = selectedDate;

        const prevMonth = new Date(currentDate);
        prevMonth.setMonth(currentDate.getMonth() - 1);
        console.log("prevMonth", prevMonth);

        calendar.gotoDate(format(new Date(prevMonth), "yyyy-MM-dd"));
        setSelectedDate(() => prevMonth);
    };

    const handleNext = () => {
        const calendar = calendarRef.current.getApi();

        const currentDate = selectedDate;

        const nextMonth = new Date(currentDate);
        nextMonth.setMonth(currentDate.getMonth() + 1);

        calendar.gotoDate(format(new Date(nextMonth), "yyyy-MM-dd"));
        setSelectedDate(() => nextMonth);
    };

    useEffect(() => {
        handleMonthChange(selectedDate);
    }, [selectedDate])


    const customButtons = {
        Prev: {
            text: 'Prev',
            click: handlePrev,
        },
        Next: {
            text: 'Next',
            click: handleNext,
        },
    };
    return (
        <div>
            <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
            <h1 className="text-3xl font-bold py-2">Attendance</h1>
            <div className='mt-5'>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={allEvents}
                    height={"84vh"}
                    eventContent={(eventInfo) => {
                        return (
                            <div
                                className={`w-10 h-10 rounded-full text-white ml-2 mb-2 flex justify-center items-center ${eventInfo.event.title === "Present" && "bg-[#0E9F6E]"} ${eventInfo.event.title === "Absent" && "bg-red-500"}`}
                            >
                                {eventInfo.event.title === "Present" && <TiTickOutline size={22} />}
                                {eventInfo.event.title === "Absent" && <FaXmark size={22} />}
                            </div>
                        );
                    }}
                    dateClick={handleDateClick}
                    // datesSet={handleDatesSet}
                    customButtons={customButtons}
                    headerToolbar={{
                        left: 'today Prev Next',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek,dayGridDay',
                    }}
                />

                <div className="flex justify-between max-w-lg mx-auto mt-5 gap-4">
                    <p className="text-lg font-semibold">
                        Total Present Days : {noOfPresents}
                    </p>
                    <p className="text-lg font-semibold">
                        Total Absent Days : {noOfAbsents}
                    </p>
                </div>

                {modalIsOpen &&
                    <AttendanceInfoModal
                        setState={setModalIsOpen}
                        event={event}
                    />
                }
            </div>
        </div>
    )
}

export default EmployeeAttendanceDetail