import { useEffect, useState } from 'react';
import classes from './AddReminderModal.module.css';
import { RxCross2 } from 'react-icons/rx';

import { useParams } from 'react-router-dom';
import { validate } from '../../utils/validate';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import { TiDeviceDesktop } from "react-icons/ti";

import { Switch } from "@/components/ui/switch"
import { Calendar } from "@/components/ui/calendar"
import { MdAccessTime } from 'react-icons/md';


const AddReminderModal = ({ setState, getAllReminder }) => {
    const { res, fetchData, isLoading } = usePatchApiReq();

    const [validateState, setValidateState] = useState([]);
    const [date, setDate] = useState(new Date());
    const [amOrPm, setAmOrPm] = useState("PM")

    const params = useParams();


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "Date": date
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!date) {
            return;
        }

        fetchData("/admin", {});

    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllReminder();
            setState(false);
        }
    }, [res])


    return (
        <div className={classes.add_document_wrapper}>
            <div className={classes.add_document_box}>
                <div className={classes.heading_container}>
                    <h3>Add Reminder</h3>
                    <div className={classes.d_flex}>
                        <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                    </div>
                </div>
                <form onSubmit={handleOnSubmit} className={classes.form}>
                    <input type="text" className="border-b-2 text-lg font-semibold outline-none border-[#9CA3AF]" placeholder='Remind me of' name="" id="" />

                    <Calendar
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        className="w-full mt-2"
                    />

                    <div className="flex justify-between items-center mt-4">
                        <div className='flex items-center gap-2'>
                            <TiDeviceDesktop size={24} />
                            <b className='text-lg'>All-day</b>
                        </div>
                        <Switch />
                    </div>
                    <div className="flex justify-between items-center -mt-2">
                        <div className='flex items-center gap-2'>
                            <MdAccessTime size={24} />
                            <b className='text-lg'>Time</b>
                        </div>
                        <div className="flex items-center w-44 gap-2">
                            <input type="time" onChange={(e)=> console.log(e.target.value)} max={12} className='text-[#348AC8] bg-[#D9D9D9] border-none outline-none px-2 py-1 h-9' />
                            <div className='flex items-center justify-end'>
                                <button onClick={()=>setAmOrPm("PM")} className={`w-10 ${amOrPm === "PM" && "bg-[#348AC8] text-white"} px-1 font-bold h-9`}>PM</button>
                                <button onClick={()=>setAmOrPm("AM")} className={`w-10 ${amOrPm === "AM" && "bg-[#348AC8] text-white"} px-1 font-bold h-9`}>AM</button>
                            </div>
                        </div>
                    </div>

                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className="flex justify-center">
                        <button className="bg-[#348AC8] text-white rounded-md w-72 py-2">Add Reminder</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddReminderModal