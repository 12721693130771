import { useState } from 'react';
import classes from './CreateStoryModal.module.css';
import { axiosInstance } from '../../utils/axiosInstance';
import { format } from 'date-fns';

import { RxCross2 } from 'react-icons/rx';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { validate } from '../../utils/validate';
import usePostApiReq from '../../hooks/usePostApiReq';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import useGetApiReq from '../../hooks/useGetApiReq';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateStoryModal = ({ isUpdate, id = "", story = "", setState, getAllStories }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateRes, fetchData: fetchUpdateData, isLoading: isUpdateLoading } = usePatchApiReq();
    const { res: memberRes, fetchData: fetchMemberData, isLoading: isMemberLoading } = useGetApiReq();

    const params = useParams();
    const [storyInfo, setStoryInfo] = useState({
        moduleId: story?.moduleId || id,
        title: story?.title || "",
        startDate: story?.startDate && format(new Date(story.startDate), "yyyy-MM-dd") || format(new Date(), "yyyy-MM-dd"),
        deadline: story?.deadline && format(new Date(story.deadline), "yyyy-MM-dd") || "",
        type: story?.type || "",
        assignedEmployee: story?.assignedEmployee || "",
        assignedProject: params.id ? params.id : params.pId,
    });
    const [description, setDescription] = useState(story?.description || "")
    const [allMembers, setAllMembers] = useState([]);
    const [validateState, setValidateState] = useState([]);

    console.log("params", params);


    const getAllMembers = async () => {
        fetchMemberData(`/admin/get-project-emp/${params.id ? params.id : params.pId}`);
    }

    useEffect(() => {
        getAllMembers();
    }, [])

    useEffect(() => {
        if (memberRes?.status === 200 || memberRes?.status === 201) {
            setAllMembers(memberRes?.data.data);
        }
    }, [memberRes])

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setStoryInfo({ ...storyInfo, [name]: value });
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "Title": storyInfo?.title,
            "Description": storyInfo?.description,
            "Start Date": storyInfo?.startDate,
            "Dead Line": storyInfo?.deadline,
            "Type": storyInfo?.type,
            "Assigned Employee": storyInfo?.assignedEmployee,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!storyInfo.moduleId
            || !storyInfo.title
            || !description
            || !storyInfo.startDate
            || !storyInfo.deadline
            || !storyInfo.type
            || !storyInfo.assignedEmployee
            || !storyInfo.assignedProject
        ) {
            return;
        }
        if (isUpdate) {
            fetchUpdateData(`admin/update-stories/${story._id}`, { ...storyInfo, description });
        }
        else {
            fetchData("admin/create-stories", { ...storyInfo, description });
        }
    }

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            getAllStories();
            setState(false);
        }
    }, [updateRes])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllStories();
            setState(false);
        }
    }, [res])


    return (
        <div className={classes.wrapper}>
            <div className={classes.box}>
                <div className={classes.heading_container}>
                    <h3 className='text-2xl'>{isUpdate ? "Update" : "Create"} Story</h3>
                    <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                </div>
                <form onSubmit={handleOnSubmit}>
                    <div className={classes.input_container}>
                        <label htmlFor="title">Title</label>
                        <input onChange={handleOnChange} value={storyInfo.title} className={classes.input} type="text" placeholder="Enter Title" name="title" id="title" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="description">Description</label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription} />
                        {/* <input onChange={handleOnChange} value={storyInfo.description} className={classes.input} type="text" placeholder="Enter Description" name="description" id="description" /> */}
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="startDate">StartDate</label>
                        <input onChange={handleOnChange} value={storyInfo.startDate} className={classes.input} type="date" placeholder="Enter StartDate" name="startDate" id="startDate" />
                    </div>
                    {/* <div className={classes.input_container}>
                        <label htmlFor="endDate">EndDate</label>
                        <input onChange={handleOnChange} value={storyInfo.endDate} className={classes.input} type="date" placeholder="Enter endDate" name="endDate" id="endDate" />
                    </div> */}
                    <div className={classes.input_container}>
                        <label htmlFor="deadline">Deadline</label>
                        <input onChange={handleOnChange} value={storyInfo.deadline} className={classes.input} type="date" placeholder="Enter deadline" name="deadline" id="deadline" />
                    </div>
                    {/* <div className={classes.input_container}>
                        <label htmlFor="status">Status</label>
                        <select onChange={handleOnChange} value={storyInfo.status} className={classes.input} name="status" id="status">
                            <option value="">Select</option>
                            <option value="in-progress">In Progress</option>
                            <option value="needs-review">Needs Review</option>
                            <option value="completed">Completed</option>
                            <option value="upcoming">Upcoming</option>
                        </select>
                    </div> */}
                    <div className={classes.input_container}>
                        <label htmlFor="type">Type</label>
                        <input onChange={handleOnChange} value={storyInfo.type} className={classes.input} type="text" placeholder="Enter type" name="type" id="type" />
                    </div>
                    <div className={classes.input_container}>
                        <label htmlFor="assignedEmployee">Assigned Employee</label>
                        <select onChange={handleOnChange} value={storyInfo.assignedEmployee} className={classes.input} name="assignedEmployee" id="assignedEmployee">
                            <option value="">Select</option>
                            {allMembers?.map((employee) => (
                                <option key={employee?.data[0]?._id} value={employee?.data[0]?._id}>{employee?.data[0]?.name}</option>
                            ))}
                        </select>
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>{isUpdate ? "Update" : "Create"}</button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default CreateStoryModal