import { useEffect, useState } from "react"

import { FaCirclePlus } from "react-icons/fa6"
import AddTicketModal from "../../components/add-ticketModal/AddTicketModal";
import { axiosInstance } from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import { openCloseBody } from "../../store/slices/bodySlice";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import LimitComp from "../../components/limit-comp/LimitComp";
import Loader from "@/components/loader/Loader";
import Search from "@/components/search/Search";
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Ticket from "@/components/ticket/Ticket";

const MyTickets = () => {
    const [isAddTicketModalOpen, setIsAddTicketModalOpen] = useState(false);
    const [allTickets, setAllTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        const handleModalOpen = () => {
            if (isAddTicketModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isAddTicketModalOpen])

    const handleTicketChange = async () => {
        setAllTickets([]);
        try {
            setIsLoading(true);
            const { data } = await axiosInstance.get(`/admin/get-user-tickets?page=${page}&limit=${limit}`);
            setIsLoading(false);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
            //console.log("my", data);
            setAllTickets(data?.data);
        } catch (error) {
            //console.log(error);
            setIsLoading(false);
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        handleTicketChange();
    }, [page, limit])


    return (
        <>
            <section>
                <h1 className="text-3xl font-bold py-2">My Tickets</h1>
                <div className="flex w-full justify-between mt-8 items-center">
                    <div className="flex items-center gap-2">
                        <LimitComp
                            setLimit={setLimit}
                            limit={limit}
                        />

                        <button onClick={() => setIsAddTicketModalOpen(true)} className="px-4 py-2 border-2 rounded-md flex items-center gap-3">
                            <FaCirclePlus size={18} />
                            Add Ticket
                        </button>
                        <Search
                            placeholder="Search Ticket"
                        />
                    </div>
                </div>

                <Table className="mt-3">
                    <TableHeader>
                        <TableRow>
                            <TableHead>Ticket Id</TableHead>
                            <TableHead>Subject</TableHead>
                            <TableHead>Assigned</TableHead>
                            <TableHead>Created Date</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allTickets?.map((ticket) => (
                            <Ticket
                                key={ticket?._id}
                                ticket={ticket}
                                getAllTickets={handleTicketChange}
                            />
                        ))}
                    </TableBody>
                </Table>
                {allTickets.length === 0 && !isLoading &&
                    <p>No tickets found</p>
                }
                {allTickets.length === 0 && isLoading &&
                    <Loader />
                }

                {pageCount > 0 &&
                    <div className="mt-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}
            </section>
            {isAddTicketModalOpen &&
                <AddTicketModal
                    setState={setIsAddTicketModalOpen}
                    getAllTickets={handleTicketChange}
                />
            }
        </>
    )
}

export default MyTickets