import { useEffect, useState } from 'react'
import Candidate from '../../components/candidate/Candidate'
import AddCandidateModal from '../../components/add-candidateModal/AddCandidateModal';
import { axiosInstance } from '../../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import { openCloseBody } from '../../store/slices/bodySlice';
import ReactPaginate from 'react-paginate';
import LimitComp from '../../components/limit-comp/LimitComp';
import Loader from '@/components/loader/Loader';
import Search from '@/components/search/Search';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

const Hiring = () => {
    const [isAddCandidateModalOpen, setIsAddCandidateModalOpen] = useState(false);
    const [allResumes, setAllResumes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const dispatch = useDispatch();

    const handleModalOpen = () => {
        setIsAddCandidateModalOpen(() => true);
        dispatch(openCloseBody(true));
    }

    const getAllResumes = async () => {
        try {
            setAllResumes([]);
            setIsLoading(true);
            const { data } = await axiosInstance.get(`/admin/get-all-resumes?page=${page}&limit=${limit}`, { withCredentials: true });
            setAllResumes(data.resumes);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
            console.log("all resumes", data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAllResumes();
    }, [page, limit])



    return (
        <div>
            <h1 className="text-3xl font-bold py-2">Resumes</h1>
            <div className="flex items-center gap-2 mt-8">
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />

                <button onClick={handleModalOpen} className="px-4 py-2 border-2 rounded-md flex items-center gap-3">
                    Add Resume
                </button>
                <Search
                    placeholder="Search Resume"
                />
            </div>

            <Table className="mt-4">
                <TableHeader>
                    <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead>Remark</TableHead>
                        <TableHead>Uploaded Date</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allResumes?.map((resume) => (
                        <Candidate
                            key={resume._id}
                            candidate={resume}
                            getAllCandidate={getAllResumes}
                        />
                    ))}
                </TableBody>
            </Table>
            {allResumes.length === 0 && isLoading &&
                <Loader />
            }

            {allResumes.length === 0 && !isLoading &&
                <p>No resume found</p>
            }


            {isAddCandidateModalOpen &&
                <AddCandidateModal
                    getAllCandidate={getAllResumes}
                    setState={setIsAddCandidateModalOpen}
                />
            }

            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}
        </div>
    )
}

export default Hiring