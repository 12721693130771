import { MdAttachment, MdEdit } from "react-icons/md";
import { FaArrowLeft, FaComments, FaUser } from "react-icons/fa";
import { RiPriceTag2Line } from "react-icons/ri";
import { BsFillOpticalAudioFill, BsThreeDots } from "react-icons/bs";
import { LuImage } from "react-icons/lu";
import parse from "html-react-parser";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AddReasonModal from "../../components/add-ReasonModal/AddReasonModal";
import { axiosInstance } from "../../utils/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { openCloseBody } from "../../store/slices/bodySlice";
import { useDispatch } from "react-redux";
import ImageModal from "../../components/ImageModal/ImageModal";
import usePatchApiReq from "../../hooks/usePatchApiReq";
import { format } from "date-fns";

const MyTicketsDetail = () => {
    const { res, fetchData, isLoading } = usePatchApiReq();

    const [status, setStatus] = useState("")
    const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
    const [isImgView, setIsImgView] = useState(false);
    const [ticket, setTicket] = useState({});

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOnClick = async () => {
        if (status === "") {
            toast.error("Select status");
            return;
        }

        fetchData(`/admin/update-admin-ticket/${params?.id}`, { status });
    }

    const getTicketDetail = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-admin-ticket-by/${params?.id}`);
            //console.log(data);
            setTicket(data?.data);
        } catch (error) {
            //console.log(error);
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        getTicketDetail();
    }, [params.id])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getTicketDetail();
        }
    }, [res])


    useEffect(() => {
        if (status === "decline" || status === "completed") {
            setIsReasonModalOpen(true);
        }
    }, [status])

    useEffect(() => {
        if (!isReasonModalOpen) {
            setStatus("");
        }
        const handleModalOpen = () => {
            if (isReasonModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isReasonModalOpen])


    return (
        <section>
            <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
            <div className="flex w-full justify-between items-center">
                <h1 className="text-3xl font-bold py-2">Ticket Detail</h1>
                <div>
                    <select value={status} onChange={(e) => setStatus(e.target.value)} className="border-2 border-[#1A56DB] text-[#1A56DB] rounded py-1" name="" id="">
                        <option value="">Select Status</option>
                        <option value="in-progress">In Progress</option>
                        <option value="decline">Decline</option>
                        <option value="completed">Completed</option>
                    </select>
                    <button onClick={handleOnClick} className="border-2 ml-2 rounded px-2 py-1">Update</button>
                </div>
            </div>
            <div className="flex max-[942px]:flex-col gap-2 mt-5">
                <div className="w-[75%] max-[942px]:w-full">
                    <div className="grid grid-cols-3 gap-5">
                        <div className="flex gap-2 p-4 rounded-md shadow border-l-4 border-[#348AC8]">
                            <div>
                                <p className="text-[#348AC8] font-medium text-lg">Status</p>
                                <div className={`text-white ${ticket.status === "completed" ? "bg-[#008000E5]" : (ticket.status === "in-progress") ? "bg-yellow-400" : (ticket.status === "decline") ? "bg-red-500" : "bg-sky-500"} text-center px-3 mt-2 font-bold`}>{ticket.status}</div>
                                <p className="mt-2 text-sm text-[#6B7280]">Time: {ticket?.createdAt && format(new Date(ticket?.createdAt), "dd-MM-yyy")}</p>
                            </div>
                        </div>
                        <div className="flex gap-2 justify-between p-4 rounded-md shadow border-l-4 border-[#348AC8]">
                            <div>
                                <p className="text-[#348AC8] font-medium text-lg">Created By</p>
                                <div className="px-1 rounded-full mt-2 font-bold">{ticket?.createdBy?.name}</div>
                            </div>
                            <div className="w-12 h-12 my-auto bg-[#348AC8] rounded-full text-white flex justify-center items-center">
                                <FaUser />
                            </div>
                        </div>
                        <div className="flex gap-2 justify-between p-4 rounded-md shadow border-l-4 border-[#348AC8]">
                            <div className="text-sm">
                                <p className="text-[#348AC8] font-medium text-lg">Priority</p>
                                <div className={`${ticket.priority === "low" ? "bg-[#008000E5] text-white" : (ticket.priority === "medium") ? "bg-yellow-400" : "bg-red-500"} text-center px-2 mt-2 font-bold`}>{ticket.priority}</div>
                            </div>
                            <div className="w-12 h-12 my-auto bg-[#FFC107] rotate-45 flex justify-center items-center">
                                <BsThreeDots className="-rotate-45" size={24} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mt-5">
                <div className="border-[3px] rounded-lg border-[#D1D5DB] p-4">
                    <h3 className="font-medium text-lg underline">{ticket.subject}</h3>
                    <p className="text-[#6B7280] mt-2">
                        {ticket?.description && parse(ticket?.description)}
                    </p>
                </div>
                <div>
                    <div className="w-[100%] border-[3px] rounded-lg border-[#D1D5DB] p-4">
                        <h3 className="font-medium underline">Image Attached</h3>
                        <div>
                            <div className="flex justify-between mt-2 items-center gap-2 py-2">
                                <div className="flex items-center gap-2">
                                    <MdAttachment size={22} />
                                    <p className="text-[#6B7280] font-medium">{ticket.imageUrl}</p>
                                </div>
                                <div>
                                    <button onClick={() => setIsImgView(true)} className="border-2 rounded px-6 py-1">View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-[3px] rounded-lg border-[#D1D5DB] mt-4 p-4">
                        <h4 className="font-medium underline">Reason</h4>
                        <p className="text-[#6B7280] mt-2">
                            {ticket.reason ? parse(ticket.reason) : "No reason found."}
                        </p>
                    </div>
                </div>
            </div>

            {isReasonModalOpen &&
                <AddReasonModal
                    setState={setIsReasonModalOpen}
                    status={status}
                    getTicketDetail={getTicketDetail}
                    isAdmin={true}
                    ticketId={params?.id}
                />
            }

            {isImgView &&
                <ImageModal
                    setIsImgView={setIsImgView}
                    img={ticket.imageUrl}
                />
            }
        </section >
    )
}

export default MyTicketsDetail