import { useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/axiosInstance';
import loader from '../../assets/rolling.gif';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import avatar from "../../assets/employee.png";
import EmpProjectCard from '../../components/emp-projectCard/EmpProjectCard';
import LimitComp from '../../components/limit-comp/LimitComp';
import { readCookie } from '../../utils/readCookie';
import { Bar } from 'react-chartjs-2';
import Loader from '@/components/loader/Loader';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { TbUserEdit } from 'react-icons/tb';

const Home = () => {
  const salesData = {
    labels: [
      'Apr 01',
      'Apr 02',
      'Apr 03',
      'Apr 04',
      'Apr 05',
      'Apr 06',
      'Apr 07'
    ],
    fontColor: "white",
    datasets: [
      {
        label: "working hours / Month",
        data: [2, 4, 6, 8, 3, 5, 7],
        backgroundColor: ["#4C52C4","#4C52C4","#4C52C4","#4C52C4","#4C52C4","#4C52C4","#FF8C4C"],
        borderColor: ["white"],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    borderRadius: 30, // Adjust this value as needed
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }
};


  const [allProjects, setAllProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const cookies = readCookie("userInfo");
  const userId = cookies?.userId;


  const getAllProjects = async () => {
    try {
      setIsLoading(true);
      setAllProjects([]);
      const { data } = await axiosInstance.get(`/admin/emp-projects/${userId}?page=${page}&limit=${limit}`);
      setIsLoading(false);
      console.log("projects", data);
      setAllProjects(data.data);
      const count = Math.ceil(data?.totalDocs / limit);
      setPageCount(count);
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  const getUserDetails = async () => {
    try {
      const { data } = await axiosInstance.get(`/admin/get-employee-details/${userId}`);
      console.log("user", data);
      setUserInfo(data.employee);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllProjects();
    getUserDetails();
  }, [])

  useEffect(() => {
    getAllProjects();
  }, [page, limit])

  return (
    <div>
      <h2 className="text-3xl font-bold px-2 py-2">Employee Details</h2>
      <div className='flex gap-9 mb-8 mt-3'>
        <div className="flex gap-4 bg-white shadow px-5 py-7 rounded-xl relative">
          <div>
            <img className='w-28 h-28 rounded-full' src={userInfo?.image ? `${import.meta.env.VITE_IMAGE_URL}/${userInfo.image}` : avatar} alt="employee" />
            <p className='mt-2'>Employee ID : PXL003</p>
          </div>
          <div className='w-[3px] h-44 bg-[#D1D5DB] rounded-xl'></div>
          <div>
            <h2 className='text-[22px] font-bold'>{userInfo?.name}</h2>
            <span className='text-sm text-white px-3 py-1 absolute -right-7 -top-3 bg-[#348AC8] rounded-bl-2xl rounded-tr-2xl'>{userInfo?.position}</span>
            <div className='flex gap-5 mt-4'>
              <div className='flex flex-col gap-2'>
                <p className='font-light'><span className='font-semibold'>Email :</span> {userInfo?.email}</p>
                <p className='font-light'><span className='font-semibold'>Phone :</span> {userInfo?.phone}</p>
                <p className='font-light'><span className='font-semibold'>Department :</span> {userInfo?.department}</p>
                <p className='font-light'><span className='font-semibold'>Status :</span> <span className={`px-2 py-[2px] rounded-md ${userInfo.status ? "bg-green-400 text-white" : "bg-red-400"}`}>{userInfo?.status ? "Active" : "InActive"}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <div style={{ background: "conic-gradient(#F47600 80.6deg, #ededed 0deg)" }} className='w-36 h-36 flex justify-center items-center rounded-full'>
            <div className='w-32 h-32 bg-white rounded-full flex flex-col justify-center items-center'>
              <h2 className='text-[#F47600] text-5xl'>5</h2>
              <p className='text-center text-sm font-semibold'>Work <br /> Experience</p>
            </div>
          </div>
          <div style={{ background: "conic-gradient(#65A41F 80.6deg, #ededed 0deg)" }} className='w-36 h-36 flex justify-center items-center rounded-full'>
            <div className='w-32 h-32 bg-white rounded-full flex flex-col justify-center items-center'>
              <h2 className='text-[#65A41F] text-5xl'>14</h2>
              <p className='text-center text-sm font-semibold'>Projects <br /> Completed</p>
            </div>
          </div>
        </div>
        <div className='w-[40%] shadow rounded-xl py-2 px-4'>
          <div className='flex justify-between items-center gap-2'>
            <h2 className='text-lg font-bold'>Working Statistics</h2>
            <select className="border rounded-md">
              <option value="week">Weekly</option>
              <option value="month">Monthly</option>
            </select>
          </div>
          <Bar data={salesData} options={options} />
        </div>
      </div>

      <div className="mt-5">
        <div className="flex gap-2 items-center">
          <h3 className="text-2xl font-bold">Experience Information</h3>
        </div>

        <Table className="mt-3">
          <TableHeader>
            <TableRow>
              <TableHead>Years  of Experience</TableHead>
              <TableHead>Company Name</TableHead>
              <TableHead>From</TableHead>
              <TableHead>To</TableHead>
              <TableHead>Logo</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>5 Years 2 months</TableCell>
              <TableCell>Pixel Wipes</TableCell>
              <TableCell>Jan 2016</TableCell>
              <TableCell>Feb 2021</TableCell>
              <TableCell>
                <img className='w-10 h-10' src={avatar} alt="avatar" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <div className="flex justify-between items-center px-2 mt-3">
        <h2 className="text-3xl font-bold py-2">Projects</h2>
        <div className="flex items-center gap-2">
          <LimitComp
            setLimit={setLimit}
            limit={limit}
          />

          <Link to={"/employee-projects"} className="bg-[#1A56DB] text-white rounded-md py-1 px-3">View all</Link>
        </div>
      </div>
      <Table className="mt-3">
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead>Project Name</TableHead>
            <TableHead>Start Date</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {allProjects?.map((project) => (
            <EmpProjectCard
              key={project?.projectId?._id}
              project={project}
            />
          ))}
        </TableBody>
      </Table>
      {allProjects.length === 0 && isLoading &&
        <Loader />
      }

      {allProjects.length === 0 && !isLoading &&
        <p>No projects found</p>
      }

      {pageCount > 0 &&
        <div className="mt-5">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={(e) => setPage(e.selected + 1)}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>}
    </div>
  )
}

export default Home