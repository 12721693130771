import React, { useEffect, useState } from 'react';
// import logo from '../../assets/logo.png'
import logo from '../../assets/Easesmith logo.png'
import user from '../../assets/user.png'

import classes from './Header.module.css'
import { useDispatch } from 'react-redux';
import { RxExit } from 'react-icons/rx';
import LogoutModal from '../logoutModal/LogoutModal';
import { openCloseBody } from '../../store/slices/bodySlice';
import { readCookie } from '../../utils/readCookie';
import { FaBell } from "react-icons/fa";
import useGetApiReq from '@/hooks/useGetApiReq';
import axios from 'axios';

const Header = () => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const dispatch = useDispatch();

  const cookies = readCookie("userInfo");
  const name = cookies?.name;
  console.log("userinfo",cookies);

  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const [isStaticImg, setIsStaticImg] = useState(true);

  useEffect(() => {
    const handleModalOpen = () => {
      if (isLogoutModal) {
        dispatch(openCloseBody(true));
      }
      else {
        dispatch(openCloseBody(false));
      }
    }
    handleModalOpen()
  }, [isLogoutModal])


  useEffect(() => {
    if (cookies?.userImage) {
      setIsStaticImg(false)
      }
      else{
      setIsStaticImg(true)
    }
  }, [cookies])


  return (
    <>
      <div className={classes.header}>
        <div className={classes.container}>
          <img src={logo} alt='logo' />
          {name && <span className='text-lg font-bold'>Hi, {name}</span>}
          <div className={classes.icon_container}>
            <div className='bg-[#E5E7EB] w-12 h-12 rounded-md flex justify-center items-center'>
              <FaBell className='text-[#348AC8] text-2xl' />
            </div>
            <div className='rounded-md w-12 h-12'>
              <img src={isStaticImg ? user :`${import.meta.env.VITE_IMAGE_URL}/${cookies?.userImage}`} className='rounded-md w-full h-full object-cover' alt="" />
            </div>
            <RxExit onClick={() => setIsLogoutModal(true)} className="text-2xl ml-2 cursor-pointer" />
          </div>
        </div>
      </div>
      {
        isLogoutModal &&
        <LogoutModal
          setState={setIsLogoutModal}
        />
      }
    </>
  )
}

export default Header