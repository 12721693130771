import { useState } from "react";
import { axiosInstance } from "../utils/axiosInstance";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { handleLoading } from "../store/slices/loadingSlice";

const usePatchApiReq = () => {
    const [res, setRes] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const fetchData = async (url, sendData, config = {}) => {
        try {
            setIsLoading(true);
            dispatch(handleLoading(true));
            const response = await axiosInstance.patch(url, sendData, config);
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.message);
                setRes(response);
            }
            console.log("patch api res", response);
        } catch (error) {
            console.log("patch api error =>", error);
            toast.error(error?.response?.data?.message || "An error occurred.");
        } finally {
            dispatch(handleLoading(false));
            setIsLoading(false);
        }
    };

    return { res, isLoading, fetchData };


};

export default usePatchApiReq;