import { MdEdit } from "react-icons/md";
import { FaArrowLeft, FaComments, FaUser } from "react-icons/fa";
import { RiPriceTag2Line } from "react-icons/ri";
import { BsFillOpticalAudioFill } from "react-icons/bs";
import { LuImage } from "react-icons/lu";
import parse from "html-react-parser";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AddReasonModal from "../../components/add-ReasonModal/AddReasonModal";
import { axiosInstance } from "../../utils/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { openCloseBody } from "../../store/slices/bodySlice";
import { useDispatch } from "react-redux";
import ImageModal from "../../components/ImageModal/ImageModal";
import usePatchApiReq from "../../hooks/usePatchApiReq";

const ProjectTicketsDetail = () => {
    const { res, fetchData, isLoading } = usePatchApiReq();

    const [status, setStatus] = useState("")
    const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
    const [isImgView, setIsImgView] = useState(false);
    const [ticket, setTicket] = useState({});

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOnClick = async () => {
        if (status === "") {
            toast.error("Select status");
            return;
        }

        fetchData(`/admin/update-admin-ticket/${params?.ticketId}`, { status });
    }

    const getTicketDetail = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-admin-ticket-by/${params?.ticketId}`);
            //console.log(data);
            setTicket(data?.data);
        } catch (error) {
            //console.log(error);
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        getTicketDetail();
    }, [params.id])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getTicketDetail();
        }
    }, [res])


    useEffect(() => {
        if (status === "decline" || status === "completed") {
            setIsReasonModalOpen(true);
        }
    }, [status])

    useEffect(() => {
        if (!isReasonModalOpen) {
            setStatus("");
        }
        const handleModalOpen = () => {
            if (isReasonModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isReasonModalOpen])


    return (
        <section>
            <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
            <div className="flex w-full justify-between flex-wrap border-b-2 border-gray-200 items-center bg-[#DCEFFE] px-2">
                <h1 className="text-3xl font-bold py-2">Tickets Detail</h1>
                <div>
                    <select value={status} onChange={(e) => setStatus(e.target.value)} className="border-2 border-gray-400 rounded" name="" id="">
                        <option value="">Select Status</option>
                        <option value="in-progress">In Progress</option>
                        <option value="decline">Decline</option>
                        <option value="completed">Completed</option>
                        {/* <option value="created">Created</option> */}
                    </select>
                    <button onClick={handleOnClick} className="bg-black text-white ml-2 text-sm rounded px-2 py-1">Update</button>
                </div>
            </div>
            <div className="flex max-[942px]:flex-col gap-2 mt-5">
                <div className="w-[65%] max-[942px]:w-full">
                    <div className="flex items-center flex-wrap gap-2">
                        <div className="flex gap-2 w-64 p-4 border shadow-sm">
                            <div className={`w-12 h-12 ${ticket.status === "completed" ? "bg-green-400 text-black" : (ticket.status === "in-progress") ? "bg-yellow-400" : (ticket.status === "decline") ? "bg-red-400" : "bg-sky-500"} flex justify-center items-center`}>
                                <BsFillOpticalAudioFill size={20} />
                            </div>
                            <div className="text-sm">
                                <p>Status</p>
                                <div className={`${ticket.status === "completed" ? "bg-green-400 text-black" : (ticket.status === "in-progress") ? "bg-yellow-400" : (ticket.status === "decline") ? "bg-red-500" : "bg-sky-500"} text-center px-2 rounded-full mt-1 font-bold`}>{ticket.status}</div>
                            </div>
                        </div>
                        <div className="flex gap-2 w-64 p-4 border shadow-sm">
                            <div className="w-12 h-12 bg-sky-300 flex justify-center items-center">
                                <FaUser />
                            </div>
                            <div className="text-sm">
                                <p>Created Name</p>
                                <div className="px-1 rounded-full mt-1 font-bold">{ticket?.createdBy?.name}</div>
                            </div>
                        </div>
                        <div className="flex gap-2 w-64 p-4 border shadow-sm">
                            <div className={`w-12 h-12 ${ticket.priority === "low" ? "bg-green-400 text-black" : (ticket.priority === "medium") ? "bg-yellow-400" : "bg-red-300"} flex justify-center items-center`}>
                                <RiPriceTag2Line size={20} />
                            </div>
                            <div className="text-sm">
                                <p>Priority</p>
                                <div className={`${ticket.priority === "low" ? "bg-green-400 text-black" : (ticket.priority === "medium") ? "bg-yellow-400" : "bg-red-500"} text-center px-2 rounded-full mt-1 font-bold`}>{ticket.priority}</div>
                            </div>
                        </div>
                    </div>
                    <div className="border mt-5 p-4">
                        <h3 className="text-red-500 font-bold">{ticket.subject}</h3>
                        <p className="text-sm text-gray-600 mt-4">
                            {ticket?.description && parse(ticket?.description)}
                        </p>
                    </div>
                </div>
                <div className="w-[36%] max-[942px]:w-full p-4 border">
                    <div className="w-[100%] border p-4">
                        <h3 className="text-red-500 font-bold">Image Attached</h3>
                        <div>
                            <div className="flex justify-between items-center flex-wrap gap-2 py-2 border-b">
                                <div className="flex items-center gap-2">
                                    <div className="w-14 h-14 bg-pink-200 rounded-full flex justify-center items-center">
                                        <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMAGE_URL}/${ticket.imageUrl}`} alt="" />
                                    </div>
                                    <p className="text-xs font-bold">{ticket.imageUrl}</p>
                                </div>
                                <div>
                                    <button onClick={() => setIsImgView(true)} className="bg-pink-200 text-sm rounded px-2 py-1">View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className="font-bold mt-4">Reason</h4>
                    <p className="text-sm mt-1">
                        {ticket.reason ? parse(ticket.reason) : "No reason found."}
                    </p>
                </div>
            </div>

            {isReasonModalOpen &&
                <AddReasonModal
                    setState={setIsReasonModalOpen}
                    status={status}
                    getTicketDetail={getTicketDetail}
                    isAdmin={true}
                    ticketId={params?.ticketId}
                />
            }

            {isImgView &&
                <ImageModal
                    setIsImgView={setIsImgView}
                    img={ticket.imageUrl}
                />
            }
        </section >
    )
}

export default ProjectTicketsDetail