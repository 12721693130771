import ReplyModal from "@/components/replyModal/ReplyModal"
import { Button } from "@/components/ui/button"
import useGetApiReq from "@/hooks/useGetApiReq"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { FaArrowLeft, FaDownload, FaUser } from "react-icons/fa6"
import { ImNewTab } from "react-icons/im";

const EmailDetails = ({ setIsEmailDetail, email, folderType }) => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
    const [emailObj, setEmailObj] = useState("")
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    console.log("email", email);
    const getEmails = () => {
        fetchData(`/user/get-single-mail?folderType=${folderType}&seqno=${email?.seqno}`);
    }

    useEffect(() => {
        if (email) {
            getEmails();
        }
    }, [])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setEmailObj(res?.data?.emailBody)
            console.log("email deatail", res);
        }
    }, [res])


    return (
        <>
            {emailObj &&
                <div className="px-4 py-2 email-detail">
                    <div className="sticky top-0 bg-white py-1">
                        <FaArrowLeft className="cursor-pointer" onClick={() => setIsEmailDetail(false)} />
                    </div>
                    <h1 className="font-bold text-2xl mt-4">{emailObj?.headers?.subject && emailObj?.headers?.subject}</h1>
                    <div className="mt-3 flex items-start gap-4">
                        <div className="w-14 h-14 rounded-full bg-[#F4F4F4] flex justify-center items-center">
                            <FaUser className="text-[#CCCCCC] text-4xl" />
                        </div>
                        <div>
                            {!isDetailOpen && <div>From <span className="text-[#00ACFF]">{emailObj?.headers?.from}</span> on {emailObj?.headers?.date && format(new Date(emailObj?.headers?.date), "yyyy-MM-dd hh:mm")}</div>}
                            {isDetailOpen &&
                                <div className="flex flex-col gap-2">
                                    <div className="grid grid-cols-[25%_75%]">
                                        <span className="font-bold text-gray-600">From</span>
                                        <span className="text-[#00ACFF]">{emailObj.headers.from}</span>
                                    </div>
                                    <div className="grid grid-cols-[25%_75%]">
                                        <span className="font-bold text-gray-600">To</span>
                                        <span className="text-[#00ACFF]">{emailObj.headers.to}</span>
                                    </div>
                                    {emailObj?.headers?.cc &&
                                        <div className="grid grid-cols-[25%_75%]">
                                            <span className="font-bold text-gray-600">CC</span>
                                            <span className="text-[#00ACFF]">{emailObj.headers.cc}</span>
                                        </div>}
                                    {emailObj?.headers?.bcc &&
                                        <div className="grid grid-cols-[25%_75%]">
                                            <span className="font-bold text-gray-600">BCC</span>
                                            <span className="text-[#00ACFF]">{emailObj.headers.bcc}</span>
                                        </div>}
                                    <div className="grid grid-cols-[25%_75%]">
                                        <span className="font-bold text-gray-600">Date</span>
                                        <span>{emailObj?.headers?.date && format(new Date(emailObj?.headers?.date), "eee hh:mm")}</span>
                                    </div>
                                </div>
                            }
                            <button onClick={() => setIsDetailOpen(!isDetailOpen)} className="text-[#00ACFF] mt-1">
                                {isDetailOpen ? "Summary" : "Details"}
                            </button>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: emailObj?.body }} className="mt-4 email-body"></div>
                    <div className="flex gap-3 flex-wrap">
                        {emailObj.attachments?.map((attachment, index) => (
                            <div key={index} className="attachment relative mt-2 border-2 rounded-md bg-sky-50">
                                <p className="text-sm px-2 text-gray-600">{attachment.filename}</p>
                                <div className="px-10 py-2 flex flex-col gap-3">
                                    {attachment.contentType.startsWith('image/') && (
                                        <img
                                            src={`data:${attachment.contentType};base64,${attachment.content}`}
                                            alt={attachment.filename}
                                            className="max-w-[250px] object-contain flex mt-3"
                                        />
                                    )}

                                    {attachment.contentType.startsWith('video/') && (
                                        <video
                                            src={`data:${attachment.contentType};base64,${attachment.content}`}
                                            alt={attachment.filename}
                                            controls
                                            className="max-w-[300px] object-contain flex mt-3"
                                        />
                                    )}
                                    {attachment.contentType.startsWith('application/pdf') && (
                                        <iframe
                                            src={`data:${attachment.contentType};base64,${attachment.content}`}
                                            alt={attachment.filename}
                                            className="max-w-[300px] h-52 object-contain flex mt-3"
                                            allowFullScreen={true}
                                        />
                                    )}
                                    <div className="flex justify-between">
                                        <a
                                            className="flex gap-1 items-center"
                                            href={`data:${attachment.contentType};base64,${attachment.content}`}
                                            target="_blank" rel="noreferrer"
                                        >
                                            <ImNewTab />
                                            Open
                                        </a>
                                        <a
                                            className="flex gap-1 items-center"
                                            href={`data:${attachment.contentType};base64,${attachment.content}`}
                                            download={attachment.filename}
                                        >
                                            <FaDownload />
                                            Download
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex justify-end mt-4">
                        <Button onClick={() => setIsReplyModalOpen(true)} type="submit">
                            {/* {isLoading ? <Loader size={25} /> : "Send"} */}
                            Reply
                        </Button>
                    </div>
                    <ReplyModal
                        to={email?.headers?.from}
                        isReplyModalOpen={isReplyModalOpen}
                        setIsReplyModalOpen={setIsReplyModalOpen}
                    />
                </div>}
        </>
    )
}

export default EmailDetails