import useGetApiReq from '@/hooks/useGetApiReq';
import { useEffect, useState } from 'react'
import SubFolder from '../subfolder/SubFolder';
import { FaFolder } from 'react-icons/fa6';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleFolderId } from '@/store/slices/folderSlice';
import { useSelector } from 'react-redux';
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "@/components/ui/context-menu"
import AddSubFolderModal from '../add-subFolderModal/AddSubFolderModal';
import AddDocumentModal from '../add-documentModal/AddDocumentModal';
import { readCookie } from '@/utils/readCookie';

// import { saveDoc } from '@/utils/download';
// import AddSubFolderModal from '../add-subFolderModal/AddSubFolderModal';

const Folder = ({ folder }) => {
    const { res: subFolderRes, fetchData: fetchSubFolderData, isLoading: isSubFolderLoading } = useGetApiReq();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { pathname } = useLocation();

    const [isAddSubFolderModalOpen, setIsAddSubFolderModalOpen] = useState(false);
    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);

    const handleFolderClick = (id) => {
        dispatch(handleFolderId(id));
    }

    const { res: managerRes, fetchData: fetchManagerData, isLoading: isManagerLoading } = useGetApiReq();
    const userInfo = readCookie("userInfo")

    const getProjectManagerStatus = async () => {
        fetchManagerData(`/admin/get-project-access-admin/${params?.pId}`);
    }

    useEffect(() => {
        if (params?.pId) {
            getProjectManagerStatus()
        }
    }, [])

    const navigateUrl = `/${pathname.includes("/documents") ? "documents" : userInfo.userType === "employee" ? `employee-projects/${params?.pId}` : `allProjects/${params?.id}`}/folder`;

    return (
        <>
            <div>
                <div className="cursor-pointer flex justify-center font-semibold">
                    {(managerRes?.data?.data?.employeeId === userInfo?.userId || userInfo.userType === "admin") ?

                        <ContextMenu>
                            <ContextMenuTrigger>
                                <div
                                    onDoubleClick={() => navigate(navigateUrl, { state: { folderId: folder?._id } })}
                                    onClick={() => handleFolderClick(folder?._id)}
                                    className={`flex flex-col gap-1 justify-center text-center`}
                                >
                                    <FaFolder className={`text-yellow-400 text-8xl `} />
                                    {folder?.name}
                                </div>
                            </ContextMenuTrigger>
                            <ContextMenuContent>
                                <ContextMenuItem className="cursor-pointer" onClick={() => setIsAddSubFolderModalOpen(true)}>Add Folder</ContextMenuItem>
                                <ContextMenuItem className="cursor-pointer" onClick={() => setIsAddDocumentModalOpen(true)}>Add Document</ContextMenuItem>
                            </ContextMenuContent>
                        </ContextMenu>
                        :
                        <div
                            onDoubleClick={() => navigate(navigateUrl, { state: { folderId: folder?._id } })}
                            onClick={() => handleFolderClick(folder?._id)}
                            className={`flex flex-col gap-1 justify-center text-center`}
                        >
                            <FaFolder className={`text-yellow-400 text-8xl `} />
                            {folder?.name}
                        </div>
                    }
                </div>
            </div>

            {isAddSubFolderModalOpen &&
                <AddSubFolderModal
                    isAddSubFolderModalOpen={isAddSubFolderModalOpen}
                    setIsAddSubFolderModalOpen={setIsAddSubFolderModalOpen}
                    getSubFolders={() => { }}
                    id={folder._id}
                />
            }

            {isAddDocumentModalOpen &&
                <AddDocumentModal
                    setState={setIsAddDocumentModalOpen}
                    isAddDocumentModalOpen={isAddDocumentModalOpen}
                    getAllDocuments={() => { }}
                    folderId={folder._id}
                    projectId={params?.id || params?.pId}
                />
            }
        </>
    )
}

export default Folder