import { createSlice } from "@reduxjs/toolkit";

const initialState = {};


const bodySlice = createSlice({
  name: "openCloseBody",
  initialState,
  reducers: {
    openCloseBody: (state,action) => {
        if (action.payload) {
            document.querySelector("body").classList.add("overflow-hidden");
        }
        else{
            document.querySelector("body").classList.remove("overflow-hidden");
        }
    }
  },
});

export const { openCloseBody } = bodySlice.actions;
export default bodySlice.reducer;