import { useEffect, useState } from 'react';
import classes from './AddCandidateModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import { validate } from '../../utils/validate';
import { format } from 'date-fns';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';
import usePostApiReq from '../../hooks/usePostApiReq';
import usePatchApiReq from '../../hooks/usePatchApiReq';

const AddCandidateModal = ({ setState, getAllCandidate, candidate = "" }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateRes, fetchData: updateFetchData, isLoading: updateIsLoading } = usePatchApiReq();

    const [candidateInfo, setCandidateInfo] = useState({
        name: candidate?.name || "",
        contact: candidate?.contact || "",
        email: candidate?.email || "",
        resume: candidate?.pdf || "",
        remark: candidate?.remarks || "",
        interviewedOn: candidate?.interviewedOn && format(new Date(candidate?.interviewedOn), "yyyy-MM-dd") || "",
        position: candidate?.position || "",
        previewImage: "",
    });
    const [validateState, setValidateState] = useState([]);
    const dispatch = useDispatch();


    const handleOnChange = (e) => {
        let { name, value } = e.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        setCandidateInfo({ ...candidateInfo, [name]: value });
    }

    const getImage = (event) => {
        event.preventDefault();
        // getting the image
        const uploadedImage = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setCandidateInfo({
                    ...candidateInfo,
                    previewImage: this.result,
                    resume: uploadedImage,
                });
            });
        }
    };

    const handleModalOpen = () => {
        setState(() => false);
        dispatch(openCloseBody(false));
    }


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        let res;
        if (candidate) {
            res = validate({
                "Email": candidateInfo.email,
                "Interviewed On": candidateInfo.interviewedOn,
                "Name": candidateInfo.name,
                "Contact": candidateInfo.contact,
                "Position": candidateInfo.position,
                "Remark": candidateInfo.remark,
            });
        }
        else {
            res = validate({
                "Email": candidateInfo.email,
                "Interviewed On": candidateInfo.interviewedOn,
                "Name": candidateInfo.name,
                "Contact": candidateInfo.contact,
                "Position": candidateInfo.position,
                "Remark": candidateInfo.remark,
                "Resume": candidateInfo.resume,
            });
        }

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!candidateInfo.email
            || !candidateInfo.interviewedOn
            || !candidateInfo.name
            || !candidateInfo.contact
            || !candidateInfo.position
            || !candidateInfo.remark
            || (!candidate && !candidateInfo.resume)
        ) {
            return;
        }
        const formdata = new FormData();
        formdata.append("contact", candidateInfo.contact);
        formdata.append("email", candidateInfo.email);
        formdata.append("interviewedOn", candidateInfo.interviewedOn);
        formdata.append("name", candidateInfo.name);
        formdata.append("position", candidateInfo.position);
        formdata.append("remarks", candidateInfo.remark);
        if (!candidate) {
            formdata.append("pdf", candidateInfo.resume);
        }

        if (candidate) {
            updateFetchData(`/admin/update-resume/${candidate._id}`, { ...candidateInfo }, { withCredentials: true });
        }
        else {
            fetchData("/admin/add-resume", formdata);
        }
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllCandidate();
            handleModalOpen();
        }
    }, [res])

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            getAllCandidate();
            handleModalOpen();
        }
    }, [updateRes])



    return (
        <>
            <div className={classes.add_document_wrapper}>
                <div className={classes.add_document_box}>
                    <div className={classes.heading_container}>
                        <h3>{candidate ? "Update" : "Add"} Resume</h3>
                        <div className={classes.d_flex}>
                            <RxCross2 onClick={handleModalOpen} cursor={"pointer"} size={26} />
                        </div>
                    </div>
                    <form onSubmit={handleOnSubmit} className={classes.form}>
                        <div className={classes.container}>
                            <label htmlFor="name">Name</label>
                            <input
                                value={candidateInfo.name}
                                onChange={handleOnChange}
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Enter name"
                            />
                        </div>
                        <div className={classes.container}>
                            <label htmlFor="phone">Phone</label>
                            <input
                                value={candidateInfo.contact}
                                onChange={handleOnChange}
                                type="text"
                                id="contact"
                                name="contact"
                                placeholder="Enter phone"
                            />
                        </div>
                        <div className={classes.container}>
                            <label htmlFor="email">Email</label>
                            <input
                                value={candidateInfo.email}
                                onChange={handleOnChange}
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter email"
                            />
                        </div>
                        {!candidate && <div className={classes.container}>
                            <label htmlFor="resume">Resume</label>
                            {candidateInfo.previewImage &&
                                <iframe src={candidateInfo.previewImage} style={{ width: "100%", height: "60vh", border: "none" }}></iframe>
                            }
                            <input onChange={getImage} type="file" id="resume" name="resume" />
                        </div>}
                        <div className={classes.container}>
                            <label htmlFor="remark">Remark</label>
                            <input
                                value={candidateInfo.remark}
                                onChange={handleOnChange}
                                type="text"
                                id="remark"
                                name="remark"
                                placeholder="Enter remark"
                            />
                        </div>
                        <div className={classes.container}>
                            <label htmlFor="interviewDate">Interview Date</label>
                            <input
                                value={candidateInfo.interviewedOn}
                                onChange={handleOnChange}
                                type="date"
                                id="interviewedOn"
                                name="interviewedOn"
                                placeholder="Enter interviewed on"
                            />
                        </div>
                        <div className={classes.container}>
                            <label htmlFor="position">Position</label>
                            <select value={candidateInfo.position} onChange={handleOnChange} id="position" name="position">
                                <option value="">Select</option>
                                <option value="react-developer">React Developer</option>
                                <option value="backend-developer">Backend Developer</option>
                                <option value="devops">Devops</option>
                            </select>
                        </div>

                        {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                        <div className={classes.button_wrapper}>
                            <button className={classes.button}>{candidate ? "Update" : "Add"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddCandidateModal