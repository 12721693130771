import useGetApiReq from '@/hooks/useGetApiReq';
import React, { useEffect, useState } from 'react'
import Doc from '../document/Doc';
import { useLocation } from 'react-router-dom';

const SubSubFolderDocs = () => {
    const { res: subFolderRes, fetchData: fetchSubFolderData, isLoading: isSubFolderLoading } = useGetApiReq();

    const [subFolderDocs, setSubFolderDocs] = useState([]);
    const { state } = useLocation();

    const getSubFolders = () => {
        fetchSubFolderData(`/admin/get-folder/${state?.folderId}?flag=sub-folder`);
    }

    useEffect(() => {
        getSubFolders();
    }, [])


    useEffect(() => {
        if (subFolderRes?.status === 200 || subFolderRes?.status === 201) {
            console.log("sub 'sub' FolderRes docs", subFolderRes);
            setSubFolderDocs(subFolderRes?.data?.docs);
        }
    }, [subFolderRes])

    return (
        <>
            {subFolderDocs.length === 0 &&
                <p>No docs found</p>
            }
            <div className='grid grid-cols-7 gap-4 mt-5'>
                {subFolderDocs?.map((doc) => (
                    <Doc
                        key={doc?._id}
                        doc={doc}
                    />
                ))}
            </div>
        </>
    )
}

export default SubSubFolderDocs