import classes from './AppRoutes.module.css'

import Sidebar from '../components/sidebar/Sidebar'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/header/Header'
import { useEffect, useState } from 'react'
import { readCookie } from '../utils/readCookie'

const AppRoutes = () => {
    const { pathname } = useLocation();
    const [isReturn, setIsReturn] = useState(true);

    let cookies = readCookie("userInfo")
    const permissions = cookies?.perm;
    const navigate = useNavigate();
    const value = pathname.split("/")[1];

    useEffect(() => {
        if (pathname.includes("/") && value && permissions && permissions[value] !== "none") {
            setIsReturn(false);
        } else {
            navigate(-1);
        }
    }, [pathname, permissions]);



    if (!isReturn) {
        return (
            <section>
                <section className={classes.section}>
                    <Sidebar />
                    <main className={classes.main}>
                        <Header />
                        <div className='p-8 w-full'>
                            <Outlet />
                        </div>
                    </main>
                </section>
            </section>
        )
    }

}

export default AppRoutes