import { useNavigate } from 'react-router-dom';
import classes from './ProjectCard.module.css';
import { format } from 'date-fns';
import avatar from "../../assets/planning.png"
import { TableCell, TableRow } from '../ui/table';

const EmpProjectCard = ({ project, index }) => {
    const navigate = useNavigate();
    console.log("project", project);

    return (
        <>
            <TableRow>
                <TableCell>
                    <div onClick={() => navigate(`/employee-projects/${project?.projectId?._id}`, { state: project })} className='w-14 h-14 cursor-pointer rounded-full p-[2px] overflow-hidden border-2'>
                        <img src={project?.logo ? `${import.meta.env.VITE_IMAGE_URL}/${project.logo}` : project?.projectId?.logo ? `${import.meta.env.VITE_IMAGE_URL}/${project?.projectId?.logo}` : avatar} className='w-full h-full' alt="avatar" />
                    </div>
                </TableCell>
                <TableCell className="cursor-pointer" onClick={() => navigate(`/employee-projects/${project?.projectId?._id}`, { state: project })}>{project?.projectId?.projectName}</TableCell>
                <TableCell>{format(new Date(project?.projectId?.startDate ? project?.projectId?.startDate : project?.startDate), "dd-MM-yyyy")}</TableCell>
                <TableCell><span className={`${project?.projectId?.status === "ongoing" ? "bg-[#FFD700] text-white" : project?.projectId?.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-3 py-2 text-center font-semibold rounded-tl-xl rounded-br-xl`}>{project?.projectId?.status}</span></TableCell>
            </TableRow>
        </>
    )
}

export default EmpProjectCard