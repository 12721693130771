import { useEffect, useState } from 'react';
import classes from './AddExpenseModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { format } from 'date-fns';
import { validate } from '../../utils/validate';
import usePostApiReq from '../../hooks/usePostApiReq';
import usePatchApiReq from '../../hooks/usePatchApiReq';

const AddExpenseModal = ({ setState, projectId = "", getAllExpenses, expense }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateRes, fetchData: updateFetchData, isLoading: updateIsLoading } = usePatchApiReq();
    
    console.log("projectId", projectId);
    const [description, setDescription] = useState(expense?.description || "")
    const [expenseInfo, setExpenseInfo] = useState({
        item: expense?.item || "",
        date: expense?.date && format(new Date(expense?.date), "yyyy-MM-dd") || "",
        from: expense?.from || "",
        expenseId: expense?.expenseId || "",
        cost: expense?.cost || "",
        status: expense?.status || "",
        projectId: projectId || "",
    });
    const [validateState, setValidateState] = useState([]);



    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setExpenseInfo({ ...expenseInfo, [name]: value });
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "Item": expenseInfo?.item,
            "Date": expenseInfo?.date,
            "From": expenseInfo?.from,
            "Expense Id": expenseInfo?.expenseId,
            "Cost": expenseInfo?.cost,
            "Status": expenseInfo?.status,
            "Description": description,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!expenseInfo.item
            || !expenseInfo.date
            || !expenseInfo.from
            || !expenseInfo.status
            || !expenseInfo.cost
            || !expenseInfo.expenseId
            || !description
        ) {
            return;
        }
        if (expense) {
            updateFetchData(`/admin/update-expense/${expense?._id}`, { ...expenseInfo, description });
        }
        else {
            fetchData("/admin/create-expense", { ...expenseInfo, description });
        }

    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getAllExpenses();
            setState(false);
            setState(false);
        }
    }, [res])

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            getAllExpenses();
            setState(false);
            setState(false);
        }
    }, [updateRes])

    return (
        <div className={classes.add_document_wrapper}>
            <div className={classes.add_document_box}>
                <div className={classes.heading_container}>
                    <h3>{expense ? "Update" : "Add"} Expense</h3>
                    <div className={classes.d_flex}>
                        <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                    </div>
                </div>
                <form onSubmit={handleOnSubmit} className={classes.form}>
                    <div className={classes.container}>
                        <label htmlFor="expenseId">Expense Id</label>
                        <input
                            value={expenseInfo.expenseId}
                            onChange={handleOnChange}
                            type="number"
                            id="expenseId"
                            name="expenseId"
                            placeholder="Enter expense Id"
                        />
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="cost">Expense Cost</label>
                        <input
                            value={expenseInfo.cost}
                            onChange={handleOnChange}
                            type="number"
                            id="cost"
                            name="cost"
                            placeholder="Enter cost"
                        />
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="item">Item</label>
                        <input
                            value={expenseInfo.item}
                            onChange={handleOnChange}
                            type="text"
                            id="item"
                            name="item"
                            placeholder="Enter item"
                        />
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="date">Date</label>
                        <input
                            value={expenseInfo.date}
                            onChange={handleOnChange}
                            type="date"
                            id="date"
                            name="date"
                            placeholder="Enter date"
                        />
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="from">from</label>
                        <input
                            value={expenseInfo.from}
                            onChange={handleOnChange}
                            type="text"
                            id="from"
                            name="from"
                            placeholder="Enter from"
                        />
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="description">Description</label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription} />
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="status">Status</label>
                        <select value={expenseInfo.status} onChange={handleOnChange} id="status" name="status">
                            <option value="">Select</option>
                            <option value="planned">Planned</option>
                            <option value="in-progress">In Progress</option>
                            <option value="completed">Completed</option>
                        </select>
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>{expense ? "Update" : "Create"}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddExpenseModal