import classes from './ResumeModal.module.css';

import { FaXmark } from 'react-icons/fa6';

import html2PDF from 'jspdf-html2canvas';

const ResumeModal = ({ setIsResumeViewModalOpen, resume }) => {

    return (
        <div className={classes.container}>
            <div className={classes.invoice_box_wrapper}>
                <FaXmark onClick={() => setIsResumeViewModalOpen(false)} className={classes.icon} />
                <div className={classes.invoice_box} id='resume_box'>
                    <iframe src={`${import.meta.env.VITE_PDF_URL}/${resume}`} style={{ width: "100%", height: "80vh", border: "none" }}></iframe>
                </div>
            </div>
        </div>
    )
}

export default ResumeModal