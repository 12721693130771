import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  folderId: "1"
};


const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    handleFolderId: (state, action) => {
      state.folderId = action.payload
    }
  },
});

export const { handleFolderId } = folderSlice.actions;
export default folderSlice.reducer;