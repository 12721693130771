import { useEffect, useState } from 'react';
import classes from './AddReasonModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { validate } from '../../utils/validate';
import usePatchApiReq from '../../hooks/usePatchApiReq';

const AddReasonModal = ({ setState, status, getTicketDetail, isAdmin = false, ticketId }) => {
    const { res, fetchData, isLoading } = usePatchApiReq();

    const [reason, setReason] = useState("");
    const [validateState, setValidateState] = useState([]);

    const params = useParams();

    const url = isAdmin ? `/admin/update-admin-ticket/${ticketId}` : `/admin/update-admin-ticket/${ticketId}`

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const res = validate({
            "Reason": reason
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }

        if (!reason) {
            return;
        }

        fetchData(url, { status, reason });

    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getTicketDetail();
            setState(false);
        }
    }, [res])


    return (
        <div className={classes.add_document_wrapper}>
            <div className={classes.add_document_box}>
                <div className={classes.heading_container}>
                    <h3>Add Reason</h3>
                    <div className={classes.d_flex}>
                        <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />
                    </div>
                </div>
                <form onSubmit={handleOnSubmit} className={classes.form}>
                    <div className={classes.container}>
                        <label htmlFor="status">Status : {status}</label>
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="description">Reason</label>
                        <ReactQuill theme="snow" value={reason} onChange={setReason} />
                    </div>
                    {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                    <div className={classes.button_wrapper}>
                        <button className={classes.button}>Update</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddReasonModal