import classes from './DeleteModal.module.css'

const DeleteModal = ({ setState, handleDelete,text="Are you sure you want to delete it ?" }) => {
    return (
        <div className={classes.modal_wrapper}>
            <div className={classes.modal}>
                <p>{text}</p>
                <div className={classes.button_wrapper}>
                    <button onClick={()=> {
                        setState(false)
                        handleDelete()
                    }} className={classes.button}>Yes</button>
                    <button onClick={() => setState(false)} className={classes.button}>No</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal