import { CgSpinner } from 'react-icons/cg';

const Loader = ({ size = 40 }) => {
    return (
        <div className="flex w-full justify-center items-center">
            <CgSpinner size={size} className="mt-1 animate-spin" />
        </div>
    )
}

export default Loader