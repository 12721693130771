import React, { useState } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import { format } from 'date-fns';

import classes from '../../pages/profile/Profile.module.css'

import InvoiceModal from '../invoiceModal/InvoiceModal';

import { BiSolidTrashAlt } from 'react-icons/bi';
import { FaEye } from 'react-icons/fa6';
import DeleteModal from '../deleteModal/DeleteModal';
import toast from 'react-hot-toast';
import { TableCell, TableRow } from '../ui/table';

const InvoiceItem = ({ state, clientInvoice, getClientAllInvoices }) => {
    const [isInvoicePreview, setIsInvoicePreview] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);


    const handleDeleteInvoice = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-invoice/${clientInvoice._id}`);
            toast.success("Invoice deleted successfully");
            getClientAllInvoices();
            setIsDeleteModal(false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <TableRow>
                <TableCell># {clientInvoice.invoiceNumber}</TableCell>
                <TableCell>_</TableCell>
                <TableCell>{format(new Date(clientInvoice.date), 'dd-MM-yyyy')}</TableCell>
                <TableCell>_</TableCell>
                <TableCell>₹ {clientInvoice.total}</TableCell>
                <TableCell>
                    <div className="flex gap-3">
                        <FaEye onClick={() => setIsInvoicePreview(true)} cursor={"pointer"} size={18} color="black" />
                        <BiSolidTrashAlt onClick={() => setIsDeleteModal(true)} cursor={"pointer"} size={18} color="red" />
                    </div>
                </TableCell>
            </TableRow>
           
            {isInvoicePreview &&
                <InvoiceModal
                    setIsInvoicePreview={setIsInvoicePreview}
                    services={clientInvoice.services}
                    subtotal={clientInvoice.subTotal}
                    taxRate={clientInvoice.taxRate}
                    total={clientInvoice.total}
                    invoiceNumber={clientInvoice.invoiceNumber}
                    invoiceDate={clientInvoice.date}
                    state={state}
                    Terms={clientInvoice?.Terms}
                />}

            {isDeleteModal &&
                <DeleteModal
                    setState={setIsDeleteModal}
                    handleDelete={handleDeleteInvoice}
                />
            }
        </>
    )
}

export default InvoiceItem