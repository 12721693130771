import React from 'react'

const ModuleFilter = ({handleDateTypeChange, handleStatusChange, handleDateChange, handleReset,status,date,dateType }) => {
    return (
        <div className='flex justify-end gap-2'>
            <select onChange={handleStatusChange} value={status} className='border-2 px-2 py-2 rounded-tl-xl rounded-br-xl' name="" id="">
                <option value="">Select Status</option>
                <option value="in-progress">In Progress</option>
                <option value="needs-review">Needs Review</option>
                <option value="completed">Completed</option>
                <option value="upcoming">Upcoming</option>
            </select>

            <select onChange={handleDateTypeChange} value={dateType} className='border-2 px-2 py-2 rounded-tl-xl rounded-br-xl' name="" id="">
                <option value="startDate">Start Date</option>
                <option value="endDate">Completed On</option>
                <option value="createdAt">Created At</option>
                <option value="deadline">Deadline</option>
            </select>   

            <div className='flex gap-2 items-center'>
                <input onChange={handleDateChange} value={date} className='border-2 px-2 py-2 rounded-tl-xl rounded-br-xl' type="date" name="" id="" />
            </div>

            <button onClick={handleReset} className='border-2 px-6 py-2 rounded-tl-xl rounded-br-xl'>Reset</button>
        </div>
    )
}

export default ModuleFilter