import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import classes from './Clients.module.css';
import loader from '../../assets/rolling.gif';

import Client from '../../components/client/Client';

import { FaCirclePlus } from "react-icons/fa6";

import AddClientModal from '../../components/add-clientModal/AddClientModal';
import { useNavigate } from 'react-router-dom';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';

import "../../fc-common.css";
import ReactPaginate from 'react-paginate';
import LimitComp from '../../components/limit-comp/LimitComp';
import Search from '@/components/search/Search';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Loader from '@/components/loader/Loader';

const Clients = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allClients, setAllClients] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [searchInput, setSearchInput] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
    }
    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isModalOpen])

    const getAllClient = async () => {
        try {
            setAllClients([]);
            setIsLoading(true);
            const { data } = await axiosInstance.get(`/admin/all-clients?page=${page}&limit=${limit}`);
            console.log("clients", data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
            setAllClients(data.AllClient);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        if (searchInput) {
            handleClientSearch();
        } else {
            getAllClient()
        }
    }, [page, limit,searchInput])

    const handleClientSearch = async () => {
        try {
            setAllClients([]);
            setIsLoading(true);
            const { data } = await axiosInstance.get(`/admin/client?search=${searchInput}`);
            console.log(data);
            setAllClients(data?.clients);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    // useEffect(() => {
    //     if (searchInput) {
    //         handleClientSearch();
    //     }
    // }, [searchInput])
    console.log("searchInput", searchInput);



    return (
        <section>
            <div className={`${classes.heading_container}`}>
                <h1 className="text-3xl font-bold py-2">Clients</h1>
            </div>
            <div className="flex items-center gap-2 mt-8">
                <button onClick={handleModal} className="px-4 py-2 border-2 flex items-center gap-1 rounded-tl-xl rounded-br-xl">
                    <FaCirclePlus size={18} />
                    Add Client
                </button>
                <select className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl" name="" id="">
                    <option value="all">All</option>
                    <option value="admin">Admin</option>
                </select>
                <select className="px-4 py-2 border-2 rounded-tl-xl rounded-br-xl" name="" id="">
                    <option value="">Filter</option>
                    <option value="upcoming">Upcoming</option>
                    <option value="ongoing">Ongoing</option>
                    <option value="completed">Completed</option>
                </select>
                <Search
                    placeholder="Search Client"
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                />
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />
            </div>

            <Table className="mt-5">
                <TableHeader>
                    <TableRow>
                        <TableHead></TableHead>
                        <TableHead>Client Name</TableHead>
                        <TableHead>Company Name</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allClients?.map((client) => (
                        <Client
                            key={client._id}
                            client={client}
                            getAllClient={getAllClient}
                        />
                    ))}
                </TableBody>
            </Table>
            {allClients?.length === 0 && !isLoading &&
                <p>No client found</p>
            }

            {allClients.length === 0 && isLoading &&
                <Loader />
            }


            {isModalOpen &&
                <AddClientModal
                    handleModal={handleModal}
                    getAllClient={getAllClient}
                    isUpdate={false}
                />
            }

            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}

        </section>
    )
}

export default Clients