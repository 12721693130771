import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../utils/axiosInstance';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Story from '../../components/story/Story';
import loader from '../../assets/rolling.gif';
import { FaArrowLeft } from 'react-icons/fa';
import Loader from '@/components/loader/Loader';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Task from '@/components/task/Task';
import LimitComp from '@/components/limit-comp/LimitComp';

const EmployeeTasks = () => {
    const [allStories, setAllStories] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [limit, setLimit] = useState(5);

    const params = useParams();
    const { state } = useLocation()
    const navigate = useNavigate();

    const getAllStories = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-emp-stories/${params?.empId}?status`);
            console.log("emp story", data);
            setAllStories(data.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getAllStories();
    }, [])

    return (
        <div>
            <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
            <div className='flex gap-4 items-center'>
                <h3 className='text-3xl font-bold py-2'>Employee Tasks</h3>
                <LimitComp
                    limit={limit}
                    setLimit={setLimit}
                />
            </div>
            <Table className="mt-3">
                <TableHeader>
                    <TableRow>
                        <TableHead>Task Name</TableHead>
                        <TableHead>Start Date</TableHead>
                        <TableHead>Status</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>

                    {allStories.map((task, i) => (
                        <Task
                            key={i}
                            task={task}
                        />
                    ))}
                </TableBody>
            </Table>
            {allStories.length === 0 && isLoading &&
                <Loader />
            }

            {allStories.length === 0 && !isLoading &&
                <p>No tasks found</p>
            }
        </div>
    )
}

export default EmployeeTasks