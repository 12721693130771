import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import classes from './EmployeeProfile.module.css';
import { MdEmail, MdOutlineEmail } from 'react-icons/md';
import { FaArrowLeft, FaPhone } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import { useState } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import Story from '../../components/story/Story';
import EmpProjectCard from '../../components/emp-projectCard/EmpProjectCard';

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { format } from 'date-fns';
import avatar from "../../assets/employee.png"

import FullCalendar from '@fullcalendar/react';
import AttendanceInfoModal from '../../components/attendance-infoModal/AttendanceInfoModal';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';

import "../../fc-common.css";
import { validate } from '../../utils/validate';
import ReactPaginate from 'react-paginate';
import usePostApiReq from '../../hooks/usePostApiReq';
import UpdatePersonalInfoModal from '../../components/update-PersonalInfoModal/UpdatePersonalInfoModal';
import AddEmployeeModal from '../../components/add-employeeModal/AddEmployeeModal';
import DeleteModal from '../../components/deleteModal/DeleteModal';
import { BiSolidEdit, BiSolidTrashAlt } from 'react-icons/bi';
import parse from "html-react-parser";
import { getISTDate } from '../../utils/getISTDate';
import LimitComp from '../../components/limit-comp/LimitComp';
import usePatchApiReq from '../../hooks/usePatchApiReq';
import Loader from '@/components/loader/Loader';
import { Bar } from 'react-chartjs-2';
import { FiPhone } from "react-icons/fi";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import Task from '@/components/task/Task';
import { TbUserEdit } from "react-icons/tb";
import { TiTickOutline } from 'react-icons/ti';
import { FaEye, FaEyeSlash, FaXmark } from 'react-icons/fa6';
import UpdateEmailPasswordModal from '@/components/update-emailPasswordModal/UpdateEmailPasswordModal';
import useGetApiReq from '@/hooks/useGetApiReq';
import AddExperience from '@/components/add-experience/AddExperience';
import ResumeModal from '@/components/resumeModal/ResumeModal';

const EmployeeProfile = () => {
    const { res, fetchData, isLoading: loading } = usePatchApiReq();

    const [isUpdatePasswordModal, setIsUpdatePasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [allStories, setAllStories] = useState([]);

    const [allProjects, setAllProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isStoryLoading, setIsStoryLoading] = useState(true);
    const [validateState, setValidateState] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [isResumeViewModalOpen, setIsResumeViewModalOpen] = useState(false);

    const [isPersonalInfoUpdateModalOpen, setIsPersonalInfoUpdateModalOpen] = useState(false);
    const [noOfAbsents, setNoOfAbsents] = useState(0);
    const [noOfPresents, setNoOfPresents] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [state, setState] = useState("");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isEmailPasswordUpdateModalOpen, setIsEmailPasswordUpdateModalOpen] = useState(false)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    // const { state } = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const salesData = {
        labels: [
            'Apr 01',
            'Apr 02',
            'Apr 03',
            'Apr 04',
            'Apr 05',
            'Apr 06',
            'Apr 07'
        ],
        fontColor: "white",
        datasets: [
            {
                label: "working hours / Month",
                data: [2, 4, 6, 8, 3, 5, 7],
                backgroundColor: ["#4C52C4", "#4C52C4", "#4C52C4", "#4C52C4", "#4C52C4", "#4C52C4", "#FF8C4C"],
                borderColor: ["white"],
                borderWidth: 2,
            },
        ],
    };

    const options = {
        borderRadius: 30, // Adjust this value as needed
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };

    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const getEmpDetails = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-employee-profile/${params?.empId}`);
            console.log("emp details", data);
            setState(data.emp);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getEmpDetails();
    }, [])

    const handleDelete = async () => {
        try {
            const { data } = await axiosInstance.delete(`/admin/delete-employee/${state?._id}`);
            toast.success("Employee deleted successfully");
            setIsDeleteModal(false);
            navigate("/employees");
        } catch (error) {
            console.log(error);
        }
    }


    const getAllStories = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/get-emp-stories/${params?.empId}?status=ongoing`);
            console.log("emp story", data);
            setAllStories(data.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsStoryLoading(false);
        }
    }


    useEffect(() => {
        getAllStories();
    }, [])

    const handleUpdatePassword = async (e) => {
        e.preventDefault();

        const res = validate({
            "Password": newPassword,
        });

        const validateArr = [];
        for (const key in res) {
            if (!res[key].status) {
                validateArr.push(key);
                setValidateState(validateArr);
            }
            else {
                const temp = validateArr.filter((item) => item !== key);
                setValidateState(temp);
            }
        }
        if (!newPassword) {
            return null;
        }

        fetchData(`admin/change-employee-password/${state?._id}`, { newPassword });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsUpdatePasswordModal(false);
        }
    }, [res])

    const getAllProjects = async () => {
        try {
            setAllProjects([]);
            const { data } = await axiosInstance.get(`/admin/get-emp-project/${params?.empId}?page=${page}&limit=${limit}`);
            setIsLoading(false);
            console.log('allprojects', data)
            setAllProjects(data.data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        getAllProjects();
    }, [page, limit])

    useEffect(() => {
        const handleModalOpen = () => {
            if (isUpdatePasswordModal) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isUpdatePasswordModal])

    const [allEvents, setAllEvents] = useState([])
    const [event, setEvent] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateClick = (arg) => {
        const data = allEvents.find((event) => {
            const date = format(new Date(event.date), "dd-MM-yyyy");
            const eventDate = format(new Date(arg.dateStr), "dd-MM-yyyy");

            return date === eventDate
        })
        if (data) {
            setEvent(data);
            setModalIsOpen(true);
        }
    };

    const handleDatesSet = () => {
        const currentDate = new Date();
        setSelectedDate(() => currentDate);
    };

    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen || isDeleteModal) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isModalOpen, isDeleteModal])


    const handleMonthChange = async (newDate) => {
        const month = format(new Date(newDate), "MM");
        const year = format(new Date(newDate), "yyyy");

        try {
            const { data } = await axiosInstance.get(`/admin/get-user-atd/${params?.empId}?month=${month}&year=${year}`);
            console.log("attendance admin site", data);
            setNoOfAbsents(data.no_of_absents);
            setNoOfPresents(data.no_of_presents);
            const Data = data?.attendance?.att?.map((singleAttendance) => {
                if (singleAttendance.punchIn && singleAttendance.punchOut) {
                    return { title: "Present", date: singleAttendance?.punchIn, flag: singleAttendance.flag, punchIn: singleAttendance.punchIn, punchOut: singleAttendance.punchOut, breaks: singleAttendance.breaks }
                }
                else {
                    return { title: "Absent", date: singleAttendance?.punchIn, flag: singleAttendance.flag, punchIn: singleAttendance.punchIn, punchOut: singleAttendance.punchOut, breaks: singleAttendance.breaks }
                }
            })
            setAllEvents(Data);
        } catch (error) {
            console.log("error", error);
        }
    }


    const calendarRef = useRef(null);

    const handlePrev = () => {
        const calendar = calendarRef.current.getApi();

        const currentDate = selectedDate;

        const prevMonth = new Date(currentDate);
        prevMonth.setMonth(currentDate.getMonth() - 1);

        calendar.gotoDate(format(new Date(prevMonth), "yyyy-MM-dd"));

        setSelectedDate(() => prevMonth);
    };

    const handleNext = () => {
        const calendar = calendarRef.current.getApi();

        const currentDate = selectedDate;

        const nextMonth = new Date(currentDate);
        nextMonth.setMonth(currentDate.getMonth() + 1);

        calendar.gotoDate(format(new Date(nextMonth), "yyyy-MM-dd"));

        setSelectedDate(() => nextMonth);
    };

    console.log("state", state);

    useEffect(() => {
        handleMonthChange(selectedDate);
    }, [selectedDate])


    const customButtons = {
        Prev: {
            text: 'Prev',
            click: handlePrev,
        },
        Next: {
            text: 'Next',
            click: handleNext,
        },
    };

    const { res: experienceRes, fetchData: fetchExperienceData, isLoading: isExperienceLoading } = useGetApiReq();
    const [allExperiences, setAllExperiences] = useState([]);

    const getAllExperience = async () => {
        fetchExperienceData(`/admin/get-work-experience/${params.empId}`);
    }

    useEffect(() => {
        getAllExperience();
    }, [])


    useEffect(() => {
        if (experienceRes?.status === 200 || experienceRes?.status === 201) {
            console.log("experienceRes", experienceRes);
            setAllExperiences(experienceRes.data.workExperience)
        }
    }, [experienceRes])

    return (
        <>
            <section>
                <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
                <h1 className="text-3xl font-bold py-2">Employee Profile</h1>

                <div className='flex gap-20 mb-8 mt-4 h-72'>
                    <div className="flex gap-5 bg-white shadow px-4 py-7 rounded-xl relative">
                        <div className='flex flex-col items-center'>
                            <img className='w-28 h-28 rounded-full' src={state?.image ? `${import.meta.env.VITE_IMAGE_URL}/${state.image}` : avatar} alt="employee" />
                            <p className='mt-2'>Employee ID : PXL003</p>
                            <div className="flex gap-3 justify-center mt-3">
                                <BiSolidEdit onClick={handleModal} cursor={"pointer"} size={24} />
                                <BiSolidTrashAlt onClick={() => setIsDeleteModal(true)} cursor={"pointer"} size={22} color="red" />
                            </div>
                        </div>
                        <div className='w-[3px] h-32 bg-[#D1D5DB] rounded-xl'></div>
                        <div>
                            <h2 className='text-[22px] font-bold'>{state?.name}</h2>
                            <span className='text-sm text-white px-3 py-1 absolute -right-7 -top-3 bg-[#348AC8] rounded-bl-2xl rounded-tr-2xl'>{state?.position}</span>
                            <div className='mt-4'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex gap-2 items-center'>
                                        <MdOutlineEmail />
                                        {state?.email}
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <FiPhone />
                                        {state?.phone}
                                    </div>
                                </div>
                                <button onClick={() => setIsResumeViewModalOpen(true)} className="px-2 py-1 mt-4 border-2 rounded-md mr-2">View Resume</button>
                                <button onClick={() => setIsUpdatePasswordModal(true)} className="px-2 py-1 mt-4 border-2 rounded-md">Update Password</button>
                                <button onClick={() => setIsEmailPasswordUpdateModalOpen(true)} className="px-2 py-1 mt-4 border-2 rounded-md">Update Email Password</button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div style={{ background: "conic-gradient(#F47600 80.6deg, #ededed 0deg)" }} className='w-32 h-32 flex justify-center items-center rounded-full'>
                            <div className='w-28 h-28 bg-white rounded-full flex flex-col justify-center items-center'>
                                <h2 className='text-[#F47600] text-5xl'>5</h2>
                                <p className='text-center text-sm font-semibold'>Work <br /> Experience</p>
                            </div>
                        </div>
                        <div style={{ background: "conic-gradient(#65A41F 80.6deg, #ededed 0deg)" }} className='w-32 h-32 flex justify-center items-center rounded-full'>
                            <div className='w-28 h-28 bg-white rounded-full flex flex-col justify-center items-center'>
                                <h2 className='text-[#65A41F] text-5xl'>14</h2>
                                <p className='text-center text-sm font-semibold'>Projects <br /> Completed</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-[40%] shadow py-2 px-4 rounded-xl'>
                        <div className='flex justify-between items-center gap-2'>
                            <h2 className='text-lg font-bold'>Working Statistics</h2>
                            <select className="border rounded-md">
                                <option value="week">Weekly</option>
                                <option value="month">Monthly</option>
                            </select>
                        </div>
                        <Bar data={salesData} options={options} />
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-10 mt-5">
                    <div className="">
                        <div className="flex gap-2 justify-between">
                            <h3 className="text-2xl font-bold">Current  Projects</h3>
                            <LimitComp
                                setLimit={setLimit}
                                limit={limit}
                            />
                        </div>
                        <Table className="mt-3">
                            <TableHeader>
                                <TableRow>
                                    <TableHead></TableHead>
                                    <TableHead>Project Name</TableHead>
                                    <TableHead>Start Date</TableHead>
                                    <TableHead>Status</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {allProjects?.map((project) => (
                                    <EmpProjectCard
                                        key={project?.projectId?._id}
                                        project={project}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                        {allProjects.length === 0 && isLoading &&
                            <Loader />
                        }

                        {allProjects.length === 0 && !isLoading &&
                            <p>No projects found</p>
                        }

                        {pageCount > 0 &&
                            <div className="mt-2 mb-3">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageCount={pageCount}
                                    onPageChange={(e) => setPage(e.selected + 1)}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>}
                    </div>
                    <div className="">
                        <div className="flex gap-2 justify-between">
                            <h3 className="text-2xl font-bold">Current Task</h3>
                            <button onClick={() => navigate(`/employees/${params?.empId}/all-tasks`)} className='bg-[#1A56DB] text-white px-4 py-1 rounded'>View All</button>
                        </div>

                        <Table className="mt-3">
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Task Name</TableHead>
                                    <TableHead>Start Date</TableHead>
                                    <TableHead>Status</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>

                                {allStories.map((task, i) => (
                                    <Task
                                        key={i}
                                        task={task}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                        {allStories.length === 0 && isStoryLoading &&
                            <Loader />
                        }

                        {allStories.length === 0 && !isStoryLoading &&
                            <p>No tasks found</p>
                        }
                    </div>
                </div>

                <div className="mt-5">
                    <div className="flex gap-2 items-center">
                        <h3 className="text-2xl font-bold">Personal Information</h3>
                        <button onClick={() => setIsPersonalInfoUpdateModalOpen(true)} className="px-3 py-1 ml-10 border-2 rounded-md flex gap-2 items-center">
                            Edit
                            <TbUserEdit />
                        </button>
                    </div>

                    <Table className="mt-3">
                        <TableHeader>
                            <TableRow>
                                <TableHead>Date of Birth</TableHead>
                                <TableHead>Job Title</TableHead>
                                <TableHead>Employement Type</TableHead>
                                <TableHead>Department</TableHead>
                                <TableHead>Date of Joining</TableHead>
                                <TableHead>Experience</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell>29/05/1993</TableCell>
                                <TableCell>{state?.position}</TableCell>
                                <TableCell>Fulltime</TableCell>
                                <TableCell>{state?.department}</TableCell>
                                <TableCell>{state?.joiningDate && format(new Date(state?.joiningDate), "dd-MM-yyyy")}</TableCell>
                                <TableCell>05</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>

                <div className="mt-3">
                    <div className="flex gap-2 items-center">
                        <h3 className="text-2xl font-bold">Bank Information</h3>
                        <button className="px-3 py-1 ml-10 border-2 rounded-md flex gap-2 items-center">
                            Edit
                            <TbUserEdit />
                        </button>
                    </div>

                    <Table className="mt-3">
                        <TableHeader>
                            <TableRow>
                                <TableHead>Bank Name</TableHead>
                                <TableHead>Branch</TableHead>
                                <TableHead>IFSC Code</TableHead>
                                <TableHead>Full Name</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell>BOB</TableCell>
                                <TableCell>Dharmapuri</TableCell>
                                <TableCell>BOB1245</TableCell>
                                <TableCell>Vishal Yadav</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>

                <div className="mt-3">
                    <div className="flex gap-2 items-center">
                        <h3 className="text-2xl font-bold">Experience Information</h3>
                        <button onClick={() => setIsAddModalOpen(true)} className="px-3 py-1 ml-10 border-2 rounded-md flex gap-2 items-center">
                            Add
                            <TbUserEdit />
                        </button>
                    </div>
                    {allExperiences.length === 0 &&
                        <p>No experience found</p>
                    }
                    {allExperiences.length > 0 && <Table className="mt-3">
                        <TableHeader>
                            <TableRow>
                                <TableHead>Years  of Experience</TableHead>
                                <TableHead>Company Name</TableHead>
                                <TableHead>From</TableHead>
                                <TableHead>To</TableHead>
                                <TableHead>Position</TableHead>
                                <TableHead>Location</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {allExperiences.map((exp) => (
                                <TableRow key={exp._id}>
                                    <TableCell>{exp.yearsOfExperience}</TableCell>
                                    <TableCell>{exp.companyName}</TableCell>
                                    <TableCell>{exp.fromDate}</TableCell>
                                    <TableCell>{exp.toDate}</TableCell>
                                    <TableCell>{exp.position}</TableCell>
                                    <TableCell>{exp.location}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
                </div>

                <div className='mt-5'>
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={allEvents}
                        height={"70vh"}
                        eventContent={(eventInfo) => {
                            return (
                                <div
                                    className={`w-10 h-10 rounded-full text-white ml-2 mb-2 flex justify-center items-center ${eventInfo.event.title === "Present" && "bg-[#0E9F6E]"} ${eventInfo.event.title === "Absent" && "bg-red-500"}`}
                                >
                                    {eventInfo.event.title === "Present" && <TiTickOutline size={22} />}
                                    {eventInfo.event.title === "Absent" && <FaXmark size={22} />}
                                </div>
                            );
                        }}
                        dateClick={handleDateClick}
                        // datesSet={handleDatesSet}
                        customButtons={customButtons}
                        headerToolbar={{
                            left: 'today Prev Next',
                            center: 'title',
                            right: 'dayGridMonth,dayGridWeek,dayGridDay',
                        }}
                    />

                    {modalIsOpen &&
                        <AttendanceInfoModal
                            setState={setModalIsOpen}
                            event={event}
                        />
                    }
                </div>

                <div className="flex justify-between max-w-lg mx-auto mt-5 gap-4">
                    <p className="text-lg font-semibold">
                        Total Present Days : {noOfPresents}
                    </p>
                    <p className="text-lg font-semibold">
                        Total Absent Days : {noOfAbsents}
                    </p>
                </div>
            </section >

            {isUpdatePasswordModal &&
                <div className={classes.wrapper}>
                    <div className={classes.box}>
                        <div className={classes.heading_container}>
                            <h3>Update Password</h3>
                            <RxCross2 onClick={() => setIsUpdatePasswordModal(false)} cursor={"pointer"} size={26} />
                        </div>
                        <form onSubmit={handleUpdatePassword}>
                            <div className={classes.input_container}>
                                <label htmlFor="newPassword">New Password</label>
                                <input onChange={(e) => setNewPassword(e.target.value)} value={newPassword} className={classes.input} type={isShowPassword ? "text" : "password"} placeholder="Enter New Password" name="newPassword" id="newPassword" />
                                {isShowPassword ?
                                    <FaEyeSlash onClick={() => setIsShowPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                                    : <FaEye onClick={() => setIsShowPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                                }
                            </div>
                            {validateState.length > 0 && <p className="text-red-400 mb-2">{`${validateState.map((value) => value).join(", ")} required.`}</p>}
                            <div className={classes.button_wrapper}>
                                <button className={classes.button}>Update</button>
                            </div>
                        </form>

                    </div>
                </div>
            }

            {
                isModalOpen &&
                <AddEmployeeModal
                    handleModal={handleModal}
                    isUpdate={true}
                    employee={state}
                    getAllEmployee={getEmpDetails}
                />
            }

            {isDeleteModal &&
                <DeleteModal
                    setState={setIsDeleteModal}
                    handleDelete={handleDelete}
                />
            }

            {isPersonalInfoUpdateModalOpen &&
                <UpdatePersonalInfoModal
                    setState={setIsPersonalInfoUpdateModalOpen}
                    info={state.personalInfo}
                    getEmpDetails={getEmpDetails}
                />
            }

            {isEmailPasswordUpdateModalOpen &&
                <UpdateEmailPasswordModal
                    isEmailPasswordUpdateModalOpen={isEmailPasswordUpdateModalOpen}
                    setIsEmailPasswordUpdateModalOpen={setIsEmailPasswordUpdateModalOpen}
                    id={state?._id}
                />
            }

            {isAddModalOpen &&
                <AddExperience
                    isAddModalOpen={isAddModalOpen}
                    setIsAddModalOpen={setIsAddModalOpen}
                    getAllExperience={getAllExperience}
                />
            }

            {isResumeViewModalOpen &&
                <ResumeModal
                    resume={state.resume}
                    setIsResumeViewModalOpen={setIsResumeViewModalOpen}
                />
            }
        </>
    )
}

export default EmployeeProfile



//http://localhost:5173/allProjects/6596a50793f7dbc3c047ddec


// http://localhost:5173/allProjects/65969e4a1a32d1fc7ee8bbaa