import classes from './AllModuleStory.module.css';
import { format } from 'date-fns';

import { MdDelete, MdEdit } from "react-icons/md";
import { axiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import DeleteModal from '../deleteModal/DeleteModal';
import CreateStoryModal from '../create-story-modal/CreateStoryModal';
import StoryInfoModal from '../story-infoModal/StoryInfoModal';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';
import empImg from '../../assets/emp-img.png';
import { FaEye } from 'react-icons/fa';

const AllModuleStory = ({ story, getAllStories, setState = "" }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [statusClass, setStatusClass] = useState("");
    const [isStoryInfoModalOpen, setIsStoryInfoModalOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const handleModalOpen = () => {
            if (isModalOpen || isDeleteModalOpen || isStoryInfoModalOpen || setState) {
                dispatch(openCloseBody(true));
            }
            else {
                dispatch(openCloseBody(false));
            }
        }
        handleModalOpen()
    }, [isModalOpen, isDeleteModalOpen, isStoryInfoModalOpen, setState])

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`/admin/delete-stories/${story._id}`);
            toast.success("Story deleted successfully");
            getAllStories();
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        if (story.status === "in-progress") {
            setStatusClass("yellow");
        }
        else if (story.status === "needs-review") {
            setStatusClass("red");
        }
        else if (story.status === "completed") {
            setStatusClass("green");
        }
        else {
            setStatusClass("blue");
        }
    }, [story.status])


    return (
        <>
            <div
                className={`${classes.task_card} ${classes[statusClass]} w-[315px] h-48 cursor-default`}
            >
                <div className='w-full'>
                    <div className='flex justify-between items-center gap-2'>
                        <span
                            className={`${story.status === "in-progress" ? "bg-[#FFD700] text-white" : story.status === "needs-review" ? "bg-red-500 text-white" : story.status === "completed" ? "bg-[#008000] text-white" : "bg-[#3498DB] text-white"} px-2 py-[1px] rounded-full`}
                        >{story.status}</span>
                        <div className={classes.buttons_wrapper}>
                            <button onClick={() => setIsStoryInfoModalOpen(true)}><FaEye /></button>
                            <button onClick={() => setIsModalOpen(true)}><MdEdit /></button>
                            <button onClick={() => setIsDeleteModalOpen(true)}><MdDelete /></button>
                        </div>
                    </div>
                    <div className={classes.task_card_top}>
                        <h4 className={`line-clamp-1`}>{story.title}</h4>
                    </div>
                    <p className='text-gray-400 my-2 line-clamp-1'>{story?.moduleId?.title}</p>
                    <div className='flex items-center gap-1'>
                        <div className='w-full h-2 bg-gray-300 rounded-md relative overflow-hidden'>
                            <div className='w-full h-2 bg-[#7CD4E8] absolute rounded-md overflow-hidden'></div>
                        </div>
                        <p className='text-[#7CD4E8]'>100%</p>
                    </div>
                    <div className='flex gap-1 items-center mt-2'>
                        <div className='flex items-center'>
                            <img className='-mr-1' src={empImg} alt="empImg" />
                            <img className='-mr-1' src={empImg} alt="empImg" />
                            <img src={empImg} alt="empImg" />
                        </div>
                        <div className='px-2 py-1 ml-4 rounded-md bg-[#8AAFB8] text-[13px] text-white'>{format(new Date(story?.startDate), "MMM dd, yyyy")}</div>
                        {story?.endDate && <div className='px-2 py-1 rounded-md bg-[#8AAFB8] text-[13px] text-white'>{story?.endDate && format(new Date(story?.endDate), "MMM dd, yyyy")}</div>}
                    </div>
                </div>
            </div>

            {isDeleteModalOpen &&
                <DeleteModal
                    setState={setIsDeleteModalOpen}
                    handleDelete={handleDelete}
                />
            }

            {isModalOpen &&
                <CreateStoryModal
                    isUpdate={true}
                    story={story}
                    setState={setIsModalOpen}
                    getAllStories={getAllStories}
                />
            }

            {isStoryInfoModalOpen &&
                <StoryInfoModal
                    setState={setIsStoryInfoModalOpen}
                    story={story}
                />
            }
        </>
    )
}

export default AllModuleStory