import { FaRegStar, FaStar } from 'react-icons/fa'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselComp = ({ children }) => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 520, min: 0 },
            items: 1
        },
        largemobile: {
            breakpoint: { max: 520, min: 0 },
            items: 1
        }
    };

    const CustomDot = ({ onClick, active }) => {
        return (
            <button
                className={`rounded-md w-8 h-3 mx-1 ${active ? 'bg-[#348AC8]' : 'bg-[#D9D9D9]'}`}
                onClick={onClick}
            />
        );
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            customDot={<CustomDot />}
            dotListClass='mt-5 pt-10'
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="0.5s ease-in"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            itemClass="carousel-item-padding-40-px"
        >
            {children}
        </Carousel>
    )
}

export default CarouselComp