import { IoIosArrowForward, IoMdArrowDropdown } from "react-icons/io";
import { FaRegPauseCircle, FaStar, FaRegPlayCircle, FaPlus } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { IoTriangle } from "react-icons/io5";
import { IoMdArrowDropup } from "react-icons/io";

import beachBall from "../../assets/game-icons_beach-ball.png";
import assigned from "../../assets/assigned.png";
import Progress from "../../assets/Progress.png";
import Completed from "../../assets/Completed.png";
import sales from "../../assets/sales.png";
import cost from "../../assets/cost.png";
import revenue from "../../assets/revenue.png";
import profit from "../../assets/profit.png";
import avatar from "../../assets/avatar.jpg";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import { useEffect, useState } from 'react';
import CreateProjectModal from '../../components/create-project-modal/CreateProjectModal';
import AddEmployeeModal from '../../components/add-employeeModal/AddEmployeeModal';
import { openCloseBody } from '../../store/slices/bodySlice';
import { useDispatch } from 'react-redux';
import { axiosInstance } from '../../utils/axiosInstance';
import AddReminderModal from "@/components/add-reminderModal/AddReminderModal";
import useGetApiReq from "@/hooks/useGetApiReq";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
);


const Report = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
    const [totalCount, setTotalCount] = useState({
        Progress: 0,
        all: 0,
        assigned: 0,
        completed: 0
    })

    const [isAddReminderModalOpen, setIsAddReminderModalOpen] = useState(false);

    const events = [
        { title: 'Meeting', start: new Date(), end: new Date("2024-04-24") },
    ]

    const dispatch = useDispatch();

    const handleModalOpen = () => {
        setIsCreateProjectModalOpen(() => true);
        dispatch(openCloseBody(true));
    }

    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
        dispatch(openCloseBody(true));
    }

    const revenueData = {
        labels: [
            // 'Mon',
            // 'Tue',
            // 'Wed',
        ],
        fontColor: "white",
        datasets: [
            {
                label: "Revenue / %",
                data: [65, 35],
                backgroundColor: ["#02A611", "#ccc"],
                borderColor: ["white"],
                borderWidth: 2,
            },
        ],
    };

    const getLengths = async () => {
        fetchData(`admin/get-project-counts?status=Completed`)
    }

    useEffect(() => {
        getLengths();
    }, [])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("length data", res);
            setTotalCount(res.data.totalCount)
        }
    }, [res])



    return (
        <>
            <div>
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl text-[#374151] font-bold py-2">Dashboard</h1>
                </div>

                <div className='grid grid-cols-[60%_40%] h-64 gap-3'>
                    <div className='grid grid-cols-[46%_52%] gap-3'>
                        <div className="bg-[#EBF5FF] px-4 py-4 h-64 rounded-md">
                            <div className="flex justify-between items-center">
                                <div className='flex gap-2 items-center'>
                                    <p className='text-sm'>Today's tasks</p>
                                    <div className='w-7 h-5 text-sm bg-[#348AC8] rounded-tl-xl rounded-br-xl text-white flex justify-center items-center'>3</div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <p className='text-sm text-[#385791]'>Manage</p>
                                    <IoIosArrowForward />
                                </div>
                            </div>
                            <div className="mt-3 flex flex-col gap-2 h-44 report-todays-task overflow-y-auto">
                                <div className="bg-[#B8EFBD] px-1 py-2 rounded grid gap-2 items-center grid-cols-[30px_1fr_20px]">
                                    <div className="flex justify-center">
                                        <FaRegPauseCircle size={28} />
                                    </div>
                                    <div className="">
                                        <h4 className="text-[13px]">Color & Typography Selection</h4>
                                        <p className="text-[11px] text-[#365284]">Real estate App</p>
                                    </div>
                                    <div>
                                        <FaStar className='text-[#D9D9D9]' />
                                    </div>
                                </div>
                                <div className="bg-[#B8EFBD] px-1 py-2 rounded grid gap-2 items-center grid-cols-[30px_1fr_20px]">
                                    <div className="flex justify-center">
                                        <FaRegPauseCircle size={28} />
                                    </div>
                                    <div className="">
                                        <h4 className="text-[13px]">Color & Typography Selection</h4>
                                        <p className="text-[11px] text-[#365284]">Real estate App</p>
                                    </div>
                                    <div>
                                        <FaStar className='text-[#D9D9D9]' />
                                    </div>
                                </div>
                                <div className="bg-[#C9E6FA] px-1 py-2 rounded grid gap-2 items-center grid-cols-[30px_1fr_20px]">
                                    <div className="flex justify-center">
                                        <FaRegPlayCircle size={28} />
                                    </div>
                                    <div className="">
                                        <h4 className="text-[13px]">Color & Typography Selection</h4>
                                        <p className="text-[11px] text-[#365284]">Real estate App</p>
                                    </div>
                                    <div>
                                        <FaStar className='text-[#D9D9D9]' />
                                    </div>
                                </div>
                                <div className="bg-[#C9E6FA] px-1 py-2 rounded grid gap-2 items-center grid-cols-[30px_1fr_20px]">
                                    <div className="flex justify-center">
                                        <FaRegPlayCircle size={28} />
                                    </div>
                                    <div className="">
                                        <h4 className="text-[13px]">Color & Typography Selection</h4>
                                        <p className="text-[11px] text-[#365284]">Real estate App</p>
                                    </div>
                                    <div>
                                        <FaStar className='text-[#D9D9D9]' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#EBF5FF] px-4 py-4 h-64 rounded-md">
                            <div className="flex justify-between items-center gap-2">
                                <div className='flex gap-2 items-center'>
                                    <p className='text-sm'>Projects completed</p>
                                    <div className='text-sm h-5 bg-[#63FF3C99] flex justify-center gap-1 p-1 items-center'>
                                        <p className='text-xs'>10%</p>
                                        <BsGraphUpArrow size={13} color='#02A611' />
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <p className='text-sm text-[#385791]'>Manage</p>
                                    <IoIosArrowForward />
                                </div>
                            </div>
                            <div className='grid grid-cols-[57%_43%] gap-2 mt-3'>
                                <div className="flex flex-col gap-4">
                                    <div className='flex gap-[11px] items-center'>
                                        <IoTriangle color='#31D752' />
                                        <p className='text-xs'>48%</p>
                                        <p className='text-xs'>Real estate App</p>
                                    </div>
                                    <div className='flex gap-[11px] items-center'>
                                        <IoTriangle color='#F74D4D' />
                                        <p className='text-xs'>28%</p>
                                        <p className='text-xs'>Real estate App</p>
                                    </div>
                                    <div className='flex gap-[11px] items-center'>
                                        <IoTriangle color='#FEA700' />
                                        <p className='text-xs'>14%</p>
                                        <p className='text-xs'>Real estate App</p>
                                    </div>
                                    <div className='flex gap-[11px] items-center'>
                                        <IoTriangle color='#2B4F90' />
                                        <p className='text-xs'>10%</p>
                                        <p className='text-xs'>Real estate App</p>
                                    </div>
                                </div>
                                <div className="">
                                    <div style={{
                                        background: "conic-gradient(#2B4F90 0deg, #2B4F90 70deg,#F74D4D 70deg, #F74D4D 165deg,#31D752 165deg, #31D752 300deg,#FEA700 300deg, #FEA700 360deg)"
                                    }} className='w-32 h-32 flex justify-center items-center rounded-full'>
                                        <div className='w-[117px] h-[117px] bg-[#EBF5FF] rounded-full flex flex-col justify-center items-center'>
                                            <p>4</p>
                                            <p className='text-center text-sm'>Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-[#EBF5FF] px-4 h-64 py-4 rounded-md'>
                        <div className="flex justify-between items-center gap-2">
                            <div className='flex gap-2 items-center'>
                                <p className='text-sm'>Total Projects</p>
                                <div className='flex gap-2 items-center'>
                                    <p className='text-sm text-[#385791]'>Manage</p>
                                    <IoIosArrowForward />
                                </div>
                            </div>
                            <div className='flex gap-2 items-center'>
                                <button onClick={() => setIsCreateProjectModalOpen(true)} className='bg-[#348AC8] rounded-md flex items-center gap-2 px-3 py-[6px] text-white'>
                                    <FaPlus />
                                    Add Project
                                </button>
                            </div>
                        </div>
                        <div className="mt-3 grid grid-cols-4 gap-2">
                            <div className="bg-[#348AC8] rounded-md p-2 h-[160px] relative text-white">
                                <h3 className='text-2xl'>{totalCount.all}</h3>
                                <p className='mt-1 text-sm'>Total Projects</p>
                                <img className='absolute bottom-0 right-0' src={beachBall} alt="beachBall" />
                            </div>
                            <div className="bg-[#FCA265] rounded-md p-2 h-[160px] relative text-white">
                                <h3 className='text-2xl'>{totalCount.assigned}</h3>
                                <p className='mt-1 text-sm'>Assigned</p>
                                <img className='absolute bottom-0 right-0' src={assigned} alt="assigned" />
                            </div>
                            <div className="bg-[#348AC8] rounded-md p-2 h-[160px] relative text-white">
                                <h3 className='text-2xl'>{totalCount.Progress}</h3>
                                <p className='mt-1 text-sm'>Progress</p>
                                <img className='absolute bottom-0 right-0' src={Progress} alt="Progress" />
                            </div>
                            <div className="bg-[#1FC744] rounded-md p-2 h-[160px] relative text-white">
                                <h3 className='text-2xl'>{totalCount.completed}</h3>
                                <p className='mt-1 text-sm'>Completed</p>
                                <img className='absolute bottom-0 right-0' src={Completed} alt="Completed" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-[75%_25%] gap-3 mt-5'>
                    <div className='flex flex-col gap-2'>
                        <div className="bg-[#EBF5FF] rounded-md p-4 grid grid-cols-4 gap-2">
                            <div className="bg-white border-2 px-4 py-3 flex items-center gap-3">
                                <div>
                                    <img src={sales} alt="sales" />
                                </div>
                                <div className='text-sm'>
                                    <p>Number of Sales</p>
                                    <p className='text-[#348AC8]'>150</p>
                                </div>
                            </div>
                            <div className="bg-white border-2 px-4 py-3 flex items-center gap-3">
                                <div>
                                    <img src={cost} alt="cost" />
                                </div>
                                <div className='text-sm'>
                                    <p>Cost</p>
                                    <p className='text-[#02A611]'>$15,990</p>
                                </div>
                            </div>
                            <div className="bg-white border-2 px-4 py-3 flex items-center gap-3">
                                <div>
                                    <img src={revenue} alt="revenue" />
                                </div>
                                <div className='text-sm'>
                                    <p>Revenue</p>
                                    <p className='text-[#02A611]'>$20,990</p>
                                </div>
                            </div>
                            <div className="bg-white border-2 px-4 py-2 flex items-center gap-3">
                                <div>
                                    <img src={profit} alt="profit" />
                                </div>
                                <div className='text-sm'>
                                    <p>Profit</p>
                                    <p className='text-[#348AC8]'>$7,190</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#EBF5FF] rounded-md p-4 grid grid-cols-[73%_25%] gap-3">
                            <div className="bg-white rounded p-3">
                                <div className="flex justify-between items-center mb-3">
                                    <p>Total Working Hours Tracked</p>
                                    <select className="border-2 rounded-md px-[2px]" name="" id="">
                                        <option value="week">Week</option>
                                    </select>
                                </div>
                                <FullCalendar
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    initialView="dayGridWeek"
                                    events={events}
                                    height={"40vh"}
                                    eventContent={(eventInfo) => {
                                        return (
                                            <div
                                                className="rounded-full bg-gradient-to-r from-[#348AC8] to-[#E4ECF2] flex items-center gap-2 text-white w-full h-7"
                                            >
                                                <img className="w-7 h-7 rounded-full bg-slate-400" src={avatar} alt="avatar" />
                                                {eventInfo.event.title}
                                                <p className="px-2">25Hr</p>
                                            </div>
                                        );
                                    }}
                                    headerToolbar={{
                                        left: 'today',
                                        center: 'title',
                                        right: 'dayGridMonth,dayGridWeek,dayGridDay',
                                    }}
                                />
                            </div>
                            <div className="bg-white rounded-lg p-3">
                                <h2 className="text-xl text-center">Revenue Status</h2>
                                <div className="relative">
                                    <Doughnut data={revenueData} />
                                    {/* <Pie data={revenueData} /> */}
                                    <div className="absolute top-[39%] left-[34%]">
                                        <p className="text-center">Total</p>
                                        <p className="text-center font-bold">70,000 ₹</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#EBF5FF] rounded-md p-4 flex flex-col gap-2">
                        <div className="flex justify-between items-center mb-3">
                            <p className='text-sm'>Notifications</p>
                            <div className='flex gap-2 items-center'>
                                <p className='text-sm text-[#385791]'>Manage</p>
                                <IoIosArrowForward />
                            </div>
                        </div>
                        <div className="rounded-md p-3 bg-[#0D0D0D] flex justify-between gap-2">
                            <div className="text-white">
                                <h3 className='text-lg'>09:00 AM</h3>
                                <p className='text-xs text-[#757575]'>Team Meeting with Design team</p>
                            </div>
                            <div className="flex items-start gap-[2px]">
                                <span className='text-[#F74D4D] text-[10px]'>High</span>
                                <IoMdArrowDropup className='text-[#F74D4D] -mt-[2px]' />
                            </div>
                        </div>
                        <div className="rounded-md p-3 bg-[#0D0D0D] flex justify-between gap-2">
                            <div className="text-white">
                                <h3 className='text-lg'>09:00 AM</h3>
                                <p className='text-xs text-[#757575]'>Team Meeting with Design team</p>
                            </div>
                            <div className="flex items-start gap-[2px]">
                                <span className='text-[#33E856] text-[10px]'>Low</span>
                                <IoMdArrowDropdown className='text-[#33E856] -mt-[2px]' />
                            </div>
                        </div>
                        <div className="rounded-md p-3 bg-[#0D0D0D] flex justify-between gap-2">
                            <div className="text-white">
                                <h3 className='text-lg'>09:00 AM</h3>
                                <p className='text-xs text-[#757575]'>Team Meeting with Design team</p>
                            </div>
                            <div className="flex items-start gap-[2px]">
                                <span className='text-[#33E856] text-[10px]'>Low</span>
                                <IoMdArrowDropdown className='text-[#33E856] -mt-[2px]' />
                            </div>
                        </div>
                        <button onClick={() => setIsAddReminderModalOpen(true)} className='bg-[#348AC8] text-white py-3 rounded-md'>Add Reminder</button>
                    </div>
                </div>
            </div>

            {isModalOpen &&
                <AddEmployeeModal
                    handleModal={handleModal}
                    isUpdate={false}
                    getAllEmployee={() => { }}
                />
            }

            {isCreateProjectModalOpen &&
                <CreateProjectModal
                    getAllProject={() => { }}
                    setState={setIsCreateProjectModalOpen}
                    project={""}
                />
            }

            {isAddReminderModalOpen &&
                <AddReminderModal
                    setState={setIsAddReminderModalOpen}
                    getAllReminder={() => { }}
                />
            }
        </>
    )
}

export default Report