import { format } from 'date-fns';
import classes from './ExpenseInfoModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import parse from 'html-react-parser';

const ExpenseInfoModal = ({ setState, expense }) => {
    console.log(expense);
    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.box}>
                    <div className={classes.heading_container}>
                        <h2 className='text-2xl'>expense Info </h2>
                        <div className={classes.button_icon_container}>
                            <RxCross2 onClick={() => setState(false)} cursor={"pointer"} size={26} />

                        </div>
                    </div>
                    <div className={classes.d_flex}>
                        <div className=''>
                            <h4>{expense?.item}</h4>
                            <p>status : {expense?.status}</p>
                            <p>cost : ₹{expense?.cost}</p>
                        </div>
                        <div className=''>
                            <p>from : {`${expense?.from}`}</p>
                            <p>date : {format(new Date(expense.date), "dd-MM-yyy")}</p>
                        </div>
                    </div>
                    <p className={classes.p}>{parse(expense?.description)}</p>
                </div>
            </div>
        </>
    )
}

export default ExpenseInfoModal