import logo from '../../assets/Easesmith logo.png';
import signInBg from '../../assets/sign-in-bg.png';
import esLoginImg from '../../assets/es-login-img.png';
import esLoginImg2 from '../../assets/es-login-img2.png';
import esLoginImg3 from '../../assets/es-login-img3.png';

import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { MdMarkEmailRead } from "react-icons/md";

import classes from './SignIn.module.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import usePostApiReq from '../../hooks/usePostApiReq';
import { readCookie } from '../../utils/readCookie';
import CarouselComp from '../../components/carousel/CarouselComp';

const SignIn = () => {
    const { res, fetchData, isLoading } = usePostApiReq();

    const [isShowPassword, setIsShowPassword] = useState(false);
    const [userInfo, setUserInfo] = useState({ email: "", password: "" });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let cookies = readCookie("userInfo");

    const handleIsOpen = () => {
        setIsShowPassword(!isShowPassword);
    }

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        console.log("name",value.toLowerCase());
        if (name === "email") {
            value = value.toLowerCase();
        }
        setUserInfo({ ...userInfo, [name]: value })
    }

    useEffect(() => {
        const value = cookies?.userId;
        const type = cookies?.userType;
        if (value && type === "admin") {
            navigate('/report');
        }
        if (value && type === "employee") {
            navigate('/home');
        }
    }, [])




    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!userInfo.email || !userInfo.password) {
            toast.error("All fields are required");
            return;
        }

        try {
            fetchData("/user/login", { ...userInfo });
        } catch (error) {
            if (error?.response?.data?.message === "Login failed, You are not an active user") {
                toast.error("There was some problem in login. Please contact your department.")
                return;
            }
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            if (cookies?.userType === "admin") {
                navigate('/report');
            }
            else {
                navigate('/home');
            }
        }
    }, [res])

    return (
        <section className={classes.section} style={{ backgroundImage: `url(${signInBg})` }}>
            <div className={`max-w-5xl w-full rounded-2xl flex gap-5 py-14 px-10 ${classes.signIn_box}`}>
                <div className="w-1/2 px-4 relative">
                    <div className='absolute h-[240px] w-1 right-0 top-28 bg-[#348AC8]'></div>
                    <div className={classes.img_box}>
                        <img className={classes.img} src={logo} alt="logo" />
                    </div>

                    <h2 className={classes.h2}>Hello there</h2>
                    <p className='font-semibold text-center mt-3'>Welcome back, and Let’s get started!</p>
                    <form onSubmit={handleOnSubmit}>
                        {/* <label className={classes.label} htmlFor="email">Email</label> */}
                        <div className={classes.input_box}>
                            <input onChange={handleOnChange} value={userInfo.email} className={classes.input} type="email" name='email' id='email' placeholder='Email address' />
                            <MdMarkEmailRead className={classes.icon} />
                        </div>
                        {/* <label className={classes.label} htmlFor="password">Password</label> */}
                        <div className={classes.input_box}>
                            <input onChange={handleOnChange} value={userInfo.password} className={classes.input} type={isShowPassword ? "text" : "password"} name='password' id='password' placeholder='Password' />
                            {isShowPassword ?
                                <FaEyeSlash onClick={handleIsOpen} className={classes.icon} />
                                : <FaEye onClick={handleIsOpen} className={classes.icon} />
                            }
                        </div>
                        <div className={classes.remember_me_box_link_container}>
                            <p onClick={() => navigate("/forgot-password")} className={`${classes.link} cursor-pointer text-center`}>Forgot Password?</p>
                        </div>
                        <button className={classes.button}>
                            Login
                        </button>
                    </form>
                </div>
                <div className='w-2/5'>
                    <CarouselComp>
                        <img src={esLoginImg} alt="esLoginImg" />
                        <img src={esLoginImg2} alt="esLoginImg2" />
                        <img className='h-full w-full' src={esLoginImg3} alt="esLoginImg3" />
                    </CarouselComp>
                </div>
            </div>
        </section>
    )
}

export default SignIn