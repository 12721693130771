import { useDispatch } from 'react-redux';
import classes from './LogoutModal.module.css';
import { useNavigate } from 'react-router-dom';
import useGetApiReq from '../../hooks/useGetApiReq';
import { useEffect } from 'react';

const LogoutModal = ({ setState }) => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        fetchData("/user/logout-user");
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            navigate("/")
            setState(false);
        }
    }, [res])

    return (
        <div className={classes.modal_wrapper}>
            <div className={classes.modal}>
                <p>Are you sure you want to log out?</p>
                <div className={classes.button_wrapper}>
                    <button onClick={handleLogout} className={classes.button}>Yes</button>
                    <button onClick={() => setState(false)} className={classes.button}>No</button>
                </div>
            </div>
        </div>
    )
}

export default LogoutModal