import { Link, useLocation } from 'react-router-dom';
import HomeIcn from '../../assets/icons/dashboard-img.png'
import ProIcn from '../../assets/icons/projects-img.png'
import AttIcn from '../../assets/icons/attendance-img.png'
import TicIcn from '../../assets/icons/ticket-img.png'
import AllTicIcn from '../../assets/icons/all-ticket-img.png'
import CalIcn from '../../assets/icons/calendar-img.png'
import Doc from "../../assets/icons/document-img.png"
import ResIcn from '../../assets/icons/resume-img.png'
import EmpIcn from '../../assets/icons/employee-img.png'
import ClientsIcn from '../../assets/icons/client-img.png'
import ExpIcn from "../../assets/icons/expenses-img.png"
import { IoMdMail } from "react-icons/io";

import classes from './Sidebar.module.css';
import { readCookie } from '../../utils/readCookie';


const Sidebar = () => {
  const { pathname } = useLocation();

  let cookies = readCookie("userInfo");
  const type = cookies?.userType;
  const permissions = cookies?.perm;
  // console.log("perm", permissions);

  return (
    <>
      <aside className={`${classes.sidebar} z-[6] h-[100vh] px-2 fixed top-0 pt-20 group p-5 bg-[#348AC8] w-[70px] transition-all duration-500 overflow-x-hidden overflow-y-auto hover:w-[190px]`}>
        <div className={classes.aside_top}>
          {type === "employee" &&
            <>
              <Link className={`${classes.link} ${pathname.includes("/home") && classes.active} justify-center group-hover:justify-start`} to={"/home"}>
                <img src={HomeIcn} alt='dashboard' />
                <span className='hidden group-hover:block'>Home</span>
              </Link>

              <Link className={`${classes.link} ${pathname.includes("/employee-projects") && classes.active} justify-center group-hover:justify-start`} to={"/employee-projects"}>
                <img src={ProIcn} alt='project' />
                <span className='hidden group-hover:block'>Projects</span>
              </Link>

              <Link className={`${classes.link} ${pathname.includes("/employee-attendance") && classes.active} justify-center group-hover:justify-start`} to={"/employee-attendance"}>
                <img src={AttIcn} alt='Attendance' />
                <span className='hidden group-hover:block'>Attendance</span>
              </Link>
            </>}

          {permissions?.report !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/report") && classes.active} justify-center group-hover:justify-start`} to={"/report"}>
              <img src={HomeIcn} alt='report' />
              <span className='hidden group-hover:block'>Report</span>
            </Link>}

          {permissions?.allProjects !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/allProjects") && classes.active} justify-center group-hover:justify-start`} to={"/allProjects"}>
              <img src={ProIcn} alt='project' />
              <span className='hidden group-hover:block'>All Projects</span>
            </Link>}

          {permissions?.employees !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/employees") && classes.active} justify-center group-hover:justify-start`} to={"/employees"}>
              <img src={EmpIcn} alt='employee' />
              <span className='hidden group-hover:block'>Employees</span>
            </Link>}

          {permissions?.clients !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/clients") && classes.active} justify-center group-hover:justify-start`} to={"/clients"}>
              <img src={ClientsIcn} alt='clients' />
              <span className='hidden group-hover:block'>Clients</span>
            </Link>}

          {permissions?.allTickets !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/allTickets") && classes.active} justify-center group-hover:justify-start`} to={"/allTickets"}>
              <img src={AllTicIcn} alt='allTickets' />
              <span className='hidden group-hover:block'>All Tickets</span>
            </Link>}
          <Link className={`${classes.link} ${pathname.includes("/myTickets") && classes.active} justify-center group-hover:justify-start`} to={"/myTickets"}>
            <img src={TicIcn} alt='myTickets' />
            <span className='hidden group-hover:block'>My Tickets</span>
          </Link>
          {permissions?.calender !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/calender") && classes.active} justify-center group-hover:justify-start`} to={"/calender"}>
              <img src={CalIcn} alt='calender' />
              <span className='hidden group-hover:block'>Calender</span>
            </Link>}

          {permissions?.allAttendance !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/allAttendance") && classes.active} justify-center group-hover:justify-start`} to={"/allAttendance"}>
              <img src={AttIcn} alt='Attendance' />
              <span className='hidden group-hover:block'>All Attendance</span>
            </Link>}

          {permissions?.expenses !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/expenses") && classes.active} justify-center group-hover:justify-start`} to={"/expenses"}>
              <img src={ExpIcn} alt='expense' />
              <span className='hidden group-hover:block'>Expenses</span>
            </Link>}

          {permissions?.resumes !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/resumes") && classes.active} justify-center group-hover:justify-start`} to={"/resumes"}>
              <img src={ResIcn} alt='resume' />
              <span className='hidden group-hover:block'>Resumes</span>
            </Link>}

          {permissions?.documents !== "none" &&
            <Link className={`${classes.link} ${pathname.includes("/documents") && classes.active} justify-center group-hover:justify-start`} to={"/documents"}>
              <img src={Doc} alt='documents' />
              <span className='hidden group-hover:block'>Documents</span>
            </Link>}

          <Link className={`${classes.link} ${pathname.includes("/emails") && classes.active} justify-center group-hover:justify-start`} to={"/emails"}>
            {/* <img src={Doc} alt='emails' /> */}
            <IoMdMail size={28} />
            <span className='hidden group-hover:block'>Emails</span>
          </Link>
        </div>
        <div className="mt-2">
          {/* <div className={classes.link}>
          <IoMdSettings className="text-2xl" />
          <span>Setting</span>
        </div> */}

        </div>

      </aside>
    </>
  )
}

export default Sidebar