/* eslint-disable react/jsx-no-comment-textnodes */
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

import Header from "./components/header/Header";

import AppRoutes from "./routes/AppRoutes";

import SignIn from "./pages/signIn/SignIn";

import Home from "./pages/home/Home";
import Report from "./pages/report/Report";
import CreateInvoice from "./pages/create-invoice/CreateInvoice";
import Clients from "./pages/clients/Clients";
import Profile from "./pages/profile/Profile";
import Employees from "./pages/employees/Employees";
import EmployeeProfile from "./pages/employeeProfile/EmployeeProfile";
import ProjectView from "./pages/projectView/ProjectView";

import { Provider } from "react-redux";
import { store } from "./store/store";
import { Toaster } from "react-hot-toast";
import ModuleView from "./pages/moduleView/ModuleView";
import Tickets from "./pages/tickets/Tickets";
import TicketsDetail from "./pages/tickets/TicketsDetail";
import GoogleCalender from "./pages/google-calender/GoogleCalender";
import EmployeeProjectView from "./pages/employeeProjectView/EmployeeProjectView";
import Attendance from "./pages/attendance/Attendance";
import AllProjects from "./pages/allProjects/AllProjects";
import ClientProjects from "./pages/clientProjects/ClientProjects";
import UpcomingProjects from "./pages/upcomingProjects/UpcomingProjects";
import OngoingProjects from "./pages/ongoingProjects/OngoingProjects";
import CompletedProjects from "./pages/completedProjects/CompletedProjects";
import ProjectTickets from "./pages/projectTickets/ProjectTickets";
import EmployeeAttendance from "./pages/employeeAttendance/EmployeeAttendance";
import EmployeeProjects from "./pages/employee-projects/EmployeeProjects";
import EmployeeTasks from "./pages/employee-tasks/EmployeeTasks";
import AllExpenses from "./pages/allExpenses/AllExpenses";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import Hiring from "./pages/hiring/Hiring";
import { useEffect } from "react";
import AllModules from "./pages/allmodules/AllModules";
import BackdropLoader from "./components/backdrop-loader/BackdropLoader";
import { useSelector } from "react-redux";
import AllDocuments from "./pages/allDocuments/AllDocuments";
import EmployeeAttendanceDetail from "./pages/employee-attendance-detail/EmployeeAttendanceDetail";
import NotFound from "./pages/not-found/NotFound";
import MyTickets from "./pages/my-tickets/MyTickets";
import MyTicketsDetail from "./pages/my-tickets/MyTicketsDetail";
import ProjectTicketsDetail from "./pages/projectTickets/ProjectTicketsDetail";
import Cookies from "js-cookie";
import { readCookie } from "./utils/readCookie";
import useGetApiReq from "./hooks/useGetApiReq";
import { useDispatch } from "react-redux";
import { openCloseBody } from "./store/slices/bodySlice";
import EmployeeOrManagerProjectview from "./pages/employeeOrManagerProjectview/EmployeeOrManagerProjectview";
import Emails from "./pages/emails/Emails";
import EmailDetails from "./pages/email-details/EmailDetails";
import { axiosInstance } from "./utils/axiosInstance";


function App() {
  const { res, fetchData, isLoading: loading } = useGetApiReq();

  const { isLoading } = useSelector((state) => state.loadingReducer)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkTokenExpiration = async () => {
    // fetchData("/admin/check-token-expiration");
  }

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("Check token expiration", res);
    }
  }, [res])

  

  useEffect(() => {
    checkTokenExpiration();
    let cookies = readCookie("userInfo")
    if (!cookies?.userId) {
      navigate("/");
    }
  }, [])

  useEffect(() => {
    if (isLoading) {
      dispatch(openCloseBody(true));
    }
    else {
      dispatch(openCloseBody(false));
    }
  }, [isLoading])

  return (
    <>
      {isLoading && <BackdropLoader />}

      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<SignIn />} />


        <Route element={<AppRoutes />} >
          <Route path="/report" element={<Report />} />

          <Route path="/allProjects" element={<AllProjects />} />

          <Route path="/employee-projects" element={<EmployeeProjects />} />

          <Route path="/employee-projects/:pId/*" element={<EmployeeOrManagerProjectview />} />

          <Route path="/employee-projects/:pId/tickets/:id" element={<MyTicketsDetail />} />
          <Route path="/employee-attendance" element={<EmployeeAttendance />} />
          <Route path="/home" element={<Home />} />
          <Route path="/employee-projects/:pId/allModules" element={<AllModules />} />
          <Route path="/employee-projects/:pId/projectTickets" element={<ProjectTickets />} />
          <Route path="/employee-projects/:pId/projectModules" element={<ModuleView />} />

          {/* <Route path="/allProjects/upcoming" element={<UpcomingProjects />} />
          <Route path="/allProjects/ongoing" element={<OngoingProjects />} />
        <Route path="/allProjects/completed" element={<CompletedProjects />} /> */}
          <Route path="/allProjects/:id/*" element={<ProjectView />} />
          <Route path="/allProjects/:id/projectTickets" element={<ProjectTickets />} />
          <Route path="/allProjects/:id/projectTickets/:ticketId" element={<ProjectTicketsDetail />} />
          <Route path="/allProjects/:id/allModules" element={<ModuleView />} />

          <Route path="/employees" element={<Employees />} />
          <Route path="/employees/:empId" element={<EmployeeProfile />} />
          <Route path="/employees/:empId/all-tasks" element={<EmployeeTasks />} />

          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:clientId" element={<Profile />} />
          <Route path="/clients/:clientId/create-invoice" element={<CreateInvoice />} />
          <Route path="/clients/:clientId/projects" element={<ClientProjects />} />

          <Route path="/allTickets" element={<Tickets />} />
          <Route path="/allTickets/:id" element={<MyTicketsDetail />} />

          <Route path="/myTickets" element={<MyTickets />} />
          <Route path="/myTickets/:id" element={<MyTicketsDetail />} />

          <Route path="/calender" element={<GoogleCalender />} />

          <Route path="/allAttendance" element={<Attendance />} />
          <Route path="/allAttendance/:attId" element={<EmployeeAttendanceDetail />} />

          <Route path="/expenses" element={<AllExpenses />} />

          <Route path="/resumes" element={<Hiring />} />

          <Route path="/documents/*" element={<AllDocuments />} />

          <Route path="/emails" element={<Emails />} />
          <Route path="/emails/:mId" element={<EmailDetails />} />
        </Route>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
