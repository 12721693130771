import { useState } from "react";
import { axiosInstance } from "../utils/axiosInstance";
import { useDispatch } from "react-redux";
import { handleLoading } from "../store/slices/loadingSlice";
import toast from "react-hot-toast";

const useDeleteApiReq = () => {
    const [res, setRes] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const fetchData = async (url, config = {}) => {
        try {
            setIsLoading(true);
            dispatch(handleLoading(true));
            const response = await axiosInstance.delete(url, config);
            setRes(response);
        } catch (error) {
            console.log("delete api error =>", error);
            toast.error(error?.response?.data?.message || "An error occurred.");
        } finally {
            dispatch(handleLoading(false));
            setIsLoading(false);
        }
    };

    return { res, isLoading, fetchData };


};

export default useDeleteApiReq;