import classes from './EmployeeItem.module.css';

const EmployeeItem = ({ employee, setSelectedEmployees }) => {
    const handleOnchange = (e) => {
        const { value, checked } = e.target;
        console.log(value, checked);
        if (checked) {
            setSelectedEmployees(prev => [...prev, { employeeId: value, status: true, startDate: new Date() }]);
        }
        else {
            setSelectedEmployees(prev => prev.filter(item => item.employeeId !== `${value}`));
        }
    }
    return (
        <div className={classes.employee}>
            <div className={classes.info}>
                <h4>{employee.name}</h4>
                <p>{employee.position}</p>
            </div>
            <div>
                <input onChange={handleOnchange} className={classes.checkbox} value={employee._id} type="checkbox" name="" id="" />
            </div>
        </div>
    )
}

export default EmployeeItem