import React from 'react'
import Module from '../../components/module/Module'
import { axiosInstance } from '../../utils/axiosInstance';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import loader from '../../assets/rolling.gif';
import ReactPaginate from 'react-paginate';
import ModuleFilter from '../../components/module-filter/ModuleFilter';
import LimitComp from '../../components/limit-comp/LimitComp';
import Loader from '@/components/loader/Loader';


const AllModules = () => {
    const [allModule, setAllModule] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [date, setDate] = useState("");
    const [status, setStatus] = useState("");
    const [limit, setLimit] = useState(5);

    const params = useParams();
    const navigate = useNavigate();

    const getAllModule = async () => {
        try {
            setIsLoading(true);
            setAllModule([]);
            const { data } = await axiosInstance.get(`/admin/projects-modules/${params.pId}?page=${page}&limit=${limit}`);
            console.log("modules", data);
            setAllModule(data.data);
            const count = Math.ceil(data?.totalDocs / limit);
            setPageCount(count);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getAllModule();
    }, [page, limit])


    const filterModules = async () => {
        try {
            setIsLoading(true);
            setAllModule([]);
            const { data } = await axiosInstance.get(`/admin/filter-modules?projectId=${params?.pId}&date=${date}&status=${status}`);
            console.log("filtered modules", data);
            setAllModule(data.modules);
            const count = Math.ceil(data?.totalDocs / limit)
            setPageCount(count);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        filterModules();
    }, [date, status])


    const handleDateChange = (e) => {
        setDate(e.target.value);
    }

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    }

    const handleReset = () => {
        setDate("");
        setStatus("");
        console.log("in reset function");
    }

    return (
        <section>
            <FaArrowLeft className='mb-4 cursor-pointer' size={20} onClick={() => navigate(-1)} />
            <h3 className="text-3xl font-bold mb-2">Project Modules</h3>
            <div className="flex items-center gap-2 py-2">
                <LimitComp
                    setLimit={setLimit}
                    limit={limit}
                />

                <ModuleFilter
                    handleDateChange={handleDateChange}
                    handleStatusChange={handleStatusChange}
                    handleReset={handleReset}
                    status={status}
                    date={date}
                />
            </div>

            <div
                className="flex flex-wrap items-center gap-2 mt-4 mb-5"
            >
                {allModule.map((module, i) => (
                    <Module
                        key={i}
                        index={i}
                        module={module}
                        getAllModule={getAllModule}
                        employee={true}
                        getStories={() => { }}
                    />
                ))}

                {allModule.length === 0 && isLoading &&
                    <Loader />
                }

                {allModule.length === 0 && !isLoading &&
                    <p>No module found</p>
                }
            </div>
            {pageCount > 0 &&
                <div className="mt-5">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        pageCount={pageCount}
                        onPageChange={(e) => setPage(e.selected + 1)}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>}
        </section>
    )
}

export default AllModules